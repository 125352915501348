import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import "./../students.styles.scss";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import Button from "./../../../../components/Button/button.component";

import { useCookies } from "react-cookie";

const CancelClassComplete = () => {
  const history = useHistory();
  
  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <Header />
        <Row
          style={{ maxWidth: "44rem", transform: "translateY(-77px)" }}
          className="mx-auto justify-content-center shadow-sm p-3 bg-body rounded-lg"
        >
          <Col>
            <div
              className="text-center mx-auto mt-3 mb-4"
              style={{ maxWidth: "32rem", height: "200px" }}
            >
              <h1 className="heading1 mb-4">Your Request Will Be Processed.</h1>
              <p>
                Please check your email for further information. <br />
                Thank You.
              </p>
            </div>
            <div className="d-flex justify-content-center mt-5 mb-3">
              <Button
                onClick={() => history.push("/dashboard")}
                title="BACK"
                type="primary"
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
};

export default CancelClassComplete;
