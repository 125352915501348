import React from "react";

import { Container, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import Footer from "../Footer/footer.component";
import Header from "../Header/header.component";
import HeaderAdmin from "../HeaderAdmin/header.component";
import HeaderOwner from "../HeaderOwner/header-owner.component";
import HeaderStudent from "../HeaderStudent/header-student.component";
import HeaderTeacher from "../HeaderTeacher";

const OrganismLayout = ({
  onBoardingStatus,
  title,
  children,
  className,
  maxWidth,
  isBrowseClasses,
  withLogout,
}) => {
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const [cookies, setCookies] = useCookies(["user"]);

  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;

  const setHeader = () => {
    switch (role) {
      case "owner":
        return <HeaderOwner title={title} />;
      case "teacher":
        return <HeaderTeacher title={title} />;
      case "student":
        return (
          <HeaderStudent title={title} />
        );
      case "super admin":
        return <HeaderAdmin title={title} />;
      default:
        return <Header title={title} isBrowseClasses={isBrowseClasses} withLogout={withLogout} />;
    }
  };
  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        {setHeader()}
        {onBoardingStatus}
        <div
          style={{ maxWidth: maxWidth, transform: "translateY(-77px)" }}
          className={`${
            className ?? "p-3"
          } bg-white mx-auto rounded-10 shadow-sm`}
        >
          {children}
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default OrganismLayout;
