import axiosInstance from "../axiosInstance";

export const getAllTeachers = async (user_token, studio_id, page) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(`/teachers?page=${page}`, config);
  return res;
};

export const deleteTeacher = async (user_token, studio_id, id) => {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${user_token}`,
        "Studio-Id": studio_id,
      },
    };
  
    const res = await axiosInstance.delete(`/teachers/${id}`, config)
    return res;
  };