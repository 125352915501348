import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Col, Form } from "react-bootstrap";
import TextInput from "../../components/TextInput/text-input.component";
import Button from "../../components/Button/button.component";

import {
  setStatusDefault,
  updateCancellation,
} from "../../redux/studio/studio.action";

export const Cancellation = ({ nextStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rolesOwner, setRolesOwner] = useState(true);
  const [rolesTeacher, setRolesTeacher] = useState("");
  const [cancellationBeforeHours, setCancellationBeforeHours] = useState(1);
  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { onboarding_status } = useSelector((state) => state.owner);
  const { general_terms, status, isLoading } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      cancellation_before_hours: cancellationBeforeHours,
      cancellation_policy_title: title,
      cancellation_policy_description: description,
      roles:
        rolesOwner && rolesTeacher
          ? "owner,teacher"
          : rolesOwner
          ? "owner"
          : rolesTeacher && "teacher",
    };

    dispatch(updateCancellation(params, user_token, studio_id));
  };

  useEffect(() => {
    if (status === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [status]);

  return (
    <Col>
      <div
        style={{ maxWidth: "32rem" }}
        className="custom-card add-student-form mx-auto"
      >
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <p className="fw-bold">Configure Cancellation Period</p>
          <Row>
            <Col>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  autoFocus
                  label="Hours"
                  placeholder="Input Hours..."
                  value={cancellationBeforeHours}
                  onChange={(event) => {
                    setCancellationBeforeHours(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <p className="heading3">
            The hour(s) in which a student can cancel for a class before the
            class starts
          </p>
          <p>
            <b>Cancellation Policy</b>
          </p>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              label="Title"
              placeholder="Input Title..."
              value={title || ""}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <label htmlFor="desc" className="custom-form-label">
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              required
              className="text-input"
              name="desc"
              id="desc"
              cols="30"
              rows="5"
              placeholder="Input Description..."
              value={description || ""}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <Row>
              <Col>
                <Form.Check
                  required
                  type="checkbox"
                  label="Owner"
                  id="roleowner"
                  defaultChecked={rolesOwner}
                  disabled={true}
                  // onChange={(e) => setRolesOwner(e.target.checked)}
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Teacher"
                  id="roleteacher"
                  onChange={(e) => setRolesTeacher(e.target.checked)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Row className="mt-3">
            <Col>
              <Button
                type="secondary"
                title="Skip for Now"
                onClick={() => {
                  nextStep();
                }}
              />
            </Col>
            <Col>
              <Button
                buttonType="submit"
                type="primary"
                title="SAVE"
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Col>
  );
};
