import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./owner.styles.scss";

import moment from "moment";

import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";
import SelectInput from "../../components/SelectInput/select-input.component";

import { addPlan, setStatusDefault } from "../../redux/owner/owner.action";
import DatePicker from "../../components/date-picker";

const AddPlan = ({ nextStep, setSelectedStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["subdomain", "user"]);
  const { addPlanStatus, isLoadingPlan } = useSelector((state) => state.owner);

  const currentDate = new Date();
  const [selectPlan, setSelectPlan] = useState("membership");
  const [planName, setPlanName] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [addCredit, setAddCredit] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [selectTime, setSelectTime] = useState("valid_date");
  const [startValidDate, setStartValidDate] = useState(currentDate);
  const [endValidDate, setEndValidDate] = useState(currentDate);
  const [notes, setNotes] = useState("");
  const [validFor, setValidFor] = useState("");
  const [duration, setDuration] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = "";
    let finalStartDate = moment(startValidDate).format("YYYY-MM-DD");
    let finalEndDate = moment(endValidDate).format("YYYY-MM-DD");
    if (selectPlan === "membership" && selectTime === "valid_date") {
      params = {
        plan_type: selectPlan,
        plan_name: planName,
        price: price,
        tax: tax,
        time_type: selectTime,
        start_valid_date: finalStartDate,
        end_valid_date: finalEndDate,
        usage_limit: 100,
        notes: notes,
      };
    } else if (selectPlan === "membership" && selectTime === "duration") {
      params = {
        plan_type: selectPlan,
        plan_name: planName,
        price: price,
        tax: tax,
        time_type: selectTime,
        valid_for: validFor,
        duration_type: duration,
        usage_limit: usageLimit,
        notes: notes,
      };
    } else if (selectPlan === "class pass") {
      params = {
        plan_type: selectPlan,
        plan_name: planName,
        price: price,
        tax: tax,
        credits: addCredit,
        valid_for: validFor,
        duration_type: duration,
        usage_limit: usageLimit,
        notes: notes,
      };
    }

    dispatch(addPlan(params, cookies.user.token, cookies.user.studio_id));
  };

  const handleStartValidDate = (e) => {
    setStartValidDate(e);
    setShow(false);
  };

  const handleEndValidDate = (e) => {
    setEndValidDate(e);
    setShow1(false);
  };

  useEffect(() => {
    if (addPlanStatus === "success") {
      dispatch(setStatusDefault());
      nextStep();
      setSelectedStep();
    }
  }, [addPlanStatus]);

  const listDuration = [
    { label: "- Select -", value: "", selected: true },
    { label: "Day", value: "days", selected: false },
    { label: "Week", value: "weeks", selected: false },
    { label: "Month", value: "months", selected: false },
    { label: "Year", value: "years", selected: false },
  ];

  return (
    <Col>
      <div
        className="custom-card align-items-center mx-auto"
        style={{
          paddingLeft: 100,
          paddingRight: 100,
          paddingTop: 20,
          paddingBottom: 50,
          maxWidth: "32rem",
        }}
      >
        <span className="textTitle">Add a Plan</span>
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Form.Group style={{ marginBottom: 10 }}>
            <Row>
              <>
                <Col>
                  <Form.Check
                    id="member"
                    type="radio"
                    label="Membership"
                    value="membership"
                    name="plan"
                    onChange={(e) => setSelectPlan(e.target.value)}
                    checked={selectPlan === "membership"}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id="classpass"
                    type="radio"
                    value="class pass"
                    label="Class Pass"
                    name="plan"
                    onChange={(e) => setSelectPlan(e.target.value)}
                    checked={selectPlan === "class pass"}
                  />
                </Col>
              </>
            </Row>
          </Form.Group>

          {/* formMembership */}
          {selectPlan === "membership" ? (
            <>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Name"
                  placeholder="1 Month Premium class"
                  value={planName}
                  onChange={(event) => {
                    setPlanName(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Price (exclude taxes)"
                  placeholder="1,000,000"
                  value={price}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Tax (%)"
                  placeholder="10"
                  value={tax}
                  onChange={(event) => {
                    setTax(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <Row>
                  <>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Valid Date"
                        value="valid_date"
                        name="time"
                        id="time1"
                        onChange={(e) => setSelectTime(e.target.value)}
                        checked={selectTime === "valid_date"}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Duration"
                        value="duration"
                        name="time"
                        id="time2"
                        onChange={(e) => setSelectTime(e.target.value)}
                        checked={selectTime === "duration"}
                      />
                    </Col>
                  </>
                </Row>
              </Form.Group>

              {selectTime == "valid_date" ? (
                <>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Row>
                      <>
                        <Col>
                          <DatePicker
                            title="Start Date"
                            currentDate={currentDate}
                            selectedDate={startValidDate}
                            show={show}
                            setShow={setShow}
                            setDate={handleStartValidDate}
                          />
                        </Col>
                        <Col>
                          <DatePicker
                            title="End Date"
                            currentDate={currentDate}
                            selectedDate={endValidDate}
                            show={show1}
                            setShow={setShow1}
                            setDate={handleEndValidDate}
                          />
                        </Col>
                      </>
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <TextInput
                      label="Notes"
                      value={notes}
                      placeholder="Input Notes.."
                      onChange={(event) => {
                        setNotes(event.target.value);
                      }}
                    />
                  </Form.Group>
                </>
              ) : (
                // endformmembership

                <>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Row>
                      <>
                        <Col>
                          <TextInput
                            required
                            label="Valid for"
                            value={validFor}
                            placeholder="3"
                            onChange={(event) => {
                              setValidFor(event.target.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <div className={`form-group`}>
                            <SelectInput
                              required
                              label="Duration"
                              data={listDuration}
                              onChange={(event) => {
                                setDuration(event.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <TextInput
                      required
                      label="Usage Limit"
                      value={usageLimit}
                      placeholder="100"
                      onChange={(event) => {
                        setUsageLimit(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <TextInput
                      label="Notes"
                      value={notes}
                      placeholder="Input Notes.."
                      onChange={(event) => {
                        setNotes(event.target.value);
                      }}
                    />
                  </Form.Group>
                </>
              )}
            </>
          ) : (
            <>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Name"
                  placeholder="10 Credits Class"
                  value={planName}
                  onChange={(event) => {
                    setPlanName(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Price (exclude taxes)"
                  placeholder="1,000,000"
                  value={price}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Tax (%)"
                  placeholder="10"
                  value={tax}
                  onChange={(event) => {
                    setTax(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Add Credits"
                  placeholder="30"
                  value={addCredit}
                  onChange={(event) => {
                    setAddCredit(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <Row>
                  <>
                    <Col>
                      <TextInput
                        required
                        label="Valid for"
                        value={validFor}
                        placeholder="3"
                        onChange={(event) => {
                          setValidFor(event.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <div className={`form-group`}>
                        <SelectInput
                          required
                          label="Duration"
                          data={listDuration}
                          onChange={(event) => {
                            setDuration(event.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </>
                </Row>
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Usage Limit"
                  value={usageLimit}
                  placeholder="100"
                  onChange={(event) => {
                    setUsageLimit(event.target.value);
                  }}
                />
              </Form.Group>
            </>
          )}
          <Row style={{ marginTop: 20 }}>
            <>
              <Col>
                <Button
                  isLoading={isLoadingPlan}
                  type="secondary"
                  title="Skip for now"
                  onClick={() => {
                    nextStep();
                    setSelectedStep();
                  }}
                />
              </Col>
              <Col>
                <Button
                  buttonType="submit"
                  isLoading={isLoadingPlan}
                  type="primary"
                  title="CONTINUE"
                  style={{ marginLeft: "30%" }}
                />
              </Col>
            </>
          </Row>
        </Form>
      </div>
    </Col>
  );
};

export default AddPlan;
