import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Modal, Row, Table } from "react-bootstrap";

import Header from "../../../../../components/HeaderOwner/header-owner.component";
import Footer from "../../../../../components/Footer/footer.component";
import Button from "../../../../../components/Button/button.component";
import Pagination from "../../../../../components/pagination";
import AtomNumberFormat from "../../../../../components/number-format";
import DropdownCard from "../../../../../components/Button/dropdown.component";
import AtomLoading from "../../../../../components/loding-indicator";

import {
  deactivePlan,
  deletePlan,
  getAllPlans,
  setStatusDefault,
} from "../../../../../redux/owner/owner.action";
import EmptyData from "../../../../../components/no-data";
import { useOutsideOnclick } from "../../../../../services/clickOutside";
import OrganismLayout from "../../../../../components/Layout/organism-layout";

const SettingPlans = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user"]);
  const [planType, setPlanType] = useState("membership");
  const [active, setActive] = useState(1);
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, plans, deactive_status } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const tableHead = [
    "",
    "Date Created",
    "Plan Name",
    "Price",
    "Notes",
    "Status",
  ];

  tableHead.splice(3, 0, planType === "membership" ? "Usage Limit" : "Credits");

  const planTypes = [
    { name: "Membership", value: "membership" },
    { name: "Class Pass", value: "classpass" },
  ];

  useOutsideOnclick(wrapperRef, setShow);

  const handleDeletePlan = (id) => {
    dispatch(deletePlan(user_token, studio_id, id));

    setShow(false);
    setShow1({ visible: false });
    dispatch(getAllPlans(user_token, studio_id, planType, active));
  };

  const handleDeactivatePlan = (id, value) => {
    dispatch(deactivePlan(user_token, studio_id, id, value));

    setShow(false);
    setShow2({ visible: false });
  };

  useEffect(() => {
    if (deactive_status === "success") {
      dispatch(getAllPlans(user_token, studio_id, planType, active));
      dispatch(setStatusDefault());
    } else {
      dispatch(getAllPlans(user_token, studio_id, planType, active));
    }
  }, [planType, deactive_status, active]);

  return (
    <>
      <OrganismLayout title="Plans" maxWidth="60rem" className="p-0">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Col>
            <div className=" d-flex align-items-center justify-content-between p-3">
              <div className="shadow-sm b-greyblue rounded-3 heading3 d-flex overflow-hidden">
                {planTypes.map((el, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setPlanType(el.value);
                    }}
                    className={`${
                      planType === el.value
                        ? "bg-greyblue text-white"
                        : "custom-hover4"
                    } p-2 pointer`}
                  >
                    {el.name}
                  </div>
                ))}
              </div>
              <Button
                onClick={() => history.push("/add-plan")}
                type="primary"
                title="Add Plans"
              />
            </div>

            <Table responsive hover size="sm" className="mb-5">
              <thead style={{ backgroundColor: "#ededed" }}>
                <tr>
                  {tableHead.map((el, idx) => (
                    <th className="py-3" key={idx}>
                      {el}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody ref={wrapperRef}>
                {plans.data?.length > 0 ? (
                  plans.data.map((el, idx) => (
                    <tr key={idx}>
                      <td>
                        <button
                          onClick={() => {
                            setIndex(idx);
                            setShow((show) => !show);
                          }}
                          className="option-button position-relative"
                        >
                          ...
                        </button>
                        {show && index === idx && (
                          <DropdownCard
                            className="dropdown-text"
                            // positionTop
                            positionLeft={45}
                            data={[
                              {
                                name: "Edit",
                                onClick: () =>
                                  history.push({
                                    pathname: "/edit-plan",
                                    state: { id: el?.id },
                                  }),
                              },
                              {
                                name: "Delete",
                                onClick: () =>
                                  setShow1({
                                    id: el?.id,
                                    visible: true,
                                    name: el?.plan_name,
                                    plan_type: el?.plan_type,
                                  }),
                              },
                              {
                                name:
                                  el?.status === "active"
                                    ? "Deactivate"
                                    : "Activate",
                                onClick: () =>
                                  setShow2({
                                    id: el?.id,
                                    value:
                                      el?.status === "active"
                                        ? "inactive"
                                        : "active",
                                    visible: true,
                                    title:
                                      el?.status === "active"
                                        ? "Deactivate"
                                        : "Activate",
                                    name: el?.plan_name,
                                    plan_type: el?.plan_type,
                                  }),
                              },
                            ]}
                          />
                        )}
                      </td>
                      <td>{moment(el?.created_at).format("DD MMM YYYY")}</td>
                      <td>
                        <Link
                          className="text-black"
                          to={{
                            pathname: "/detail-plan",
                            state: { id: el?.id },
                          }}
                        >
                          {el?.plan_name}
                        </Link>
                      </td>
                      <td>
                        {planType === "membership"
                          ? el?.usage_limit
                          : el?.credits}
                      </td>
                      <td>
                        <AtomNumberFormat prefix="IDR " value={el?.price} />
                      </td>
                      <td>{el?.notes}</td>
                      <td>{el?.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <EmptyData />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {plans.data?.length > 0 && (
              <Pagination
                className='pb-3'
                active={active}
                setActive={setActive}
                current_page={plans.meta.pagination?.current_page}
                total_pages={plans.meta.pagination?.total_pages}
              />
            )}
          </Col>
        )}
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show1?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Delete Plan</h2>
          <p>Delete this Plan?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{show1?.name}</b>
              <br />
              <span className="heading3">{show1?.plan_type}</span>
            </p>
          </div>
          <span className="mb-3 text-danger f-12">
            Deleting this data will delete all membership data.
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow1({ visible: false })}
            />
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDeletePlan(show1?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show2?.visible}
        onHide={() => setShow2({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">{show2?.title} Plan</h2>
            <p className="pb-3">
              Please confirm you want to {show2?.title} this plan.
            </p>{" "}
            <div className="b-gray justify-content-center rounded-lg mb-5 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Plan Name</span> <br />
                  {show2?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Plan Type</span> <br />
                  {show2?.plan_type}
                </Col>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow2({ visible: false })}
            />
            <Button
              title={show2?.title}
              type="primary"
              onClick={() => handleDeactivatePlan(show2?.id, show2.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SettingPlans;
