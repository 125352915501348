import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { RiFileCopy2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Container, Row } from "react-bootstrap";
import Footer from "../../../../../components/Footer/footer.component";
import HeaderOwner from "../../../../../components/HeaderOwner/header-owner.component";
import AtomLoading from "../../../../../components/loding-indicator";
import Button from "../../../../../components/Button/button.component";
import paymentNotifUrlImg from "../../../../../assets/images/payment-notif-url.png";
import finishUrlImg from "../../../../../assets/images/finish-url.png";
import unfinishUrlImg from "../../../../../assets/images/unfinish-url.png";

export const MidtransConfiguration = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [stepSchedule, setStepSchedule] = useState([1]);
  const [url, setUrl] = useState(
    "https://api.swiftclassroom.s360.is/v1/payment-notification"
  );
  const [image, setImage] = useState(paymentNotifUrlImg);
  const { isLoadingClass } = useSelector((state) => state.owner);
  const { isLoading, studio, status, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const {
    loadinguser = isLoading,
    user,
    loginFromStudio,
  } = useSelector((state) => state.user);

  const subdomain = window.location.host.split(".")[0];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const nextStepSchedule = () => {
    let newNumber = stepSchedule.length;
    setStepSchedule([...stepSchedule, newNumber + 1]);
    if (stepSchedule.length === 2) history.push("/settings");
  };

  const truncateString = (str, num) => {
    if (str === null) {
      return "";
    } else {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "..";
    }
  };

  const data = [
    { title: "Payment Noitfication URL", step: 1 },
    // { title: "Finish Redirect URL", step: 2 },
  ];

  // useEffect(() => {
  //   if (stepSchedule.length === 2) {
  //     setImage(finishUrlImg);
  //     setUrl(`https://${subdomain}.swiftclassroom.s360.is/online-payment-success`);
  //   }
  // }, [stepSchedule.length]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <HeaderOwner title="Midtrans Configuration" />
      <Container fluid style={{ minHeight: "63vh" }}>
        {isLoadingClass ? (
          <AtomLoading />
        ) : (
          <Row
            style={{ transform: "translateY(-77px)", maxWidth: "60rem" }}
            className="mx-auto rounded-10 bg-white"
          >
            {/* <div
              style={{ maxWidth: "24rem", transform: "translateY(-20px)" }}
              className="rounded-10 shadow-sm mx-auto text-center bg-white py-3 px-4"
            >
              <p className="fw-bold">Midtrans Configuration</p>
              <div className="d-flex justify-content-between">
                {data.map((el, idx) => (
                  <div key={idx} className="d-flex">
                    <div
                      style={{ height: "18px", width: "18px" }}
                      className={`${
                        stepSchedule.includes(el.step)
                          ? "bg-greyblue"
                          : "bg-lightgrey"
                      } rounded-circle text-white fs-12`}
                    >
                      {el.step}
                    </div>
                    <span className="ms-2 regular-text">{el.title}</span>
                  </div>
                ))}
              </div>
            </div> */}
            <div style={{ maxWidth: "44rem" }} className="mx-auto py-5">
              <div className="overflow-hidden rounded-3 mb-3">
                <img src={image} alt="image" style={{ maxWidth: "100%" }} />
              </div>

              <ol className="d-flex flex-column gap-2">
                <li>Sign in to your midtrans account</li>
                <li>
                  Navigate to <b>Settings &gt; Configuration</b>
                </li>
                <li>
                  Paste link{" "}
                  <CopyToClipboard text={url}>
                    <span
                      onClick={() => toast.success("Link Copied")}
                      className="rounded-3 bg-light text-secondary px-2 pointer custom-hover"
                    >
                      {truncateString(url, 42)} <RiFileCopy2Fill />
                    </span>
                  </CopyToClipboard>{" "}
                  to Payment Notification URL
                </li>
                <li>
                  Click <b>Update</b> button
                </li>
              </ol>
              <div className="d-flex justify-content-end py-3">
                <Button
                  type="primary"
                  title="Continue"
                  onClick={() => history.push("/dashboard")}
                />
              </div>
            </div>
          </Row>
        )}
      </Container>
      <Footer />
    </Container>
  );
};
