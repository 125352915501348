import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./register.styles.scss";

import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";

import {
  verifyOtp,
  setStatusVerifyDefault,
  resendOtp,
  setStatusDefault,
} from "../../redux/user/user.action";

const VerifyOtp = ({ nextStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { studio } = useSelector((state) => state.studio);
  const { isLoading, statusVerify, status } = useSelector(
    (state) => state.user
  );

  const [otp, setOtp] = useState("");
  const [remainTime, setRemainTime] = useState(60);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      email: studio.owner.owner_email,
      password: Number(otp),
    };

    dispatch(verifyOtp(params, null));
  };

  const resendCode = () => {
    dispatch(resendOtp(studio.owner.owner_email, null));
  };

  useEffect(() => {
    if (statusVerify === "success") {
      nextStep();
      dispatch(setStatusVerifyDefault());
    }
  }, [statusVerify]);

  useEffect(() => {
    if (!remainTime) return;
    const timer = setInterval(() => {
      setRemainTime(remainTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainTime]);

  useEffect(() => {
    if (status === "success") {
      setRemainTime(60);
      dispatch(setStatusDefault());
    }
  }, [status]);

  return (
    <Row
      style={{ transform: "translateY(-77px)" }}
      className="justify-content-md-center"
    >
      <Col lg={5}>
        <Form onSubmit={handleSubmit}>
          <div className="custom-card align-items-center">
            <p className="regular-text text-center mb-5">
              We emailed you 6 (six) digits code to{" "}
              <b>{studio.owner.owner_email}.</b> Enter the code below to confirm
              your email address
            </p>

            <TextInput
              autoFocus
              required
              min={6}
              max={6}
              type="number"
              placeholder="000000"
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              style={{
                fontSize: "24px",
                fontFamily: "Roboto",
                color: "#000",
                textAlign: "center",
                width: "50%",
                margin: "0 auto",
              }}
              inputClassName="bg-light"
              className="mt-5 mb-5"
              value={otp}
              // onKeyUp={(e) => {
              //   if (e.code === "Enter") {
              //     handleSubmit();
              //   }
              // }}
            />
            <p className="regular-text text-center mb-3 mt-3">
              The verification code above is only valid for 30 minutes. Please
              don’t share this code with anyone, including those on behalf of{" "}
              {studio?.studio_name} or GigsClassroom
            </p>
            <p className="regular-text text-center">
              Didn't receive the code? <br />
              {!remainTime ? (
                <u className="pointer greyblue" onClick={() => resendCode()}>
                  Resend Code
                </u>
              ) : (
                <span className="greyblue">
                  Resend Code in {remainTime} Second.
                </span>
              )}
            </p>
            <Button
              buttonType="submit"
              type="primary"
              title="VERIFY"
              className="mt-5 mb-5"
              isLoading={isLoading}
            />
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default VerifyOtp;
