import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import logo from "./../../assets/images/swift_logo.png";
import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.png";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";

import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { ModalLogin } from "./modal";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import {
  requestOtp,
  setStatusVerifyDefault,
  logout,
  requestOtpActivate,
  setNewUser,
  setLoginFromStudio,
} from "../../redux/user/user.action";
import * as authService from "../../services/auth";

const StudioLogin = () => {
  const subdomain = window.location.host.split(".")[0];

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, message, statusVerify, login_data } = useSelector(
    (state) => state.user
  );

  const [email, setEmail] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);
  const [showModalReactivateTeacher, setShowModalReactivateTeacher] =
    useState(false);
  const [registerWithSocmed, setRegisterWithSocmed] = useState(false);
  const [method, setMethod] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const responseSocialAuth = async (provider, response) => {
    try {
      console.log(response, "response");

      const access_token = response?.accessToken;
      setMethod(provider);
      setAccessToken(access_token);
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmed(
          provider,
          access_token
        );
        console.log("result", result);
        if (result.data.data.token) {
          dispatch(setLoginFromStudio());
          dispatch(setNewUser(result.data?.data));
          history.push("/dashboard");
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
        setRegisterWithSocmed(true);
      }
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleRegisterWithSocmed = async () => {
    try {
      const result = await authService.registerWithSocmed(method, accessToken);
      console.log(result, "result");
      if (result.data.data.token) {
        dispatch(setLoginFromStudio());
        dispatch(setNewUser(result.data?.data));
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
      dispatch(requestOtp(email));
    } else {
      dispatch(requestOtp(email, subdomain));
    }
  };

  const handleClose = () => {
    setShowModalRegister(false);
  };

  const redirectToRegister = () => {
    registerWithSocmed
      ? handleRegisterWithSocmed()
      : history.push("/register-student");
  };

  const sendRequestOtpActivate = () => {
    let params = {
      email: email,
      role: login_data?.role,
    };

    dispatch(requestOtpActivate(params, subdomain));
    if (login_data?.role === "teacher") {
      setShowModalReactivateTeacher(false);
      setEmail("");
    }
    login_data?.role === "student" &&
      history.push({ pathname: "/verify", state: { isReactivate: true } });
  };

  useEffect(() => {
    if (message === "User not registered") {
      setShowModalRegister(true);
      dispatch(setStatusVerifyDefault());
    }
  }, [message]);

  useEffect(() => {
    if (login_data?.is_account_active === 0 && login_data?.role === "student") {
      setShowModalReactivateStudent(true);
    } else if (
      login_data?.is_account_active === 0 &&
      login_data?.role === "teacher"
    ) {
      setShowModalReactivateTeacher(true);
    }
  }, [login_data]);

  useEffect(() => {
    if (statusVerify === "success" && login_data?.is_account_active === 1) {
      history.push("/verify");
    }
  }, [statusVerify]);

  return (
    <Container fluid>
      <Row style={{ minHeight: "100vh" }}>
        <Col lg={7} md={7} className="login-left-image"></Col>
        <Col lg={5} className="d-flex justify-content-center">
          <div className="login-content py-5">
            <img alt="logo" src={logo} className="swift-logo mb-2" />
            <h1 className="heading1 mt-4 mb-5">Register / Log In</h1>
            <Form className="w-100" onSubmit={handleClick}>
              <TextInput
                required
                autoFocus
                label="Email"
                placeholder="johndoe@gmail.com"
                onChange={handleChange}
                value={email}
              />
              <Button
                buttonType="submit"
                title="CONTINUE"
                type="primary"
                className="mt-4"
                style={{ width: "100%" }}
                isLoading={isLoading}
              />
            </Form>

            {/* <span className="custom-form-label mt-3 mb-3">Or, use</span>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => {
                return (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="google-btn custom-hover"
                  >
                    <img
                      alt="logo"
                      style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "10px",
                      }}
                      src={google}
                    />{" "}
                    Continue with Google
                  </button>
                );
              }}
              onSuccess={(res) => responseSocialAuth("google", res)}
              onFailure={(res) => responseSocialAuth("google", res)}
            />
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              size="small"
              textButton="Continue with Facebook"
              icon={
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    marginRight: "10px",
                  }}
                  src={facebook}
                  alt="logo"
                />
              }
              fields="name,email,picture"
              callback={(res) => responseSocialAuth("facebook", res)}
              cssClass="facebook-btn mt-3 custom-hover"
            /> */}

            <hr style={{ width: "100%" }} className="my-3" />
            <Button
              onClick={() => history.push("/public-class-list")}
              title="Browse Classes"
              type="outline-secondary"
              className="w-100"
            />
          </div>
        </Col>
      </Row>
      <Footer />

      <ModalLogin
        show={showModalRegister}
        title="Email Not Registered"
        label="Continue register with"
        email={email}
        titleButton1={"CHANGE"}
        titleButton2={"CONFIRM"}
        handleClose={handleClose}
        handleSubmit={redirectToRegister}
      />

      <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      />

      <ModalLogin
        show={showModalReactivateTeacher}
        title="Your Account Is Currently Being Deactivated"
        label="Please contact the studio owner to re-adding your teacher account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"SEND EMAIL"}
        handleClose={() => setShowModalReactivateTeacher(false)}
        handleSubmit={sendRequestOtpActivate}
      />
    </Container>
  );
};

export default StudioLogin;
