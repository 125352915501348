import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./register.styles.scss";
import registerImage1 from "./../../assets/images/register-image1.png";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import { Container, Row, Col, Modal, Spinner } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import Header from "../../components/Header/header.component";

import { setStatusDefault } from "../../redux/studio/studio.action";

const CreatingStudio = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["user", "subdomain"]);

  const { user: auth, isLoading } = useSelector((state) => state.user);

  const { status, subdomain, user_studio } = useSelector(
    (state) => state.studio
  );

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        setCookie("user", user_studio, {
          path: "/",
          domain: process.env.REACT_APP_HOST,
        });
        setCookie("subdomain", subdomain, {
          path: "/",
          domain: process.env.REACT_APP_HOST,
        });

        dispatch(setStatusDefault());
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/onboarding`;
      }, 30000);
    }
  }, [status]);

  return (
    <Row
      style={{ transform: "translateY(-77px)" }}
      className="justify-content-md-center"
    >
      <Col lg={5}>
        <div
          className="custom-card align-items-center"
          style={{
            paddingLeft: 150,
            paddingRight: 150,
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <span class="Please-wait-were-creating-your-online-studio">
            Please wait, we’re creating your online studio.
          </span>
          <Spinner
            variant="info"
            animation="border"
            size="md"
            style={{ height: 100, width: 100 }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default CreatingStudio;
