import React from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import "./../students.styles.scss";
import AtomNumberFormat from "../../../../components/number-format";
import moment from "moment";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const PaymentProcessed = () => {
  const history = useHistory();
  const { payment_processed } = useSelector((state) => state.student);

  return (
    <OrganismLayout maxWidth="42rem">
      <Col className="mx-auto p-3" style={{ maxWidth: "28rem" }}>
        <h1 className="heading1 text-center mb-5">THANK YOU!</h1>
        <div className="b-gray rounded-lg p-3 mb-5 lh-1">
          <Row>
            <Col>Order ID</Col>
            <Col className="d-flex justify-content-end fs-5">
              {payment_processed?.order_number}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Date Confirmation</Col>
            <Col className="d-flex justify-content-end">
              {moment(payment_processed.payment?.verification_date).format(
                "DD MMM YYYY, HH.mm"
              )}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Total Payment</Col>
            <Col className="d-flex justify-content-end">
              <AtomNumberFormat
                prefix="IDR "
                value={payment_processed.payment?.total_amount}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Payment Method</Col>
            <Col className="d-flex justify-content-end">Bank Transfer</Col>
          </Row>
          <hr />
          <Row>
            <Col>Bank</Col>
            <Col className="d-flex justify-content-end">
              {payment_processed.payment?.bank_name}
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-center gap-4 mt-5">
          <Button
            onClick={() =>
              history.push({ pathname: "/dashboard", state: true })
            }
            type="primary"
            title="Back"
          />
        </div>
      </Col>
    </OrganismLayout>
  );
};

export default PaymentProcessed;
