import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";

import HeaderOwner from "../../../../components/HeaderOwner/header-owner.component";
import Footer from "../../../../components/Footer/footer.component";
import Button from "../../../../components/Button/button.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import SelectInput from "../../../../components/SelectInput/select-input.component";
import midtransLogo from "../../../../assets/images/midtrans.png";

import { useCookies } from "react-cookie";
import { HiMinusCircle } from "react-icons/hi";
import { getListBanks } from "../../../../redux/bank/bank.action";
import {
  addUpdatePayment,
  getPaymentDetail,
} from "../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import OnboardingStatus from "../../../../components/onboarding-status";
import { toast } from "react-toastify";

const SettingPayment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [onlinePayment, setOnlinePayment] = useState(false);
  const [bankTransfer, setBankTransfer] = useState(true);
  const [clientKey, setClientKey] = useState("");
  const [serverKey, setServerKey] = useState("");
  const [bankList, setBankList] = useState([]);
  const [bankId, setBankId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");

  const { banks } = useSelector((state) => state.banks);
  const { isLoadingPayment, payment_detail, onboarding_status } = useSelector(
    (state) => state.owner
  );
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const list_bank = banks.data?.length > 0 ? banks.data : [];
  const isOnboarding = props.location.state?.isOnboarding;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const dataBank = [
    { label: "Choose Bank Name", value: "", selected: true },
    ...list_bank,
  ];

  const handleDelete = (idx) => {
    let deletedItem = bankList[idx];
    let newData = bankList.filter((e) => e !== deletedItem);

    setBankList(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      bank_transfer: bankTransfer,
      online_payment: onlinePayment,
      midtrans_client_key: onlinePayment ? clientKey : "",
      midtrans_server_key: onlinePayment ? serverKey : "",
      list_bank: bankList,
    };

    dispatch(addUpdatePayment(params, user_token, studio_id));
    history.push({
      pathname: "/midtrans-configuration",
      state: { isOnboarding: false },
    });
    dispatch(getPaymentDetail(user_token, studio_id));
  };

  useEffect(() => {
    dispatch(getPaymentDetail(user_token, studio_id));
    dispatch(getListBanks(user_token, studio_id));
  }, []);

  useEffect(() => {
    if (onboarding_status?.payment_information) {
      setOnlinePayment(payment_detail?.online_payment);
      setBankTransfer(payment_detail?.bank_transfer);
      setClientKey(payment_detail?.midtrans_client_key);
      setServerKey(payment_detail?.midtrans_server_key);
      setBankList(payment_detail?.list_bank);
    }
  }, [payment_detail]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <HeaderOwner title="Payment" />
        {isOnboarding && <OnboardingStatus selectedStep={2} />}
        {isLoadingPayment ? (
          <AtomLoading />
        ) : (
          <Row
            style={{ transform: "translateY(-77px)" }}
            className="justify-content-md-center"
          >
            <Col lg={3} className="p-3 bg-white rounded-lg shadow-sm">
              <p className="fw-bold">Configure Payment Information</p>
              <Form onSubmit={handleSubmit}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="online-payment"
                    id="online-payment"
                    defaultChecked={onlinePayment}
                    onChange={(e) => setOnlinePayment(e.target.checked)}
                  />
                  <label
                    style={{
                      margin: 0,
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#000",
                    }}
                    htmlFor="online-payment"
                  >
                    Online Payment
                  </label>
                  <p className="heading3">
                    Accept online payment with midtrans.
                  </p>
                  {onlinePayment && (
                    <div className="b-gray rounded-3 p-2 heading3 bg-light-grey">
                      <p className="mb-2">Accepted Payment</p>
                      <img
                        className="b-gray rounded-10 px-2 py-1 mb-2"
                        src={midtransLogo}
                        alt="logo"
                        width={100}
                      />
                      <p>
                        For each transsaction,a fee will charged by Midtrans.
                        Please refer to the{" "}
                        <a href="https://midtrans.com">Midtrans</a> website for
                        actual rates.
                      </p>
                      <TextInput
                        required={onlinePayment}
                        className="mb-2"
                        inputClassName="bg-light-grey"
                        label="Midtrans Client API Key"
                        placeholder="Input Midtrans Client API Key..."
                        value={clientKey}
                        onChange={(e) => setClientKey(e.target.value)}
                      />
                      <p>
                        Copy the Midtrans Client API Key from the Midtrans
                        dashboard.
                      </p>
                      <TextInput
                        required={onlinePayment}
                        className="mb-2"
                        inputClassName="bg-light-grey"
                        label="Midtrans Server API Key"
                        placeholder="Input Midtrans Server API Key..."
                        value={serverKey}
                        onChange={(e) => setServerKey(e.target.value)}
                      />
                      <p>
                        Copy the Midtrans Server API Key from the Midtrans
                        dashboard.
                      </p>
                    </div>
                  )}
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="bank-transfer"
                    id="bank-transfer"
                    defaultChecked={bankTransfer}
                    onChange={(e) => setBankTransfer(e.target.checked)}
                  />
                  <label
                    style={{
                      margin: 0,
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#000",
                    }}
                    htmlFor="bank-transfer"
                  >
                    Bank Transfer
                  </label>
                  <p className="heading3">
                    Allow student to transfer the payment manually.
                  </p>
                  {bankTransfer && (
                    <div className="b-gray rounded-3 p-2 heading3 bg-light-grey mb-3">
                      <b>Bank List</b>
                      {bankList?.length > 0 &&
                        bankList.map((el, idx) => (
                          <div key={idx} className="mb-3">
                            <div className="my-2 d-flex justify-content-between align-items-center">
                              <div className="d-flex flex-column">
                                <b>{el?.bank_name}</b>
                                <span>{el?.bank_account_number}</span>
                                <span>{el?.account_holder_name}</span>
                              </div>
                              <HiMinusCircle
                                onClick={() => {
                                  handleDelete(idx);
                                }}
                                className="text-danger fs-5 pointer custom-hover"
                              />
                            </div>
                            <div
                              className="b-gray"
                              style={{ borderStyle: "dashed" }}
                            ></div>
                          </div>
                        ))}
                      <SelectInput
                        className="mb-2"
                        selectClassName="bg-light-grey"
                        label="Choose Bank Account"
                        data={dataBank}
                        onChange={(e) => setBankId(e.target.value)}
                      />
                      <TextInput
                        type="number"
                        className="mb-2"
                        inputClassName="bg-light-grey px-2"
                        label="Bank Account Number"
                        placeholder="Input Bank Account Number..."
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                      <TextInput
                        className="mb-4"
                        inputClassName="bg-light-grey px-2"
                        label="Account Holder Name"
                        placeholder="Input Account Holder Name..."
                        value={accountHolderName}
                        onChange={(e) => setAccountHolderName(e.target.value)}
                      />
                      <div className="d-flex justify-content-center">
                        <Button
                          type="primary"
                          title="ADD"
                          onClick={() => {
                            if (
                              bankId.split(",")[0] === "" ||
                              accountNumber === "" ||
                              accountHolderName === ""
                            ) {
                              toast.error(
                                "Please Choose Bank / Input Bank Account Number / Input Account Holder Name"
                              );
                              return;
                            } else {
                              setBankList([
                                ...bankList,
                                {
                                  bank_id: bankId.split(",")[0],
                                  bank_name: bankId.split(",")[1],
                                  account_holder_name: accountHolderName,
                                  bank_account_number: accountNumber,
                                },
                              ]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    height: onlinePayment || bankTransfer ? "" : "250px",
                  }}
                  className="mt-5 d-flex justify-content-between"
                >
                  <Button
                    type="outline-secondary"
                    title={isOnboarding ? "Skip for Now" : "Cancel"}
                    onClick={() => history.push(isOnboarding ? "/dashboard" : "/settings")}
                  />
                  <Button
                    isLoading={
                      (onlinePayment === false && bankTransfer === false) ||
                      (bankTransfer && bankList?.length === 0)
                    }
                    type="primary"
                    title="SAVE"
                    buttonType="submit"
                  />
                </div>
              </Form>
            </Col>
          </Row>
        )}
      </div>
      <Footer />
    </Container>
  );
};

export default SettingPayment;
