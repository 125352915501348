import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Row } from "react-bootstrap";

import ClassDurationSetup from "../addClass/classduration-setup-component";

import BookingHistory from "./booking-history";

export const EditSchedules = ({ id }) => {
  const [stepSchedule, setStepSchedule] = useState(1);
  const [params, setParams] = useState({});

  const nextStepSchedule = () => {
    setStepSchedule(stepSchedule + 1);
  };

  const renderStepsSchedule = () => {
    switch (stepSchedule) {
      case 1:
        return (
          <ClassDurationSetup
            setParams={setParams}
            nextStepSchedule={nextStepSchedule}
            id={id}
          />
        );
      case 2:
        return <BookingHistory params={params} id={id} />;
      default:
    }
  };

  const { isLoading, studio, status } = useSelector((state) => state.studio);
  const { loadinguser = isLoading, user } = useSelector((state) => state.user);

  useEffect(() => {
    if (status === "success") {
      // nextStep()
      // dispatch(setStatusDefault())
    }
  }, [status]);

  return (
    <Row
      style={{ transform: "translateY(-30px)" }}
      className="justify-content-md-center"
    >
      <span className="text-white text-center my-3 fw-bold">
        {stepSchedule === 1 ? "Edit Schedules" : "The Cancellation List"}
      </span>
      <div className="d-flex align-items-center justify-content-md-center gap-2 mt-0 mb-2">
        <div
          style={{ height: "18px", width: "18px" }}
          className="rounded-circle shadow bg-greyblue"
        ></div>
        <div
          style={{ height: "18px", width: "18px" }}
          className={`rounded-circle shadow ${
            stepSchedule === 2 ? "bg-greyblue" : "bg-light"
          }`}
        ></div>
      </div>
      {renderStepsSchedule()}
    </Row>
  );
};
