import React, { useEffect, useRef } from "react";
import Slider from "react-slick";

import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./../students.styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import AtomLoading from "./../../../../components/loding-indicator";
import AtomNumberFormat from "./../../../../components/number-format";

import { getListOfferingPlans } from "../../../../redux/student/student.action";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const BuyPlans = () => {
  const slider = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies(["user"]);

  const { payment_detail } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, plan_list } = useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;
  const plans_type_class_pass = plan_list?.filter(
    (item) => item?.plan_type_group === "class pass"
  );
  const plans_type_membership = plan_list?.filter(
    (item) => item?.plan_type_group === "membership"
  );

  const CustomPrevButton = (props) => {
    const { className, style, onClick } = props;
    return (
      <div>
        <FaAngleLeft
          style={{
            ...style,
            borderRadius: "50%",
            width: 25,
            height: 25,
          }}
          onClick={onClick}
          className={`${className} greyblue bg-white shadow-sm custom-hover`}
        />
      </div>
    );
  };

  const CustomNextButton = (props) => {
    const { className, style, onClick } = props;
    return (
      <div>
        <FaAngleRight
          style={{
            ...style,
            borderRadius: "50%",
            width: 25,
            height: 25,
          }}
          onClick={onClick}
          className={`${className} greyblue bg-white shadow-sm custom-hover`}
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(getListOfferingPlans(user_token, studio_id));
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };
  return (
    <OrganismLayout maxWidth="48rem">
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Col className="text-center">
          <h1 className="heading1 mb-5">CHOOSE YOUR PLANS</h1>
          {plans_type_class_pass?.length > 0 && (
            <>
              <span>Based on Credits</span>
              <div className="mt-3">
                <Slider
                  {...settings}
                  prevArrow={<CustomPrevButton />}
                  nextArrow={<CustomNextButton />}
                >
                    {plans_type_class_pass[0]?.plan_data
                      .filter((data) => data.status_plan === "active")
                      .map((el, idx) => (
                        <div key={idx}>
                          <div
                            style={{ minHeight: "20rem" }}
                            className="Rectangle-15 p-3 mb-5 mx-auto d-flex flex-column justify-content-between b-greyblue custom-hover5"
                          >
                            <h6>{el?.plan_name}</h6>
                            <b>{el?.credits} Credits</b>
                            <h5>
                              <AtomNumberFormat
                                prefix="IDR "
                                value={el?.total_amount}
                              />
                            </h5>
                            <p className="py-3 fs-12 overflow-hidden">
                              {el?.notes}
                            </p>
                            <span className="fs-12">{el?.duration}</span> <br />
                            {el?.user_has_active_plan ? (
                              <span className="fs-10 text-danger mb-3">
                                You already have this plan
                              </span>
                            ) : (
                              <div className=""></div>
                            )}
                            <Link
                              to={{
                                pathname: "/choose-payment",
                                state: { plan_id: el?.plan_id },
                              }}
                            >
                              <button
                                disabled={
                                  (el?.user_has_active_plan &&
                                    el?.user_has_pending_payment === false) ||
                                  (el?.user_has_active_plan === false &&
                                    el?.user_has_pending_payment)
                                }
                                className={`${
                                  (el?.user_has_active_plan &&
                                    el?.user_has_pending_payment === false) ||
                                  (el?.user_has_active_plan === false &&
                                    el?.user_has_pending_payment)
                                    ? "disabled"
                                    : ""
                                } bg-greyblue text-white justify-content-center border-0 fw-bold rounded-3 w-100 p-2`}
                              >
                                {el?.user_has_active_plan &&
                                el?.user_has_pending_payment === false
                                  ? "REGISTERED"
                                  : el?.user_has_active_plan === false &&
                                    el?.user_has_pending_payment
                                  ? "PENDING"
                                  : payment_detail?.bank_transfer === false &&
                                    payment_detail?.online_payment === false
                                  ? "NOT AVAILABLE"
                                  : "BOOK NOW"}
                              </button>
                            </Link>
                          </div>
                        </div>
                      ))}
                </Slider>
              </div>
            </>
          )}
          {plans_type_membership?.length && (
            <>
              <span>Based on Session</span>
              <div className="mt-3">
                <Slider
                  {...settings}
                  prevArrow={<CustomPrevButton />}
                  nextArrow={<CustomNextButton />}
                >
                {plans_type_membership[0]?.plan_data
                  .filter((data) => data.status_plan === "active")
                  .map((el, idx) => (
                    <div key={idx}>
                      <div
                        style={{ minHeight: "20rem" }}
                        className="Rectangle-15 p-3 mb-5 mx-auto d-flex flex-column justify-content-between b-greyblue custom-hover5"
                      >
                        <h6>{el?.plan_name}</h6>
                        <b>{el?.session} Session</b>
                        <h5>
                          <AtomNumberFormat
                            prefix="IDR "
                            value={el?.total_amount}
                          />
                        </h5>
                        <p className="py-3 fs-12 overflow-hidden">
                          {el?.notes}
                        </p>
                        <span className="fs-12">{el?.duration}</span> <br />
                        {el?.user_has_active_plan &&
                        el?.user_has_pending_payment === false ? (
                          <span className="fs-10 text-danger mb-3">
                            You already have this plan
                          </span>
                        ) : (
                          <div className=""></div>
                        )}
                        <Link
                          to={{
                            pathname: "/choose-payment",
                            state: { plan_id: el?.plan_id },
                          }}
                        >
                          <button
                            disabled={
                              (el?.user_has_active_plan &&
                                el?.user_has_pending_payment === false) ||
                              (el?.user_has_active_plan === false &&
                                el?.user_has_pending_payment) ||
                              (payment_detail?.bank_transfer === false &&
                                payment_detail?.online_payment === false)
                            }
                            className={`${
                              (el?.user_has_active_plan &&
                                el?.user_has_pending_payment === false) ||
                              (el?.user_has_active_plan === false &&
                                el?.user_has_pending_payment)
                                ? "disabled"
                                : ""
                            } bg-greyblue text-white justify-content-center border-0 fw-bold rounded-3 w-100 p-2`}
                          >
                            {el?.user_has_active_plan &&
                            el?.user_has_pending_payment === false
                              ? "REGISTERED"
                              : el?.user_has_active_plan === false &&
                                el?.user_has_pending_payment
                              ? "PENDING"
                              : payment_detail?.bank_transfer === false &&
                                payment_detail?.online_payment === false
                              ? "NOT AVAILABLE"
                              : "BOOK NOW"}
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </>
          )}
        </Col>
      )}
    </OrganismLayout>
  );
};

export default BuyPlans;
