import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import { HiMail, HiPhone } from "react-icons/hi";

import Pagination from "../../../../components/pagination";
import DropdownCard from "../../../../components/Button/dropdown.component";
import AtomLoading from "../../../../components/loding-indicator";
import ModalDelete from "../../../../components/Modal/delete";
import { toast } from "react-toastify";
import EmptyData from "../../../../components/no-data";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { useOutsideOnclick } from "../../../../services/clickOutside";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { deleteTeacher, getAllTeachers } from "../../../../services/teachers";

export const Teachers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user", "subdomain"]);
  const [active, setActive] = useState(1);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState({
    id: "",
    visible: false,
    name: "",
    email: "",
  });
  const [teachers, setTeachers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { teacher, isLoadingTeacher } = useSelector((state) => state.owner);
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;  

  useOutsideOnclick(wrapperRef, setShow);

  const handleDelete = async () => {
    try {
      await deleteTeacher(user_token, studio_id, show1.id)

      setShow1({ visible: false });
      history.push("/Teachers");
      toast.success("Teacher account is successfully deleted!");
      getTeachers();
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getTeachers = async () => {
    setIsLoading(true);
    try {
      const res = await getAllTeachers(user_token, studio_id, active);

      setTeachers(res.data.data);
      setPagination(res.data.meta);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    getTeachers();
  }, [active]);

  return (
    <>
      <OrganismLayout title="Teachers" maxWidth="44rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Col>
            <Button
              title="Add Teacher"
              type="primary"
              onClick={() => {
                history.push("/add-teacher");
              }}
            />
            <div ref={wrapperRef} className="students my-3">
              {teachers.length > 0 ? (
                teachers.map((item, i) => (
                  <div key={i} className="single-student d-flex gap-4">
                    <div className="d-flex gap-3">
                      <div className="position-relative" lg={1}>
                        <button
                          onClick={() => {
                            setShow((show) => !show);
                            setIndex(i);
                          }}
                          className="option-button"
                        >
                          ...
                        </button>
                        {show && index === i && (
                          <DropdownCard
                            className="dropdown-text"
                            positionTop
                            positionLeft={45}
                            data={[
                              {
                                name: "Edit",
                                onClick: () =>
                                  history.push({
                                    pathname: `/teacher/${item?.id}/edit`,
                                    state: { id: item?.id },
                                  }),
                              },
                              {
                                name: "Delete",
                                onClick: () => {
                                  setShow1({
                                    id: item?.id,
                                    visible: true,
                                    name: item?.first_name,
                                    last_name: item?.last_name,
                                    email: item?.email,
                                  });
                                  setShow(false);
                                },
                              },
                            ]}
                          />
                        )}
                      </div>

                      <div>
                        <Link
                          to={{
                            pathname: `/teacher/${item?.id}/detail`,
                            state: { id: item?.id },
                          }}
                          className="text-black fw-bold"
                        >
                          {item?.first_name} {item?.last_name}
                        </Link>
                        <p>
                          <HiMail
                            color="#CE7499"
                            size={14}
                            style={{ marginRight: 5 }}
                          />
                          {item?.email}
                        </p>
                      </div>
                    </div>

                      <div>
                        <HiPhone
                          color="#CE7499"
                          size={12}
                          style={{ marginRight: 5 }}
                        />
                        {item.phone_number !== null ? (
                          item?.phone_number
                        ) : (
                          <em className="custom-form-label fs-12">
                            "Not available"
                          </em>
                        )}
                      </div>
                  </div>
                ))
              ) : (
                <EmptyData />
              )}
            </div>
            {teachers.length > 0 && (
              <Pagination
                active={active}
                setActive={setActive}
                current_page={pagination.pagination?.current_page}
                total_pages={pagination.pagination?.total_pages}
              />
            )}
          </Col>
        )}
      </OrganismLayout>

      <ModalDelete
        show={show1}
        setShow={setShow1}
        subject={"Teacher"}
        messages={
          "Please delete all class assignments related to the teacher before deleteing the teacher account."
        }
        handleDelete={handleDelete}
      />
    </>
  );
};
