import StudentActionTypes from "./student.types";

const INITIAL_STATE = {
  // Dashboard
  activePlan: [],
  isLoadingActivePlan: false,
  classes: [],
  isLoadingClasses: false,
  
  // Profile
  profile: {},
  user_role: [],
  isLoading: false,
  email: "",
  statusOtp: "default",
  statusVerifyEmail: "default",

  // Public Classes
  public_classes: [],

  // Plans
  transaction_list: [],
  plan_list: [],
  detail_plan: [],
  statusDeactive: "default",

  // Payment
  order_resp: [],
  isLoadingOrder: [],
  payment_processed: [],
  order_status: "default",
  statusVerification: "default",

  // Class
  class_list: [],
  class_detail: [],
  active_plan: [],
  active_class: [],
  cancel_class_status: "default",

  status: "default",
};

const StudentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StudentActionTypes.GET_ACTIVE_PLAN:
      return {
        ...state,
        isLoadingActivePlan: true,
      };
    case StudentActionTypes.GET_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        activePlan: action.payload,
        status: "success",
        isLoadingActivePlan: false,
      };
    case StudentActionTypes.GET_ACTIVE_PLAN_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingActivePlan: false,
        message: action.message,
      };
    case StudentActionTypes.GET_CLASSES:
      return {
        ...state,
        isLoadingClasses: true,
      };
    case StudentActionTypes.GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
        status: "success",
        isLoadingClasses: false,
      };
    case StudentActionTypes.GET_CLASSES_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.GET_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudentActionTypes.GET_PROFILE_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudentActionTypes.GET_USER_ROLE:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        user_role: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudentActionTypes.GET_USER_ROLE_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        statusOtp: "success",
        email: action.payload,
        isLoading: false,
      };
    case StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL_FAILED:
      return {
        ...state,
        statusOtp: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudentActionTypes.REQUEST_OTP_CHANGE_EMAIL_DEFAULT:
      return {
        ...state,
        statusOtp: "default",
        isLoading: false,
        message: "",
      };
    case StudentActionTypes.VERIFY_OTP_EMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.VERIFY_OTP_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statusVerifyEmail: "success",
      };
    case StudentActionTypes.VERIFY_OTP_EMAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        statusVerifyEmail: "failed",
      };
    case StudentActionTypes.VERIFY_OTP_EMAIL_DEFAULT:
      return {
        ...state,
        isLoading: false,
        message: "",
        statusVerifyEmail: "default",
      };
    case StudentActionTypes.UPDATE_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        status: "success",
        isLoading: false,
      };
    case StudentActionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudentActionTypes.SET_STATUS_DEFAULT:
      return {
        ...state,
        status: "default",
        order_status: "default",
        statusVerification: "default",
        cancel_class_status: "default",
      };
    case StudentActionTypes.GET_PUBLIC_LIST_OFFERING_CLASSES:
      return {
        ...state,
        isLoadingClasses: true,
      };
    case StudentActionTypes.GET_PUBLIC_LIST_OFFERING_CLASSES_SUCCESS:
      return {
        ...state,
        public_classes: action.payload,
        status: "success",
        isLoadingClasses: false,
      };
    case StudentActionTypes.GET_PUBLIC_LIST_OFFERING_CLASSES_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudentActionTypes.GET_LIST_OFFERING_PLAN:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.GET_LIST_OFFERING_PLAN_SUCCESS:
      return {
        ...state,
        plan_list: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudentActionTypes.GET_LIST_OFFERING_PLAN_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.GET_LIST_PLAN_TRANSACTION:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.GET_LIST_PLAN_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction_list: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudentActionTypes.GET_LIST_PLAN_TRANSACTION_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.GET_DETAIL_PLAN_TRANSACTION:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.GET_DETAIL_PLAN_TRANSACTION_SUCCESS:
      return {
        ...state,
        detail_plan: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudentActionTypes.GET_DETAIL_PLAN_TRANSACTION_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.DEACTIVE_PLAN:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.DEACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        statusDeactive: "success",
        isLoading: false,
      };
    case StudentActionTypes.DEACTIVE_PLAN_FAILED:
      return {
        ...state,
        statusOtp: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudentActionTypes.MANUAL:
      return {
        ...state,
        isLoadingOrder: true,
      };
    case StudentActionTypes.MANUAL_SUCCESS:
      return {
        ...state,
        order_resp: action.payload,
        order_status: "success",
        isLoadingOrder: false,
      };
    case StudentActionTypes.MANUAL_FAILED:
      return {
        ...state,
        order_status: "failed",
        isLoadingOrder: false,
        message: action.message,
      };
    case StudentActionTypes.GET_LIST_OFFERING_CLASSES:
      return {
        ...state,
        isLoadingClasses: true,
      };
    case StudentActionTypes.GET_LIST_OFFERING_CLASSES_SUCCESS:
      return {
        ...state,
        class_list: action.payload,
        status: "success",
        isLoadingClasses: false,
      };
    case StudentActionTypes.GET_LIST_OFFERING_CLASSES_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.GET_DETAIL_OFFERING_CLASSES:
      return {
        ...state,
        isLoadingClasses: true,
      };
    case StudentActionTypes.GET_DETAIL_OFFERING_CLASSES_SUCCESS:
      return {
        ...state,
        class_detail: action.payload,
        status: "success",
        isLoadingClasses: false,
      };
    case StudentActionTypes.GET_DETAIL_OFFERING_CLASSES_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.CANCEL_CLASS:
      return {
        ...state,
      };
    case StudentActionTypes.CANCEL_CLASS_SUCCESS:
      return {
        ...state,
        cancel_class_status: "success",
      };
    case StudentActionTypes.CANCEL_CLASS_FAILED:
      return {
        ...state,
        cancel_class_status: "failed",
        message: action.message,
      };
    case StudentActionTypes.GET_MY_ACTIVE_PLAN:
      return {
        ...state,
        isLoadingClasses: true,
      };
    case StudentActionTypes.GET_MY_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        active_plan: action.payload,
        status: "success",
        isLoadingClasses: false,
      };
    case StudentActionTypes.GET_MY_ACTIVE_PLAN_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.GET_MY_ACTIVE_CLASS:
      return {
        ...state,
        isLoadingClasses: true,
      };
    case StudentActionTypes.GET_MY_ACTIVE_CLASS_SUCCESS:
      return {
        ...state,
        active_class: action.payload,
        status: "success",
        isLoadingClasses: false,
      };
    case StudentActionTypes.GET_MY_ACTIVE_CLASS_FAILED:
      return {
        ...state,
        status: "failed",
        isLoadingClasses: false,
        message: action.message,
      };
    case StudentActionTypes.PAYMENT_VERIFICATION:
      return {
        ...state,
        isLoading: true,
      };
    case StudentActionTypes.PAYMENT_VERIFICATION_SUCCESS:
      return {
        ...state,
        payment_processed: action.payload,
        statusVerification: "success",
        isLoading: false,
      };
    case StudentActionTypes.PAYMENT_VERIFICATION_FAILED:
      return {
        ...state,
        statusVerification: "failed",
        isLoading: false,
        message: action.message,
      };
    default:
      return state;
  }
};

export default StudentReducer;
