import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Modal,
  Table,
  Form,
  Tooltip,
} from "react-bootstrap";
import "./../students.styles.scss";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import Button from "../../../../components/Button/button.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import AtomNumberFormat from "../../../../components/number-format";
import Pagination from "../../../../components/pagination";
import {
  FaSort,
  FaPowerOff,
  FaRegCreditCard,
  FaRegFileAlt,
} from "react-icons/fa";

import { useCookies } from "react-cookie";

import {
  getListPlanTransaction,
  deactivatePlan,
  setStatusDefault,
} from "../../../../redux/student/student.action";
import EmptyData from "../../../../components/no-data";
import AtomLoading from "../../../../components/loding-indicator";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const PlanList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState({
    id: "",
    visible: false,
    name: "",
    expired_date: "",
    buy_date: "",
  });
  const [cookies, setCookies] = useCookies(["user"]);
  const [active, setActive] = useState(1);
  const [sort, setsort] = useState("asc");
  const [field, setField] = useState("plan_name");
  const [message, setMessage] = useState("");

  const { client_key, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { isLoading, transaction_list, statusDeactive } = useSelector(
    (state) => state.student
  );

  const subdomain = window.location.host.split(".")[0];
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const tableHead = [
    { name: "PLAN NAME", value: "plan_name" },
    { name: "PRICE", value: "total_amount" },
    { name: "EXPIRE DATE", value: "expired_date" },
    { name: "BUY DATE", value: "order_date" },
    { name: "CREDITS/SESSIONS", value: "" },
    { name: "PLAN STATUS", value: "plan_status" },
    { name: "" },
    { name: "" },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {sort}
    </Tooltip>
  );

  const handleDeactivatePlan = (e, id) => {
    e.preventDefault();
    let params = {
      message_deactived: message,
    };

    dispatch(deactivatePlan(user_token, studio_id, params, id));
    dispatch(setStatusDefault());

    setMessage("");
    setShow({ visible: false });
  };

  useEffect(() => {
    const url = `/orders/plan?page=${active}&sort_field=${field}&sort_order=${sort}`;
    dispatch(getListPlanTransaction(user_token, studio_id, url));
  }, [active, field, sort, statusDeactive]);

  useEffect(() => {
    //change this to the script source you want to load, for example this is snap.js sandbox env
    const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_SNAP_URL;
    //change this according to your client-key
    const myMidtransClientKey = client_key?.client_key;

    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);

    document.body.appendChild(scriptTag);
    console.log("Done appending snap.js");
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [client_key]);

  const continuePayment = (midtrans_token) => {
    window.snap.pay(midtrans_token, {
      onSuccess: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/online-payment-success`;
        console.log(result, "result succes");
      },
      onPending: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/dashboard`;
        console.log(result, "result pending");
      },
      onError: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/dashboard`;
        console.log(result, "result error");
      },
      onClose: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/dashboard`;
        console.log(result, "result close");
      },
    });
  };

  return (
    <>
      <OrganismLayout maxWidth="72rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Col>
            <div className="d-flex justify-content-between p-3">
              <div className=""></div>
              <h1
                className="heading1"
                style={{ transform: "translateX(80px)" }}
              >
                PLAN TRANSACTIONS
              </h1>
              <Link to="/buy-plan">
                <Button title="Buy Plan" type="primary" />
              </Link>
            </div>
            <Table style={{fontSize: "14px" }} responsive hover size="sm" className="mb-5">
              <thead>
                <tr>
                  {tableHead.map((el, idx) => (
                    <th className="py-3 lightaquamarine" key={idx}>
                      {/* <OverlayTrigger placement="top" overlay={renderTooltip}> */}
                      <div
                        onClick={() => {
                          setField(el?.value);
                          setsort(sort === "desc" ? "asc" : "desc");
                        }}
                        className="d-flex align-items-center pointer custom-hover"
                      >
                        {el?.name !== "" && (
                          <FaSort className="greyblue fs-12" />
                        )}
                        {el?.name}
                      </div>
                      {/* </OverlayTrigger> */}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transaction_list.data?.length > 0 ? (
                  transaction_list.data?.map((el, idx) => (
                    <tr key={idx}>
                      <td>
                        <Link
                          className="text-left text-black"
                          to={{
                            pathname: `/plan-transaction/${el?._id}/detail`,
                            state: { id: el?._id },
                          }}
                        >
                          {el?.plan?.plan_name}
                        </Link>
                      </td>
                      <td>
                        <AtomNumberFormat
                          prefix="IDR "
                          value={el?.payment?.total_amount}
                        />
                      </td>
                      <td>{el?.plan?.end_valid_date}</td>
                      <td>{el?.plan?.start_valid_date}</td>
                      <td>{el?.current_credits ?? el?.current_usage_limit}</td>
                      <td>
                        {el?.plan_status
                          .toLowerCase()
                          .replace(/\w/, (firstLetter) =>
                            firstLetter.toUpperCase()
                          )}
                      </td>
                      {el?.plan_status === "pending" &&
                      el.payment?.payment_method === "manual" &&
                      el.payment?.payment_status === "pending" ? (
                        <td>
                          <Link
                            className="text-pink text-decoration-none"
                            to={{
                              pathname: "/payment-verification",
                              state: {
                                category: el?.category,
                                id: el?._id,
                                total: el.payment?.total_amount,
                                order_number: el?.order_number,
                                bank_image: el?.bank_image,
                                bank_name: el.payment?.transfer_to.bank_name,
                                account_number:
                                  el.payment?.transfer_to.bank_account_number,
                                account_holder_name:
                                  el.payment?.transfer_to.account_holder_name,
                              },
                            }}
                          >
                            <FaRegCreditCard /> Confirm Payment
                          </Link>
                        </td>
                      ) : el?.plan_status === "pending" &&
                        el.payment?.payment_method === "online" &&
                        el.payment?.payment_status === "pending" ? (
                        <td>
                          <div
                            onClick={() =>
                              continuePayment(el?.midtrans_token_id)
                            }
                            className="custom-hover greyblue pointer"
                          >
                            <FaRegCreditCard /> Pay Now
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {el?.can_deactivated ? (
                        <td>
                          <div
                            onClick={() =>
                              setShow({
                                id: el?._id,
                                visible: true,
                                name: el.plan?.plan_name,
                                expired_date: el.plan?.end_valid_date,
                                buy_date: el?.order_date,
                              })
                            }
                            className="custom-hover text-danger pointer"
                          >
                            <FaPowerOff /> Deactivate
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {/* <td>
                    <Link
                      className="greyblue text-decoration-none"                      
                    >
                      <FaRegFileAlt /> invoice
                    </Link>
                  </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <EmptyData />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {transaction_list.data?.length > 0 && (
              <Pagination
                active={active}
                setActive={setActive}
                current_page={transaction_list.meta.pagination?.current_page}
                total_pages={transaction_list.meta.pagination?.total_pages}
              />
            )}
          </Col>
        )}
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show?.visible}
        onHide={() => setShow({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">Deactivate Plan</h2>
            <p className="pb-3">
              Please confirm you want to deactivate this plan.
            </p>{" "}
            <div className="b-gray justify-content-center rounded-lg mb-5 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Plan Name</span> <br />
                  {show?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Expire Date</span> <br />{" "}
                  {show?.expired_date}
                </Col>
                <Col>
                  <span className="lightaquamarine">Purchase Date</span> <br />{" "}
                  {show?.buy_date}
                </Col>
              </Row>
            </div>
          </div>
          <Form onSubmit={(e) => handleDeactivatePlan(e, show?.id)}>
            <TextInput
              required
              label="Your Message"
              placeholder="Input Your Message..."
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <div className="d-flex justify-content-center gap-3 mt-5">
              <Button
                title="Cancel"
                type="outline-secondary"
                onClick={() => setShow({ visible: false })}
              />
              <Button title="SEND REQUEST" type="primary" buttonType="submit" />
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PlanList;
