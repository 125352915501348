import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import "./register.styles.scss";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import registerImage1 from "./../../assets/images/register-image1.png";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import Header from "../../components/Header/header.component";
import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.png";


import {
  registerStudio,
  registerStudioWithSocmedSuccess,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { registerWithSocmedMain } from "../../services/auth";

const CreateStudio = ({
  nextStep,
  setStep,
  emailAddress,
  givenName,
  familyName,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, studio, status } = useSelector((state) => state.studio);

  const options = [
    "Neque porro quisquam est, qui dolorem",
    "ipsum quia dolor sit amet, consectetur,",
    "adipisci velit, sed quia non numquam eius",
    "modi tempora incidunt ut labore et dolore",
    "magnam aliquam quaerat.",
  ];

  const [email, setEmail] = useState(emailAddress ?? "");
  const [firstName, setFirstName] = useState(givenName ?? "");
  const [lastName, setLastName] = useState(familyName ?? "");

  const responseSocialAuth = async (provider, response) => {
    try {
      const access_token = response?.accessToken;

      if (access_token) {
        const result = await registerWithSocmedMain(
          provider,
          access_token
        );

        if (result.data.data.token) {
          dispatch(registerStudioWithSocmedSuccess(result.data.data))
          setStep(3);
        }
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      owner_first_name: firstName,
      owner_last_name: lastName,
      owner_email: email,
    };

    dispatch(registerStudio(params));
  };

  useEffect(() => {
    if (status === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [status]);

  return (
    <Row
      style={{ transform: "translateY(-77px)" }}
      className="justify-content-md-center"
    >
      <Col lg={4} className="mb-3">
        <div className="custom-card1 align-items-center">
          <img src={registerImage1} style={{ width: "100%" }} />
          <ul className="list">
            {options.map((item, idx) => {
              return (
                <li key={idx}>
                  <IoCheckmarkCircleOutline
                    color="#00796b"
                    size={18}
                    style={{ marginRight: "5px" }}
                  />
                  {item}
                </li>
              );
            })}
          </ul>
          <span className="custom-form-label">
            Sign up to classes as a member
          </span>
        </div>
      </Col>

      <Col lg={4} className="custom-card">
        <Form onSubmit={handleSubmit}>
          <TextInput
            required
            autoFocus
            label="First Name"
            placeholder="John"
            value={firstName ?? ""}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <TextInput
            label="Last Name"
            className="mt-4"
            placeholder="Doe"
            value={lastName ?? ""}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
          <TextInput
            required
            label="Email Address"
            placeholder="Email Address"
            className="mt-4"
            value={email ?? ""}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <div className="d-flex justify-content-center">
            <Button
              buttonType="submit"
              isLoading={isLoading}
              type="primary"
              title="START MY CLASS"
              style={{ width: "60%" }}
              className="mx-auto mt-5 mb-3"
            />
          </div>
        </Form>
        <div className="d-flex flex-column align-items-center">
          <span className="custom-form-label mt-3 mb-3">Or, use</span>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => {
              return (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="google-btn custom-hover"
                >
                  <img
                    alt="logo"
                    style={{
                      height: "25px",
                      width: "25px",
                      marginRight: "10px",
                    }}
                    src={google}
                  />{" "}
                  Continue with Google
                </button>
              );
            }}
            onSuccess={(res) => responseSocialAuth("google", res)}
            onFailure={(res) => responseSocialAuth("google", res)}
          />
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            size="small"
            textButton="Continue with Facebook"
            icon={
              <img
                style={{
                  height: "25px",
                  width: "25px",
                  marginRight: "10px",
                }}
                src={facebook}
                alt="logo"
              />
            }
            fields="name,email,picture"
            callback={(res) => responseSocialAuth("facebook", res)}
            cssClass="facebook-btn mt-3 custom-hover"
          />
          <span className="custom-form-label mt-3" style={{ fontSize: "10px" }}>
            By clicking "start my class" you are in agreement with the{" "}
            <Link
              target="_blank"
              to={{
                pathname: "/general-terms",
                state: { isRegiterStudio: true },
              }}
              className="fw-bold greyblue"
            >
              general terms.
            </Link>
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default CreateStudio;
