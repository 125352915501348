import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./register.styles.scss";

import { Container, Row, Col, Modal } from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";

import CreateStudio from "./create-studio.component";
import VerifyOtpRegister from "./verify-otp.component";
import StudioSetup from "./studio-setup.component";
import CreatingStudio from "./creating-studio.component";

const Register = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firstName = props.location.state?.firstName;
  const lastName = props.location.state?.lastName;
  const emailAddress = props.location.state?.email;

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <CreateStudio
            nextStep={nextStep}
            setStep={setStep}
            emailAddress={emailAddress}
            givenName={firstName}
            familyName={lastName}
          />
        );
      case 2:
        return <VerifyOtpRegister nextStep={nextStep} />;
      case 3:
        return <StudioSetup nextStep={nextStep} />;
      case 4:
        return <CreatingStudio />;
      default:
    }
  };

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      {step == 4 ? (
        <Header title="Building Your Studio . . ." />
      ) : (
        <Header isBrowseClasses={step === 1} mainPage title="Start Creating Your Own Studio" />
      )}
      <Container style={{ minHeight: "100vh" }}>{renderSteps()}</Container>
      <Footer />
    </Container>
  );
};

export default Register;
