import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./owner.styles.scss";

import { Container, Row, Col, Modal, Spinner, Image } from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";

import imageWellcome from "./../../assets/images/wellcome.png";

const WellcomeOwner = () => {
  const history = useHistory();
  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <Header title="Hello" />
        <Row
          style={{ transform: "translateY(-77px)" }}
          className="justify-content-md-center"
        >
          <Col lg={5}>
            <div
              className="custom-card align-items-center"
              style={{
                paddingLeft: 150,
                paddingRight: 150,
                paddingTop: 50,
                paddingBottom: 50,
              }}
            >
              <img className="Image-9" src={imageWellcome} />
              <span className="textWellcome">
                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, sed quia non numquam eius modi
                tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem.
              </span>
              <Button
                type="primary"
                title="LET’S GET STARTED"
                className="mt-5"
                onClick={() => history.push("/basic-settings")}
                style={{ width: 180 }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
};

export default WellcomeOwner;
