import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import "./../students.styles.scss";
import { FaAngleRight } from "react-icons/fa";
import { useCookies } from "react-cookie";
import { directBuyManual } from "../../../../redux/student/student.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const BankTransfer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const state = props.location?.state;
  const [cookies, setCookies] = useCookies(["user"]);
  const [bankName, setBankName] = useState(state.bank_name);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleSubmit = () => {
    const params = state?.buy_class
      ? {
          category: "class",
          class_schedule_id: state?.class_schedule_id,
          payment_method: "manual",
          bank_name: bankName,
          account_number: accountNumber,
          account_holder_name: accountHolderName,
          owner_bank_id: state?.owner_bank_id,
        }
      : {
          category: "plan",
          plan_id: state?.plan_id,
          payment_method: "manual",
          bank_name: bankName,
          account_number: accountNumber,
          account_holder_name: accountHolderName,
          owner_bank_id: state?.owner_bank_id,
        };

    dispatch(directBuyManual(user_token, studio_id, params));

    history.push("/waiting-payment");
  };

  return (
    <OrganismLayout maxWidth="42rem">
      <Col className="mx-auto p-3" style={{ maxWidth: "28rem" }}>
        <Form onSubmit={handleSubmit}>
          <h1 className="heading1 text-center">Bank Transfer</h1>
          <TextInput
            required
            className="mt-4"
            label="Bank Name"
            placeholder="Input Bank Name..."
            onChange={(e) => setBankName(e.target.value)}
            value={bankName}
          />
          <TextInput
            required
            type="number"
            className="mt-4"
            label="Account Number"
            placeholder="Input Account Number..."
            onChange={(e) => setAccountNumber(e.target.value)}
            value={accountNumber}
          />
          <TextInput
            required
            className="my-4"
            label="Account Holder Name"
            placeholder="Input Account Holder Name..."
            onChange={(e) => setAccountHolderName(e.target.value)}
            value={accountHolderName}
          />
          <div className="custom-form-label fs-12">
            <p>
              * Enter your payment bank account info as stated in the savings
              book
            </p>
            <p>
              * Save and upload proof of transfer to speed up your payment
              verification process
            </p>
          </div>
          <button
            type="submit"
            className="custom-button bg-lightaquamarine fw-bold text-white w-100 d-flex justify-content-between py-3 px-5"
          >
            NEXT <FaAngleRight className="fs-4" />
          </button>
        </Form>
      </Col>
    </OrganismLayout>
  );
};

export default BankTransfer;
