import React, { useEffect, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Modal, Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import Button from "../../../../components/Button/button.component";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import AtomLoading from "./../../../../components/loding-indicator";
import AtomNumberFormat from "./../../../../components/number-format";

import "./../students.styles.scss";
import {
  HiArrowCircleRight,
  HiArrowCircleDown,
  HiArrowRight,
  HiArrowLeft,
  HiClock,
  HiUser,
  HiCalendar,
} from "react-icons/hi";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useCookies } from "react-cookie";
import {
  getPublicListOfferingClasses,
  getListOfferingClasses,
} from "./../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";
import DatePicker from "../../../../components/date-picker";
import EmptyData from "../../../../components/no-data";
import { OrganismAccordion } from "./accordion";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const ClassIndex = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [cookies, setCookies] = useCookies(["user"]);
  const [active, setActive] = useState("");
  const [show, setShow] = useState(false);

  const { payment_detail } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { public_classes, class_list, isLoadingClasses } = useSelector(
    (state) => state.student
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const isBrowseClasses = props.location?.state;
  const class_offerings = isBrowseClasses ? public_classes : class_list;
  const subdomain = window.location.host.split(".")[0];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const setDate = (e) => {
    setSelectedDate(e);
    setShow(false);
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      active === eventKey ? setActive("") : setActive(eventKey)
    );

    return (
      <div style={{ cursor: "pointer" }} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  const redirectToLogin = () => {
    history.push("/studio-login");
    toast.info("Please Register/Login to Continue.");
  };

  useEffect(() => {
    if (isBrowseClasses) {
      dispatch(
        getPublicListOfferingClasses(
          subdomain,
          moment(selectedDate).format("YYYY-MM-DD")
        )
      );
    } else {
      dispatch(
        getListOfferingClasses(
          user_token,
          studio_id,
          moment(selectedDate).format("YYYY-MM-DD")
        )
      );
    }
  }, [selectedDate]);

  return (
    <OrganismLayout isBrowseClasses={isBrowseClasses} maxWidth="42rem">
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <Col className="mx-auto p-3" style={{ maxWidth: "32rem" }}>
          <h1 className="heading1 text-center mb-5">Class Offerings</h1>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <DatePicker
              title="Start Date"
              width="10rem"
              currentDate={currentDate}
              selectedDate={new Date(selectedDate)}
              show={show}
              setShow={setShow}
              setDate={setDate}
            />

            <div className="d-flex gap-2">
              <button
                disabled={
                  moment(selectedDate).format("DD") <=
                  moment(currentDate).format("DD")
                }
                onClick={() =>
                  setSelectedDate(moment(selectedDate).subtract(6, "d"))
                }
                className={`${
                  moment(selectedDate).format("DD") <=
                  moment(currentDate).format("DD")
                    && "disabled"                    
                } custom-button1 p-1 rounded-3 fs-4 d-flex align-items-center`}
              >
                <HiArrowLeft />
              </button>
              <button
                onClick={() => setSelectedDate(currentDate)}
                className="custom-button3 rounded-3 py-2 px-4"
              >
                <span>Today</span>
              </button>
              <div
                onClick={() =>
                  setSelectedDate(moment(selectedDate).add(6, "d"))
                }
                className="custom-button1 p-1 rounded-3 fs-4 d-flex align-items-center"
              >
                <HiArrowRight />
              </div>
            </div>
          </div>
          <div className="text-center mb-4">
            <p className="fs-12">
              Class Available Dates : <br />{" "}
              <span className="fs-6">
                {moment(selectedDate).format("DD/MM/YYYY")} -{" "}
                {moment(selectedDate).add(6, "d").format("DD/MM/YYYY")}
              </span>
            </p>
          </div>
          {class_offerings?.length > 0 ? (
            class_offerings.map((data, idx) => (
              <div key={idx} className="">
                <div className="text-center custom-button w-100 p-2 fs-12 mb-3">
                  {moment(data?.class_date).format("DD/MM/YYYY")}
                </div>
                <OrganismAccordion
                  isBrowseClasses={isBrowseClasses}
                  listClass={class_offerings}
                  active={active}
                  setActive={setActive}
                  data={data}
                  paymentDetail={payment_detail}
                  redirectToLogin={redirectToLogin}
                />
              </div>
            ))
          ) : (
            <EmptyData />
          )}
        </Col>
      )}
    </OrganismLayout>
  );
};

export default ClassIndex;
