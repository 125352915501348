import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "./../../../../components/Footer/footer.component";
import HeaderOwner from "./../../../../components/HeaderOwner/header-owner.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import { useCookies } from "react-cookie";
import {
  getProfile,
  updateProfile,
  requestOtp,
  setStatusOtpDefault,
} from "./../../../../redux/student/student.action";
import AtomLoading from "../../../../components/loding-indicator";
import HeaderTeacher from "../../../../components/HeaderTeacher";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { profile, isLoading, statusOtp, statusVerifyEmail } = useSelector(
    (state) => state.student
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleClick = () => {
    dispatch(requestOtp(user_token, studio_id, email));
  };

  const submitProfile = (e) => {
    e.preventDefault();
    let params =
      user?.role === "teacher"
        ? {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
          }
        : { first_name: firstName, last_name: lastName };
    dispatch(updateProfile(user_token, studio_id, params));
    setTimeout(() => {
      dispatch(getProfile(user_token, studio_id));
    }, 1000);
  };

  useEffect(() => {
    if (statusOtp === "success") {
      history.push({
        pathname: "/verify-email",
        state: { verifyCurrentEmail: true },
      });
      dispatch(setStatusOtpDefault());
    }
  }, [statusOtp]);

  useEffect(() => {
    dispatch(getProfile(user_token, studio_id));
  }, []);

  useEffect(() => {
    setEmail(profile?.email ?? "");
    setFirstName(profile?.first_name ?? "");
    setLastName(profile?.last_name ?? "");
    user?.role === "teacher" && setPhoneNumber(profile?.phone_number);
  }, [profile]);

  return (
    <OrganismLayout title="Profile" maxWidth="42rem">
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Col className="mx-auto p-3" style={{ maxWidth: "24rem" }}>
          <h1 className="heading1 text-center mb-5">EDIT PROFILE</h1>
          <Form onSubmit={submitProfile} className="mb-5">
            <Form.Group>
              <span className="custom-form-label">Email Address</span>
              <div className="d-flex justify-content-between mt-2">
                <span>{email}</span>
                <u
                  className="greyblue pointer custom-hover"
                  onClick={handleClick}
                >
                  Change
                </u>
              </div>
              <TextInput
                required
                className="mt-4"
                label="First Name"
                placeholder="Input First Name..."
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <TextInput
                className="mt-4"
                label="Last Name"
                placeholder="Input Last Name..."
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              {user?.role === "teacher" && (
                <TextInput
                  className="mt-4"
                  label="Phone Number"
                  placeholder="Input Phone Number..."
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              )}
            </Form.Group>
            <div className="d-flex justify-content-center gap-4 mt-5">
              <Button
                onClick={() => history.push("/dashboard")}
                type="outline-secondary"
                title="Cancel"
              />
              <Button buttonType="submit" type="primary" title="UPDATE" />
            </div>
          </Form>
        </Col>
      )}
    </OrganismLayout>
  );
};

export default EditProfile;
