import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./login.styles.scss";

import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";

import {
  resendOtp,
  verifyOtp,
  setLoginFromStudio,
  setStatusDefault,
  setStatusVerifyDefault,
  activateAccount,
  requestOtpActivate,
} from "../../redux/user/user.action";

const VerifyOtp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [remainTime, setRemainTime] = useState(60);

  const { status, user, isLoading, login_email, loginFromStudio, login_data } =
    useSelector((state) => state.user);

  const isReactivate = props.location.state?.isReactivate;
  const subdomain = window.location.host.split(".")[0];
  const emailFromRegister = props.location.state?.emailFromRegister;

  const resendCode = () => {
    if (isReactivate) {
      dispatch(
        requestOtpActivate(
          { email: login_email, role: login_data?.role ?? "student" },
          subdomain
        )
      );
    } else {
      if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
        dispatch(resendOtp(login_email, null));
      } else {
        dispatch(resendOtp(login_email, subdomain));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      email: login_email,
      password: Number(otp),
    };
    if (isReactivate) {
      dispatch(
        activateAccount(
          {
            email: login_email === "" ? emailFromRegister : login_email,
            role: login_data?.role ?? "student",
            otp: otp,
          },
          subdomain
        )
      );
      dispatch(setLoginFromStudio());
    } else {
      if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
        dispatch(verifyOtp(params, null));
      } else {
        dispatch(verifyOtp(params, subdomain));
        dispatch(setLoginFromStudio());
      }
    }
  };

  useEffect(() => {
    if (!remainTime) return;
    const timer = setInterval(() => {
      setRemainTime(remainTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainTime]);

  useEffect(() => {
    if (status === "success") {
      dispatch(setStatusDefault());
      setRemainTime(60);
    }
  }, [status]);

  useEffect(() => {
    if (user?.token && user?.role === "superadmin") {
      history.push("/banks");
      dispatch(setStatusVerifyDefault());
      return;
    }

    if (user?.token && subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
      history.push("/choose-studio");
      dispatch(setStatusVerifyDefault());
    }
  }, [user?.token]);

  useEffect(() => {
    if (user?.token && loginFromStudio) {
      history.push("/dashboard");
    }
  }, [user?.token]);

  return (
    <Container fluid className="bg-light">
      <Row style={{ minHeight: "100vh" }}>
        <Form onSubmit={handleSubmit}>
          <Col
            lg={4}
            className="mx-auto d-flex align-items-center justify-content-center flex-column p-5"
          >
            <h4 style={{ fontFamily: "Roboto", color: "#ce7499" }}>
              Verify Your Email Address
            </h4>
            <p className="regular-text text-center mb-5 mt-3">
              We emailed you 6 (six) digits code to <b>{login_email}.</b> Enter
              the code below to confirm your email address
            </p>
            <TextInput
              autoFocus
              required
              min={6}
              max={6}
              type="number"
              placeholder="000000"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              style={{
                fontSize: "24px",
                fontFamily: "Roboto",
                color: "#000",
                textAlign: "center",
                width: "50%",
                margin: "0 auto",
              }}
              className="mt-3"
            />
            <p className="regular-text text-center mb-3 mt-3">
              The verification code above is only valid for 30 minutes. Please
              don’t share this code with anyone, including those on behalf of GigsClassroom
            </p>
            <p className="regular-text text-center">
              Didn't receive the code? <br />
              {!remainTime ? (
                <u className="pointer greyblue" onClick={resendCode}>
                  Resend Code
                </u>
              ) : (
                <span className="greyblue">
                  Resend Code in {remainTime} Second.
                </span>
              )}
            </p>
            <Button
              buttonType="submit"
              type="primary"
              title="VERIFY"
              isLoading={isLoading}
            />
          </Col>
        </Form>
      </Row>
      <Footer />
    </Container>
  );
};

export default VerifyOtp;
