import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import HeaderOwner from "./../../../../components/HeaderOwner/header-owner.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import AtomLoading from "../../../../components/loding-indicator";
import {
  updateGeneralTerms,
  setStatusDefault,
  getGeneralTerms,
} from "../../../../redux/studio/studio.action";
import OnboardingStatus from "../../../../components/onboarding-status";

export const TermsAndConditions = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);

  const { onboarding_status } = useSelector((state) => state.owner);
  const { general_terms, status, isLoading, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const isOnboarding = props.location.state?.isOnboarding;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      general_term_title: title,
      general_term_description: description,
    };

    dispatch(updateGeneralTerms(params, user_token, studio_id));
  };

  useEffect(() => {
    dispatch(getGeneralTerms(user_token, studio_id));
  }, []);

  useEffect(() => {
    setTitle(general_terms?.title);
    setDescription(general_terms?.description);
  }, [general_terms]);

  useEffect(() => {
    if (status === "success") {
      history.goBack();
    } else if (status === "success" && isOnboarding) {
      onboarding_status?.cancellation_setting === false
        ? history.push({
            pathname: "/setting-cancellation",
            state: { isOnboarding: true },
          })
        : onboarding_status?.payment_information === false
        ? history.push({
            pathname: "/setting-payment",
            state: { isOnboarding: true },
          })
        : history.goBack();
    }
    dispatch(setStatusDefault());
  }, [status, isOnboarding]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div fluid style={{ minHeight: "100vh" }}>
        <HeaderOwner title="General Terms" />
        {isLoading ? (
          <AtomLoading />
        ) : (
          <>
            {isOnboarding && (
              <OnboardingStatus selectedStep={2} activeStep={5} />
            )}
            <Row
              style={{ transform: "translateY(-77px)" }}
              className="justify-content-md-center"
            >
              <Col lg={5}>
                <div className="custom-card add-student-form">
                  <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <TextInput
                        required
                        autoFocus
                        label="Title"
                        placeholder="Input Title..."
                        value={title || ""}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <label htmlFor="desc" className="custom-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        required
                        className="text-input"
                        name="desc"
                        id="desc"
                        cols="30"
                        rows="10"
                        placeholder="Input Description..."
                        value={description || ""}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Row className="mt-3">
                      <Col>
                        <Button
                          type={"outline-secondary"}
                          title={isOnboarding ? "Skip for Now" : "Cancel"}
                          onClick={() => {
                            if (isOnboarding) {
                              onboarding_status?.cancellation_setting === false
                                ? history.push({
                                    pathname: "/setting-cancellation",
                                    state: { isOnboarding: true },
                                  })
                                : onboarding_status?.payment_information ===
                                  false
                                ? history.push({
                                    pathname: "/setting-payment",
                                    state: { isOnboarding: true },
                                  })
                                : history.goBack();
                            } else {
                              history.goBack();
                            }
                          }}
                        />
                      </Col>

                      <Col>
                        <Button
                          buttonType="submit"
                          type="primary"
                          title="SAVE"
                          isLoading={isLoading}
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Footer />
    </Container>
  );
};
