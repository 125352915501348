import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./owner.styles.scss";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
} from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";

import { addStudent, setStatusDefault } from "../../redux/owner/owner.action";

const AddStudent = ({ nextStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { addStudentStatus, isLoadingStudent } = useSelector(
    (state) => state.owner
  );
  const [cookies] = useCookies(["subdomain", "user"]);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      send_welcome_email: sendEmail ? 1 : 0,
    };
    dispatch(addStudent(params, cookies.user.token, cookies.user.studio_id));
  };

  useEffect(() => {
    if (addStudentStatus === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [addStudentStatus]);

  return (
    <Col>
      <div
        className="custom-card align-items-center mx-auto"
        style={{
          paddingLeft: 100,
          paddingRight: 100,
          paddingTop: 20,
          paddingBottom: 50,
          maxWidth: "32rem",
        }}
      >
        <span className="textTitle">Add Student</span>
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              label="First Name"
              placeholder="John"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              label="Last Name"
              placeholder="Chena"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              label="Email Address"
              placeholder="John@gmail.com"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              type="number"
              label="Phone Number"
              placeholder="087878****"
              value={phoneNumber}
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Send welcome email"
              style={{ fontFamily: "Roboto", fontSize: 14, color: "#000" }}
              onChange={(event) => {
                setSendEmail(event.target.checked);
              }}
            />
          </Form.Group>
          <Row style={{ marginTop: 20 }}>
            <>
              <Col>
                <Button
                  buttonType="button"
                  isLoading={isLoadingStudent}
                  type="secondary"
                  title="Skip for now"
                  onClick={() => {
                    nextStep();
                  }}
                />
              </Col>
              <Col>
                <Button
                  buttonType="submit"
                  isLoading={isLoadingStudent}
                  type="primary"
                  title="CONTINUE"
                />
              </Col>
            </>
          </Row>
        </Form>
      </div>
    </Col>
  );
};

export default AddStudent;
