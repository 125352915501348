import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./owner.styles.scss";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
} from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";

import ClassNameSetup from "./classname-setup-component";
import ClassDurationSetup from "./classduration-setup-component";
import ClassAddTeacherSetup from "./classaddteacher-setup-component";

import { Stepper } from "react-form-stepper";

const AddSchedule = ({ nextStep, setSelectedStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [stepSchedule, setStepSchedule] = useState(1);

  const nextStepSchedule = () => {
    setStepSchedule(stepSchedule + 1);
  };

  const renderStepsSchedule = () => {
    switch (stepSchedule) {
      case 1:
        return (
          <ClassNameSetup
            nextStepSchedule={nextStepSchedule}
            setSelectedStep={setSelectedStep}
          />
        );
      case 2:
        return (
          <ClassDurationSetup
            nextStepSchedule={nextStepSchedule}
            setSelectedStep={setSelectedStep}
          />
        );
      case 3:
        return <ClassAddTeacherSetup nextStep={nextStep} />;
      default:
    }
  };

  const { isLoading, studio, status } = useSelector((state) => state.studio);
  const { loadinguser = isLoading, user } = useSelector((state) => state.user);

  useEffect(() => {
    if (status === "success") {
      nextStep();
      // dispatch(setStatusDefault())
    }
  }, [status]);

  const setHeader = () => {
    if (stepSchedule == 1) {
      return <span className="textHeader">Class Name Setup</span>;
    } else if (stepSchedule == 2) {
      return <span className="textHeader">Class Duration Setup</span>;
    } else if (stepSchedule == 3) {
      return <span className="textHeader">Add Teacher</span>;
    }
  };

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row className="justify-content-md-center">
        {setHeader()}
        <div className="d-flex align-items-center justify-content-md-center gap-2 mb-2">
          <div
            style={{ height: "18px", width: "18px" }}
            className="rounded-circle shadow bg-greyblue"
          ></div>
          <div
            style={{ height: "18px", width: "18px" }}
            className={`rounded-circle shadow ${
              stepSchedule === 2 || stepSchedule === 3
                ? "bg-greyblue"
                : "bg-light"
            }`}
          ></div>
          <div
            style={{ height: "18px", width: "18px" }}
            className={`rounded-circle shadow ${
              stepSchedule === 3 ? "bg-greyblue" : "bg-light"
            }`}
          ></div>
        </div>
        {renderStepsSchedule()}
      </Row>
    </Container>
  );
};

export default AddSchedule;
