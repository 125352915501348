import React from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Container, Row, Col, Image } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import AtomNumberFormat from "../../../../components/number-format";
import sampleImg from "./../../../../assets/images/placeholder.png";
import "./../students.styles.scss";

import AtomLoading from "../../../../components/loding-indicator";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const WaitingPayment = (props) => {
  const history = useHistory();

  const { isLoadingOrder, class_detail, order_resp } = useSelector(
    (state) => state.student
  );

  const state = props.location?.state;

  return (
    <OrganismLayout maxWidth="42rem">
      {isLoadingOrder ? (
        <AtomLoading />
      ) : (
        <Col className="mx-auto p-3" style={{ maxWidth: "28rem" }}>
          <h1 className="heading1 text-center mb-5">Waiting For Payment</h1>
          <div className="bg-pink rounded-lg p-3 mb-3 text-center fw-bold">
            Pay Before :{" "}
            {moment(order_resp?.payment?.expired_date).format(
              "DD MMM YYYY, HH.mm"
            )}{" "}
            WIB
          </div>
          <div className="b-gray rounded-lg p-3 mb-3">
            <Row>
              <Col>Total</Col>
              <Col className="d-flex justify-content-end fs-4">
                <AtomNumberFormat
                  prefix="IDR "
                  value={order_resp.payment?.total_amount}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>Order ID</Col>
              <Col className="d-flex justify-content-end">
                {order_resp?.order_number}
              </Col>
            </Row>
          </div>
          <div className="lh-1 b-gray rounded-lg p-3 mb-5">
            <Row>
              <Col>
                <p className="custom-form-label">Payment Method</p>
                <p>Bank Transfer</p>
                <Image src={order_resp?.bank_image ?? sampleImg} width={80} />
              </Col>
              <Col className="border-1 border-start">
                <p className="custom-form-label">Bank</p>
                <p>{order_resp.payment.transfer_to?.bank_name}</p>
                <p className="custom-form-label">Account Number</p>
                <p>{order_resp.payment.transfer_to?.bank_account_number}</p>
                <p className="custom-form-label">Account Name</p>
                <p>{order_resp.payment.transfer_to?.account_holder_name}</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center gap-4">
            <Button
              onClick={() => history.push("/dashboard")}
              type="outline-secondary"
              title="Back"
            />
            <Button
              onClick={() =>
                history.push({
                  pathname: "/payment-verification",
                  state: state,
                })
              }
              type="primary"
              title="CONFIRM"
            />
          </div>
        </Col>
      )}
    </OrganismLayout>
  );
};

export default WaitingPayment;
