import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import HeaderOwner from "../../../../components/HeaderOwner/header-owner.component";
import Footer from "../../../../components/Footer/footer.component";
import { FaAngleRight } from "react-icons/fa";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const Settings = () => {
  const history = useHistory();

  const settings = [
    { title: "Terms & Conditions", link: "/setting-terms-and-conditions" },
    { title: "Plans", link: "/setting-plans" },
    { title: "Payment", link: "/setting-payment" },
    { title: "Cancelation", link: "/setting-cancellation" },
  ];

  return (
    <OrganismLayout title="General Settings" maxWidth="24rem">
      <Col>
        {settings.map((el, idx) => (
          <div
            key={idx}
            onClick={() =>
              history.push({
                pathname: el.link,
              })
            }
            className="b-gray rounded-3 d-flex justify-content-between align-items-center p-2 mb-1 custom-hover3 pointer"
          >
            <span className="ms-3">{el.title}</span>
            <FaAngleRight style={{ color: "lightgrey" }} className="fs-5" />
          </div>
        ))}
      </Col>
    </OrganismLayout>
  );
};

export default Settings;
