import React, { useState } from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../redux/user/user.action";
import { useDispatch, useSelector } from "react-redux";

import logo from "./../../assets/images/bgheader.png";
import logoStudio from "./../../assets/images/swift_logo.png";
import "./header-student.styles.scss";

const HeaderStudent = ({ title }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, removeCookies] = useCookies(["user", "subdomain"]);
  const [active, setActive] = useState("Dashboard");

  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { subdomain, isRegisterStudent } = useSelector((state) => state.studio);
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;

  const menu = [
    { name: "Dashboard", href: "/" },
    { name: "Plans", href: "/plan-list" },
    { name: "Classes", href: "/class-list" },
    { name: "Profile", href: "/student-profile" },
  ];

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }
    dispatch(logout(user_token));
    history.push("/studio-login");
  };

  return (
    <Row>
      <Col lg={12} className="p-0">
        <Navbar className="header-owner" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/dashboard">
              <img
                alt="studio-logo"
                src={user?.studio_logo ?? cookies.user?.studio_logo}
                width="60"
                height="60"
                className="rounded-circle position-absolute"
                style={{ zIndex: 10, top: 25 }}
              />
              <div style={{ transform: "translateX(60px)" }}>
                <b className="text-uppercase ps-3" style={{ fontSize: "14px" }}>
                  {user?.studio_name ?? cookies.user?.studio_name}
                </b>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="text-center" id="basic-navbar-nav">
              <Nav className="ms-auto">
                {menu.map((el, idx) => (
                  <Nav.Link
                    as={Link}
                    key={idx}
                    onClick={() => {
                      setActive(el.name);
                    }}
                    to={el.href}
                    className={`${active === el.name ? "nav" : "nav"}`}
                  >
                    {el.name}
                  </Nav.Link>
                ))}
                <Nav.Link className="nav" onClick={(e) => handleLogout(e)}>
                  Log Out
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div
          style={{
            background: "#00897B",
            height:
              window.location.pathname === "/dashboard" ? "90px" : "165px",
          }}
          className="d-flex justify-content-center p-4 position-relative"
        >
          <h2 className="heading1 m-0" style={{ color: "#fff" }}>
            {title}
          </h2>
          <img
            src={logo}
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default HeaderStudent;
