import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Container, Row, Form, Image, Col } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/HeaderAdmin/header.component";
import TextInput from "../../components/TextInput/text-input.component";
import "./bank.styles.scss";
import sampleImg from "../../assets/images/placeholder.png";

import {
  addBank,
  editBank,
  getBankDetail,
  setStatusDefault,
} from "../../redux/bank/bank.action";
import AtomLoading from "../../components/loding-indicator";
import OrganismLayout from "../../components/Layout/organism-layout";

const ModifyBank = (props) => {
  const history = useHistory();
  const uploadLogo = useRef(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { isLoading, bank_detail } = useSelector((state) => state.banks);

  const location = useLocation();
  const isCreating = location.pathname.includes("add") ? true : false;
  const id = props.location.state?.id;

  const [bankName, setBankName] = useState("");
  const [logoBank, setLogoBank] = useState(null);
  const [preview, setPreview] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("bank_name", bankName);
    logoBank !== null && formData.append("bank_image", logoBank);

    isCreating
      ? dispatch(addBank(user?.token, user?.studio_id, formData))
      : dispatch(editBank(user?.studio_id, formData, id));

    history.goBack();
  };

  useEffect(() => {
    if (!logoBank) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(logoBank);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logoBank]);

  useEffect(() => {
    if (id) {
      dispatch(getBankDetail(user?.studio_id, id));
    }
  }, [id]);

  useEffect(() => {
    if (!isCreating) {
      setBankName(bank_detail?.bank_name ?? "");
      // setLogoBank(bank_detail.bank_image?.image_url);
      setPreview(bank_detail?.image_link ?? "");
      dispatch(setStatusDefault());
    }
  }, [bank_detail]);

  return (
    <OrganismLayout
      title={isCreating ? "Add Bank" : "Edit Bank"}
      maxWidth="28rem"
    >
      <Col>
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Form onSubmit={handleSubmit}>
            <TextInput
              required
              className="mt-3"
              label="Bank Name"
              placeholder="Input Bank Name..."
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
            <div className="b-dashed bg-babyblue p-5 m-4 mb-5 d-flex flex-column align-items-center">
              <Image src={preview ?? sampleImg} width={80} rounded />
              <span className="gray-text mt-1">Upload Logo Bank</span>
              <p className="regular-text mt-2 mb-2" style={{ fontSize: 10 }}>
                File format:.jpg, .png, .pdf - max 1 MB
              </p>
              <div className="d-flex align-items-center mt-4">
                <Button
                  type="outline-secondary"
                  title="Browse"
                  onClick={(e) => {
                    e.preventDefault();
                    uploadLogo.current.click();
                  }}
                />
                <Form.Group controlId="formFileSm" className="d-none">
                  <Form.Control
                    ref={uploadLogo}
                    type="file"
                    name="logoBank"
                    onChange={(event) => {
                      setLogoBank(event.target.files[0]);
                      console.log(event.target.files[0]);
                    }}
                    size="sm"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-4">
              <Button
                type="outline-secondary"
                title="Cancel"
                onClick={() => history.goBack()}
              />
              <Button
                isLoading={isCreating && logoBank === null}
                buttonType="submit"
                type="primary"
                title={isCreating ? "ADD" : "SAVE"}
              />
            </div>
          </Form>
        )}
      </Col>
    </OrganismLayout>
  );
};

export default ModifyBank;
