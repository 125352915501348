import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

import Button from "../../../../../components/Button/button.component";
import Pagination from "../../../../../components/pagination";

import { useCookies } from "react-cookie";

import moment from "moment";
import { updateSchedule } from "../../../../../redux/owner/owner.action";

const BookingHistory = ({ id, params }) => {
  params.do_cancel = "yes";
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [active, setActive] = useState(1);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { booking_history, updateScheduleStatus } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const title = [
    "Participant",
    "Booking Date Schedule",
    "Booking Status",
    "Payment Status",
  ];

  const handleNext = () => {
    dispatch(updateSchedule(params, user_token, studio_id, id));
    history.push("/classes");
  };

  // useEffect(() => {
  //   if (updateScheduleStatus === "success") {
  //     history.push("/classes");
  //     dispatch(setStatusDefault())
  //   }
  // }, [updateScheduleStatus]);

  return (
    <Row>
      <Col
        style={{ maxWidth: "63rem" }}
        className="mx-auto justify-content-center shadow-sm p-3 py-3 mb-5 bg-white rounded-lg"
      >
        {booking_history.data?.length > 0 && (
          <p className="text-center">
            A change in date will result in a schedule change for the
            registrants below. <br />
            Are you sure to continue?
          </p>
        )}
        <div className="heading3">
          <p>
            {booking_history.data?.length}/{booking_history?.total} Participants
          </p>
        </div>
        <Row>
          {title.map((el, idx) => (
            <Col key={idx} className="fs-12 custom-form-label">
              {el}
            </Col>
          ))}
        </Row>
        {booking_history.data?.length > 0 ? (
          booking_history.data?.map((el, idx) => (
            <Row key={idx}>
              <Col>
                <span>{el.student?.student_name}</span> <br />
                <span className="heading3">{el?.email}</span>
              </Col>
              <Col>{moment(el?.created_at).format("DD MMM YYYY, HH.mm")}</Col>
              <Col className="text-capitalize">{el?.booking_class_status}</Col>
              <Col className="text-capitalize">
                {el.payment?.payment_status}
              </Col>
              <hr className="mt-2" />
            </Row>
          ))
        ) : (
          <div className="text-center text-black-50 fs-12 p-3 my-3">
            <span>"There is no booking data that needs to be updated"</span>
          </div>
        )}
        {booking_history.data?.length > 0 && (
          <Pagination
            active={active}
            setActive={setActive}
            current_page={booking_history?.current_page}
            total_pages={booking_history?.total_pages}
          />
        )}
      </Col>
      <div className="d-flex justify-content-center gap-2 mt-3">
        <Button
          onClick={() => history.push("/classes")}
          type="outline-secondary"
          title="Cancel"
        />
        <Button type="primary" title="Save" onClick={handleNext} />
      </div>
    </Row>
  );
};

export default BookingHistory;
