import axiosInstance from "../axiosInstance";

export const getClassInProgress = async (user_token, studio_id, date, page) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?date=${date}&actiontype=class-in-progress&page=${page}`,
    config
  );
  return res;
};

export const getPaymentVerification = async (
  user_token,
  studio_id,
  date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?date=${date}&actiontype=payment-verification&page=${page}`,
    config
  );
  return res;
};

export const getUpcomingClass = async (user_token, studio_id, date, page) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?date=${date}&actiontype=upcoming-classes&page=${page}`,
    config
  );
  return res;
};

export const getCancellationRequest = async (
  user_token,
  studio_id,
  date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?date=${date}&actiontype=cancellation-request&page=${page}`,
    config
  );
  return res;
};

export const getClassHeld = async (user_token, studio_id, date, page) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?date=${date}&actiontype=class-held&page=${page}`,
    config
  );
  return res;
};

export const getListStatistic = async (
  user_token,
  studio_id,
  date,
  actionType,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-owners?date=${date}&actiontype=${actionType}&page=${page}`,
    config
  );
  return res;
};
