import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Image } from "react-bootstrap";
import "./../students.styles.scss";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import sampleImg from "./../../../../assets/images/placeholder.png";

import { FaAngleRight } from "react-icons/fa";
import { useCookies } from "react-cookie";
import {
  directBuyManual,
  setStatusDefault,
} from "../../../../redux/student/student.action";
import { getPaymentDetail } from "../../../../redux/owner/owner.action";
import { getClientKey } from "../../../../redux/studio/studio.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const ChoosePayment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [next, setNext] = useState(false);

  const { client_key, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const { payment_detail } = useSelector((state) => state.owner);
  const { order_status, order_resp } = useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const subdomain = window.location.host.split(".")[0];
  const plan_id = props.location.state?.plan_id;
  const buy_class = props.location.state?.buy_class ?? false;
  const class_schedule_id = props.location.state?.class_schedule_id;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleSubmit = () => {
    const params = buy_class
      ? {
          category: "class",
          class_schedule_id: class_schedule_id,
          payment_method: "online",
        }
      : {
          category: "plan",
          plan_id: plan_id,
          payment_method: "online",
        };

    dispatch(directBuyManual(user_token, studio_id, params));
  };

  useEffect(() => {
    if (next && selectedPayment === "option2") {
      handleSubmit();
    }
  }, [next && selectedPayment === "option2"]);

  useEffect(() => {
    // dispatch(getClientKey(user_token, studio_id));
    dispatch(getPaymentDetail(user_token, studio_id));
  }, []);

  useEffect(() => {
    //change this to the script source you want to load, for example this is snap.js sandbox env
    const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_SNAP_URL;
    //change this according to your client-key
    const myMidtransClientKey = client_key?.client_key;

    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);

    document.body.appendChild(scriptTag);
    console.log("Done appending snap.js");
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [client_key]);

  useEffect(() => {
    if (order_status === "success") {
      window.snap.pay(order_resp?.midtrans_token_id, {
        onSuccess: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/online-payment-success`;
          console.log(result, "result succes");
        },
        onPending: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/dashboard`;
          console.log(result, "result pending");
        },
        onError: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/dashboard`;
          console.log(result, "result error");
        },
        onClose: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/dashboard`;
          console.log(result, "result close");
        },
      });
      dispatch(setStatusDefault());
    }
  }, [order_status]);

  return (
    <OrganismLayout maxWidth="48rem">
      <Col className="mx-auto p-3" style={{ maxWidth: "28rem" }}>
        <h1 className="heading1 mb-5 text-center">Choose Payment</h1>
        {payment_detail?.bank_transfer && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
              onChange={(e) => setSelectedPayment(e.target.value)}
              checked={selectedPayment === "option1"}
            />
            <label className="fw-bold" htmlFor="exampleRadios1">
              Bank Transfer
            </label>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque,
              possimus?
            </p>
            {next &&
              selectedPayment === "option1" &&
              payment_detail?.list_bank.map((el, idx) => (
                <div
                  key={idx}
                  onClick={() =>
                    history.push({
                      pathname: "/bank-transfer",
                      state: {
                        buy_class: buy_class,
                        owner_bank_id: el?.bank_id,
                        bank_name: el?.bank_name,
                        plan_id: plan_id,
                        class_schedule_id: class_schedule_id,
                      },
                    })
                  }
                  className="b-gray rounded-3 d-flex justify-content-between align-items-center p-3 mb-3 pointer"
                >
                  <div className="">
                    <Image src={el?.bank_image ?? sampleImg} width={50} />
                    <span className="ms-3">{el?.bank_name}</span>
                  </div>
                  <FaAngleRight className="fs-4 text-black-50" />
                </div>
              ))}
          </div>
        )}
        {payment_detail?.online_payment && (
          <div className="form-check mb-5">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
              onChange={(e) => setSelectedPayment(e.target.value)}
              checked={selectedPayment === "option2"}
            />
            <label className="fw-bold" htmlFor="exampleRadios2">
              Online Payment
            </label>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque,
              possimus?
            </p>
          </div>
        )}
        <button
          onClick={() => {
            setNext(true);
          }}
          className="custom-button bg-lightaquamarine fw-bold text-white w-100 d-flex justify-content-between py-3 px-5"
        >
          BOOK NOW <FaAngleRight className="fs-4" />
        </button>
      </Col>
    </OrganismLayout>
  );
};

export default ChoosePayment;
