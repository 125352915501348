const UserActionTypes = {
    REQUEST_OTP: "REQUEST_OTP",
    REQUEST_OTP_RESPONSE: "REQUEST_OTP_RESPONSE",
    REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
    REQUEST_OTP_FAILED: "REQUEST_OTP_FAILED",

    REQUEST_OTP_ACTIVATE: "REQUEST_OTP_ACTIVATE",
    REQUEST_OTP_ACTIVATE_SUCCESS: "REQUEST_OTP_ACTIVATE_SUCCESS",
    REQUEST_OTP_ACTIVATE_FAILED: "REQUEST_OTP_ACTIVATE_FAILED",

    ACTIVATE_ACCOUNT: "ACTIVATE_ACCOUNT",
    ACTIVATE_ACCOUNT_SUCCESS: "ACTIVATE_ACCOUNT_SUCCESS",
    ACTIVATE_ACCOUNT_FAILED: "ACTIVATE_ACCOUNT_FAILED",

    RESEND_OTP: "RESEND_OTP",
    RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
    RESEND_OTP_FAILED: "RESEND_OTP_FAILED",

    VERIFY_OTP: "VERIFY_OTP",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILED: "VERIFY_OTP_FAILED",
    VERIFY_OTP_DEFAULT: "VERIFY_OTP_DEFAULT",
    
    LOGIN_FROM_STUDIO: "LOGIN_FROM_STUDIO",
    
    SET_STATUS_DEFAULT: "SET_STATUS_DEFAULT",
    
    SET_CHANGE_ROLE: "SET_CHANGE_ROLE",
    SET_CHANGE_ROLE_SUCCESS: "SET_CHANGE_ROLE_SUCCESS",
    SET_CHANGE_ROLE_FAILED: "SET_CHANGE_ROLE_FAILED",

    SET_NEW_USER: "SET_NEW_USER",    
    
    LOGOUT: "LOGOUT"
    
}

export default UserActionTypes;