import { toast } from "react-toastify";
import axios from "../../axiosInstance";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import BankActionTypes from "./bank.types";

const editBankUrl = "/banks/";
const listBankUrl = "/banks";

export const addBank = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: BankActionTypes.ADD_BANK,
      });

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.post(listBankUrl, params, config);

      toast.success("Success!");
      dispatch({
        type: BankActionTypes.ADD_BANK_SUCCESS,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: BankActionTypes.ADD_BANK_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const editBank = (studio_id, params, bankId) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch({
        type: BankActionTypes.EDIT_BANK,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state.user.user.token}`,
          "Studio-id": studio_id,
        },
      };

      const res = await axios.post(editBankUrl + `${bankId}`, params, config);

      toast.success("Success!");
      dispatch({
        type: BankActionTypes.EDIT_BANK_SUCCESS,
        // payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: BankActionTypes.EDIT_BANK_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getListBanks = (token, studio_id) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state.user.user.token ?? token}`,
          "Studio-id": studio_id,
        },
      };

      dispatch({
        type: BankActionTypes.GET_BANKS,
      });

      const res = await axios.get(listBankUrl, config);

      dispatch({
        type: BankActionTypes.GET_BANKS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      console.log(error.response);
      dispatch({
        type: BankActionTypes.GET_BANKS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getBankDetail = (studio_id, bankId) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state.user.user.token}`,
          studio_id: studio_id,
        },
      };

      dispatch({
        type: BankActionTypes.GET_BANKS_DETAIL,
      });

      const res = await axios.get(editBankUrl + `${bankId}`, config);

      dispatch({
        type: BankActionTypes.GET_BANKS_DETAIL_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: BankActionTypes.GET_BANKS_DETAIL_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const deleteBank = (studio_id, bankId) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state.user.user.token}`,
          studio_id: studio_id,
        },
      };

      dispatch({
        type: BankActionTypes.DELETE_BANK,
      });

      await axios.delete(editBankUrl + `${bankId}`, config);

      toast.success("Success!");
      dispatch({
        type: BankActionTypes.DELETE_BANK_SUCCESS,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      console.log(error.response);
      dispatch({
        type: BankActionTypes.DELETE_BANK_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const updateStatusBank = (studio_id, bankId, params, status) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state.user.user.token}`,
          studio_id: studio_id,
        },
      };

      dispatch({
        type: BankActionTypes.DELETE_BANK,
      });

      await axios.post(
        `${editBankUrl}${bankId}?status=${status}`,
        params,
        config
      );

      toast.success("Success!");
      dispatch({
        type: BankActionTypes.DELETE_BANK_SUCCESS,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      console.log(error.response);
      dispatch({
        type: BankActionTypes.DELETE_BANK_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const setStatusDefault = () => {
  return (dispatch) => {
    dispatch({
      type: BankActionTypes.SET_STATUS_DEFAULT,
    });
  };
};
