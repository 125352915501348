import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Container, Row } from "react-bootstrap";
import Footer from "../../../../../components/Footer/footer.component";
import HeaderOwner from "../../../../../components/HeaderOwner/header-owner.component";

import ClassNameSetup from "./classname-setup-component";
import ClassDurationSetup from "./classduration-setup-component";
import ClassAddTeacherSetup from "./classaddteacher-setup-component";

import { Stepper } from "react-form-stepper";
import OnboardingStatus from "../../../../../components/onboarding-status";
import HeaderTeacher from "../../../../../components/HeaderTeacher";
import { useCookies } from "react-cookie";

export const AddClass = (props) => {
  const [cookies, setCookies] = useCookies(["user"]);
  const [stepSchedule, setStepSchedule] = useState(1);

  const { isLoading, studio, status } = useSelector((state) => state.studio);
  const {
    loadinguser = isLoading,
    user,
    loginFromStudio,
  } = useSelector((state) => state.user);

  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const isOnboarding = props.location.state?.isOnboarding;

  const nextStepSchedule = () => {
    setStepSchedule(stepSchedule + 1);
  };

  const prevStepSchedule = () => {
    setStepSchedule(stepSchedule - 1);
  };

  const renderStepsSchedule = () => {
    switch (stepSchedule) {
      case 1:
        return (
          <ClassNameSetup
            isOnboarding={isOnboarding}
            prevStepSchedule={prevStepSchedule}
            nextStepSchedule={nextStepSchedule}
          />
        );
      case 2:
        return (
          <ClassDurationSetup
            prevStepSchedule={prevStepSchedule}
            nextStepSchedule={nextStepSchedule}
          />
        );
      case 3:
        return (
          <ClassAddTeacherSetup
            prevStepSchedule={prevStepSchedule}
            nextStepSchedule={nextStepSchedule}
          />
        );
      default:
    }
  };

  useEffect(() => {
    if (status === "success") {
      // nextStep()
      // dispatch(setStatusDefault())
    }
  }, [status]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        {user_role === "owner" ? (
          <HeaderOwner title="Add Class" />
        ) : (
          <HeaderTeacher title="Add Class" />
        )}

        {isOnboarding && <OnboardingStatus selectedStep={1} activeStep={3} />}
        <Row
          style={{ transform: "translateY(-77px)" }}
          className="justify-content-md-center"
        >
          <span
            className={`${
              isOnboarding ? "text-black" : "text-white"
            } text-center mb-1 fw-bold`}
          >
            Class Setup
          </span>
          <div className="d-flex align-items-center justify-content-md-center gap-2 mb-2">
            <div
              style={{ height: "18px", width: "18px" }}
              className="rounded-circle shadow bg-greyblue"
            ></div>
            <div
              style={{ height: "18px", width: "18px" }}
              className={`rounded-circle shadow ${
                stepSchedule === 2 || stepSchedule === 3
                  ? "bg-greyblue"
                  : "bg-light"
              }`}
            ></div>
            <div
              style={{ height: "18px", width: "18px" }}
              className={`rounded-circle shadow ${
                stepSchedule === 3 ? "bg-greyblue" : "bg-light"
              }`}
            ></div>
          </div>
          {renderStepsSchedule()}
        </Row>
      </div>
      <Footer />
    </Container>
  );
};
