import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import {
  Container,
  Row,
  Col,
  Accordion,
  useAccordionButton,
} from "react-bootstrap";
import Footer from "../../../../components/Footer/footer.component";
import HeaderTeacher from "../../../../components/HeaderTeacher/index";
import Pagination from "../../../../components/pagination";
import DropdownCard from "../../../../components/Button/dropdown.component";
import AtomLoading from "../../../../components/loding-indicator";

import {
  HiArrowCircleDown,
  HiArrowCircleRight,
  HiCalendar,
  HiClock,
  HiUser,
  HiUserGroup,
} from "react-icons/hi";
import { FaEllipsisH } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  getProfile,
  getUserRole,
} from "../../../../redux/student/student.action";
import {
  setLoginFromStudio, setNewUser,
} from "../../../../redux/user/user.action";
import EmptyData from "../../../../components/no-data";
import * as dashboardService from "../../../../services/dashboard";
import { useOutsideOnclick } from "../../../../services/clickOutside";
import { changeRole } from "../../../../services/auth";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";

const TeacherDashBoard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [active2, setActive2] = useState(1);
  const [active3, setActive3] = useState(1);
  const [active4, setActive4] = useState(1);
  const [index, setIndex] = useState("");
  const [open, setOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(["user"]);
  const [status, setStatus] = useState("registered");
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [cancellationRequest, setCancellationRequest] = useState([]);
  const [classInprogress, setClassInprogress] = useState([]);
  const [upcomingClass, setUpcomingClass] = useState([]);
  const [paymentVerification, setPaymentVerification] = useState([]);
  const [classHeld, setClassHeld] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { user_role, profile, activePlan, classes, isLoadingClasses } =
    useSelector((state) => state.student);

  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;

  const userRoles = user_role.roles
    ? user_role.roles?.filter((item) => item !== role)
    : [];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const data = [
    ...userRoles.map((el) => ({
      name: `View as ${el.toLowerCase().replace(/\w/, (x) => x.toUpperCase())}`,
      onClick: () => setRoles(el),
    })),
    {
      name: "Edit Profile",
      href: "/profile",
    },
  ];

  useOutsideOnclick(wrapperRef, setOpen);

  const setRoles = async (role) => {
    try {
      const params = {
        role: role,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data))

      // if (cookies.user) {
        removeCookies("user", {
          path: "/",
          domain: process.env.REACT_APP_HOST,
          // domain: ".swift.local",
        });
        removeCookies("subdomain", {
          path: "/",
          domain: process.env.REACT_APP_HOST,
          // domain: ".swift.local",
        });
      // }
      dispatch(setLoginFromStudio());
      history.push("/dashboard");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      index === eventKey ? setIndex("") : setIndex(eventKey)
    );

    return (
      <div style={{ cursor: "pointer" }} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  const getClassInProgress = async () => {
    try {
      const res = await dashboardService.getClassInProgress(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active4
      );
      setClassInprogress(res.data);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getPaymentVerification = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getPaymentVerification(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active
      );
      setPaymentVerification(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getUpcomingClass = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getUpcomingClass(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active1
      );
      setUpcomingClass(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getCancellationRequest = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getCancellationRequest(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active2
      );
      setCancellationRequest(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getClassHeld = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getClassHeld(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active3
      );
      setClassHeld(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useEffect(() => {
    dispatch(getUserRole(user_token, studio_id));
    dispatch(getProfile(user_token, studio_id));
  }, []);

  useEffect(() => {
    getClassInProgress();
  }, [selectedDate]);

  useEffect(() => {
    getPaymentVerification();
  }, [selectedDate, active]);

  useEffect(() => {
    getUpcomingClass();
  }, [selectedDate, active1]);

  useEffect(() => {
    getCancellationRequest();
  }, [selectedDate, active2]);

  useEffect(() => {
    getClassHeld();
  }, [selectedDate, active3]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <HeaderTeacher title="Dashboard" />
        {isLoading ? (
          <AtomLoading />
        ) : (
          <div
            style={{
              gap: "0.8rem",
              maxWidth: "60rem",
            }}
            className="mx-auto mb-5 mt-2 d-flex fs-12"
          >
            <Col sm={4} className="d-flex flex-column">
              <div
                style={{ height: "142px" }}
                className="text-white shadow-sm bg-lightaquamarine py-2 px-4 rounded-lg"
              >
                <div className="d-flex justify-content-between align-items-end">
                  <span className="heading3">Welcome</span>
                  <div
                    ref={wrapperRef}
                    className="position-relative"
                    onClick={() => setOpen((open) => !open)}
                  >
                    <FaEllipsisH className="custom-hover pointer" />
                    {open && (
                      <DropdownCard
                        positionRight
                        data={data}
                        className="text-lightaquamarine custom-hover"
                      />
                    )}
                  </div>
                </div>
                <span className="heading1">
                  {profile?.first_name} {profile?.last_name}
                </span>
                <br />
                <span className="heading3 text-capitalize">as {role}</span>
              </div>

              <div className="shadow-sm p-2 rounded-lg bg-white my-2">
                <p className="lightaquamarine">Payment Verification</p>
                {paymentVerification.data?.length > 0 ? (
                  paymentVerification.data?.map((el, idx) => (
                    <div key={idx}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Link
                          to={{
                            pathname: `/student/${el?.id}/detail`,
                            state: { id: el?.id },
                          }}
                          className="text-black"
                        >
                          <b>{el?.order_number}</b>
                        </Link>
                        <span
                          className={`${
                            el?.category === "class"
                              ? "text-danger"
                              : "greyblue"
                          } heading3`}
                        >
                          {el.category?.replace(/\b\w/g, (l) =>
                            l.toUpperCase()
                          )}
                        </span>
                      </div>
                      <Row className="heading3">
                        <Col>
                          {el?.bank_name} -{" "}
                          {moment(el?.payment_verification_date).format(
                            "DD MMM YYYY"
                          )}
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))
                ) : (
                  <EmptyData />
                )}
                {paymentVerification.data?.length > 0 && (
                  <Pagination
                    active={active}
                    setActive={setActive}
                    current_page={
                      paymentVerification.meta.pagination?.current_page
                    }
                    total_pages={
                      paymentVerification.meta.pagination?.total_pages
                    }
                  />
                )}
              </div>

              <div className="shadow-sm p-2 rounded-lg bg-white">
                <p className="lightaquamarine">Cancellation Request</p>
                {cancellationRequest.data?.length > 0 ? (
                  cancellationRequest.data?.map((el, idx) => (
                    <div key={idx}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Link
                          to={{
                            pathname: `/student/${el?.student_id}/detail`,
                            state: { id: el?.student_id, setOrder: true },
                          }}
                          className="text-black"
                        >
                          <b>{el?.student_name}</b>
                        </Link>
                        <span
                          className={`${
                            el?.category === "class"
                              ? "text-danger"
                              : "greyblue"
                          } heading3`}
                        >
                          {el?.category?.replace(/\b\w/g, (l) =>
                            l.toUpperCase()
                          )}
                        </span>
                      </div>
                      <Row className="heading3">
                        <Col>
                          {moment(el?.cancel_date).format("dddd, DD MMM YYYY")}
                        </Col>{" "}
                        <br />
                        <span>{el?.cancellation_reason ?? "-"}</span>
                      </Row>
                      <hr />
                    </div>
                  ))
                ) : (
                  <EmptyData />
                )}
                {cancellationRequest.data?.length > 0 && (
                  <Pagination
                    active={active2}
                    setActive={setActive2}
                    current_page={
                      cancellationRequest.meta.pagination?.current_page
                    }
                    total_pages={
                      cancellationRequest.meta.pagination?.total_pages
                    }
                  />
                )}
              </div>
            </Col>

            <Col className="">
              <div className="shadow-sm p-1 rounded-10 bg-white">
                <div
                  style={{ width: "250px" }}
                  className="mx-auto d-flex justify-content-between align-items-center"
                >
                  <IoIosArrowBack
                    onClick={() =>
                      setSelectedDate(moment(selectedDate).subtract(1, "M"))
                    }
                    className="greyblue fs-4 pointer custom-hover"
                  />
                  <span className="lightaquamarine">
                    {moment(selectedDate).format("MMMM, YYYY")}
                  </span>
                  <IoIosArrowForward
                    onClick={() =>
                      setSelectedDate(moment(selectedDate).add(1, "M"))
                    }
                    className="greyblue fs-4 pointer custom-hover"
                  />
                </div>
              </div>

              <Accordion>
                <div className="bg-white shadow-sm rounded-10 p-2 mt-2">
                  {classInprogress.data?.length > 0 ? (
                    classInprogress.data.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between fs-12 mb-2">
                          <span className="lightaquamarine">
                            Class In Progress
                          </span>
                          <Link
                            to={{
                              pathname: `/class/${el?.class_id}/detail`,
                              state: { id: el?.class_id },
                            }}
                            className="text-decoration-none greyblue"
                          >
                            Detail
                          </Link>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                          <div className="">
                            <span className="fw-bold me-2">
                              {el.class_name}
                            </span>
                            <span className="fs-12">
                              {el.is_online === 1 ? "Online" : "Offline"}
                            </span>
                          </div>
                          <span className="">
                            <CustomToggle eventKey={idx}>
                              {index === idx ? (
                                <HiArrowCircleDown className="greyblue custom-hover fs-6" />
                              ) : (
                                <HiArrowCircleRight className="text-black-50 custom-hover fs-6" />
                              )}
                            </CustomToggle>
                          </span>
                        </div>

                        <div className="fs-12 d-flex gap-3">
                          <span>
                            <HiCalendar className="text-pink" />{" "}
                            {moment(el?.class_date).format("dddd, DD MMM YYYY")}
                          </span>
                          <span>
                            <HiClock className="text-pink" />{" "}
                            {el?.class_start_time} - {el?.class_end_time}
                          </span>
                          <span>
                            <HiUserGroup className="text-pink" />{" "}
                            {el?.participant} Registrants
                          </span>
                          <span>
                            <HiUserGroup className="text-pink" /> {el?.attended}{" "}
                            Attendants
                          </span>
                          <span>
                            <HiUser className="text-pink" /> {el?.teachers}
                          </span>
                        </div>

                        <hr />

                        <Accordion.Collapse eventKey={idx}>
                          <>
                            <Row className="fw-bold">
                              <Col>Location</Col>
                              <Col>Room</Col>
                            </Row>
                            <Row className="">
                              <Col>{el?.location}</Col>
                              <Col>{el?.room}</Col>
                            </Row>
                            <Row className="fw-bold">
                              <Col>Web Conference Link</Col>
                              <Col>Additional Information</Col>
                            </Row>
                            <Row className="">
                              <Col>
                                {el?.class_link === "" ? "-" : el?.class_link}
                              </Col>
                              <Col>
                                {el?.additional_information === ""
                                  ? "-"
                                  : el?.additional_information}
                              </Col>
                            </Row>
                            <Row className="fw-bold">
                              <Col>Notes</Col>
                            </Row>
                            <Row className="">
                              <Col>{el?.notes === "" ? "-" : el?.notes}</Col>
                            </Row>
                          </>
                        </Accordion.Collapse>
                      </div>
                    ))
                  ) : (
                    <div className="">
                      <span className="lightaquamarine">Class In Progress</span>
                      <EmptyData />
                    </div>
                  )}
                  {classInprogress.data?.length > 0 && (
                    <Pagination
                      active={active4}
                      setActive={setActive4}
                      current_page={
                        classInprogress.meta.pagination?.current_page
                      }
                      total_pages={classInprogress.meta.pagination?.total_pages}
                    />
                  )}
                </div>
              </Accordion>

              <div className="shadow-sm p-3 rounded-lg bg-white my-2">
                <div>
                  <p className="lightaquamarine">Upcoming Class</p>
                  <div
                    style={{ border: "1px solid #e9ecef" }}
                    className="bg-light rounded-10 mb-2"
                  >
                    <Row className="p-3">
                      <Col>Schedule</Col>
                      <Col>Class Name</Col>
                      <Col>Registrants</Col>
                      <Col>Est. Turnover</Col>
                    </Row>
                  </div>
                  {upcomingClass.data?.length > 0 ? (
                    upcomingClass.data?.map((el, idx) => (
                      <div key={idx} className="">
                        <Row className="px-3 pt-2">
                          <Col>{el?.date_time_class}</Col>
                          <Col>{el?.class_name}</Col>
                          <Col>{el?.participant}</Col>
                          <Col>
                            IDR{" "}
                            {el?.turnover
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {upcomingClass.data?.length > 0 && (
                    <Pagination
                      active={active1}
                      setActive={setActive1}
                      current_page={upcomingClass.meta.pagination?.current_page}
                      total_pages={upcomingClass.meta.pagination?.total_pages}
                    />
                  )}
                </div>
              </div>

              <div className="shadow-sm p-3 rounded-lg bg-white">
                <div>
                  <p className="lightaquamarine">Class Held</p>
                  <div
                    style={{ border: "1px solid #e9ecef" }}
                    className="bg-light rounded-10 mb-2"
                  >
                    <Row className="p-3">
                      <Col>Schedule</Col>
                      <Col>Class Name</Col>
                      <Col>Registrants</Col>
                      <Col>Attendants</Col>
                      <Col>Est. Turnover</Col>
                    </Row>
                  </div>
                  {classHeld.data?.length > 0 ? (
                    classHeld.data?.map((el, idx) => (
                      <div key={idx} className="">
                        <Row className="px-3 pt-2">
                          <Col>{el?.class_date}</Col>
                          <Col>{el?.class_name}</Col>
                          <Col>{el?.participants}</Col>
                          <Col>{el?.attended}</Col>
                          <Col>{el?.turnover}</Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {classHeld.data?.length > 0 && (
                    <Pagination
                      active={active3}
                      setActive={setActive3}
                      current_page={classHeld.meta.pagination?.current_page}
                      total_pages={classHeld.meta.pagination?.total_pages}
                    />
                  )}
                </div>
              </div>
            </Col>
          </div>
        )}
      </div>
      <Footer />
    </Container>
  );
};

export default TeacherDashBoard;
