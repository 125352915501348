import React, { useEffect } from "react";
import { Accordion, Col, Row, useAccordionButton } from "react-bootstrap";
import {
  HiArrowCircleDown,
  HiArrowCircleRight,
  HiClock,
  HiUser,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import Button from "../../../../components/Button/button.component";
import AtomNumberFormat from "../../../../components/number-format";

export const OrganismAccordion = ({
  listClass,
  active,
  setActive,
  data,
  isBrowseClasses,
  paymentDetail,
  redirectToLogin,
}) => {
  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      active === eventKey ? setActive("") : setActive(eventKey)
    );

    return (
      <div style={{ cursor: "pointer" }} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  return (
    <Accordion>
      {listClass &&
        listClass
          .filter(
            (item) => item.class_schedules[0].class_date === data?.class_date
          )
          .map((element) =>
            element.class_schedules.map((el, i) => (
              <div key={i} className="mb-3 b-gray rounded-10 p-3">
                <div className="fs-12 d-flex justify-content-between mb-2">
                  <span>{el?.is_online === 1 ? "Online" : "Offline"}</span>
                  <span>
                    <HiClock className="fs-5 text-pink" />{" "}
                    {el?.class_start_time} - {el?.class_end_time}
                  </span>
                  <span>
                    <HiUser className="fs-5 text-pink" />
                    {el?.teachers.join(", ")}
                  </span>
                  <span className="fs-2">
                    <CustomToggle eventKey={el.class_schedule_id}>
                      {active === el.class_schedule_id ? (
                        <HiArrowCircleDown className="greyblue custom-hover" />
                      ) : (
                        <HiArrowCircleRight className="text-black-50 custom-hover" />
                      )}
                    </CustomToggle>
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="fw-bold">{el?.class_name}</span>
                  {el?.user_has_booked_class &&
                    el?.user_has_pending_payment === false && <span className="greyblue">Registered</span>}
                </div>
                <Accordion.Collapse eventKey={el.class_schedule_id}>
                  <>
                    <hr />
                    <Row>
                      <Col className="fw-bold">
                        <p>Schedule</p>
                      </Col>
                      <Col className="fs-12" xs={9}>
                        {el?.class_date}, {el?.class_start_time} -{" "}
                        {el?.class_end_time}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="fw-bold">
                        <p>Slots</p>
                      </Col>
                      <Col className="fs-12" xs={9}>
                        {el?.slots} Remaining
                      </Col>
                    </Row>
                    <Row>
                      <Col className="fw-bold">
                        <p>Credits</p>
                      </Col>
                      <Col className="fs-12" xs={9}>
                        {el?.credits}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="fw-bold">
                        <p>Price</p>
                      </Col>
                      <Col className="fs-12" xs={9}>
                        <AtomNumberFormat
                          prefix="IDR "
                          value={el?.total_amount}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="fw-bold">
                        <p>Notes</p>
                      </Col>
                      <Col className="fs-12" xs={9}>
                        {el?.notes}
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center m-4">
                      {isBrowseClasses ? (
                        <Button
                          onClick={redirectToLogin}
                          type="primary"
                          title="ENROLL NOW"
                        />
                      ) : (
                        <Link
                          to={{
                            pathname: `/payment-class/${el?.class_schedule_id}/detail`,
                            state: {
                              id: el?.class_schedule_id,
                            },
                          }}
                        >
                          <button
                            disabled={
                              (el?.user_has_booked_class &&
                                el?.user_has_pending_payment === false) ||
                              (el?.user_has_booked_class === false &&
                                el?.user_has_pending_payment) ||
                              (paymentDetail?.bank_transfer === false &&
                                paymentDetail?.online_payment === false)
                            }
                            className="bg-greyblue text-white border-0 fw-bold rounded-3 w-100 custom-hover p-2"
                          >
                            {el?.user_has_booked_class &&
                            el?.user_has_pending_payment === false
                              ? "REGISTERED"
                              : el?.user_has_booked_class === false &&
                                el?.user_has_pending_payment
                              ? "Pending"
                              : paymentDetail?.bank_transfer === false &&
                                paymentDetail?.online_payment === false
                              ? "Not Available"
                              : "ENROLL NOW"}
                          </button>
                        </Link>
                      )}
                    </div>
                  </>
                </Accordion.Collapse>
              </div>
            ))
          )}
    </Accordion>
  );
};
