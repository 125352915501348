import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Container, Row } from "react-bootstrap";
import Footer from "../../../../../components/Footer/footer.component";
import HeaderOwner from "../../../../../components/HeaderOwner/header-owner.component";
import HeaderTeacher from "../../../../../components/HeaderTeacher";
import AtomLoading from "../../../../../components/loding-indicator";

import ClassNameSetup from "../addClass/classname-setup-component";
import ClassAddTeacherSetup from "../addClass/classaddteacher-setup-component";

import { EditSchedules } from "./edit-schedule";
import { getClassDetail } from "../../../../../redux/owner/owner.action";

export const EditClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [stepSchedule, setStepSchedule] = useState(1);

  const { isLoadingClass } = useSelector((state) => state.owner);
  const { isLoading, studio, status, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const {
    loadinguser = isLoading,
    user,
    loginFromStudio,
  } = useSelector((state) => state.user);

  const id = props.location.state?.id;
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const nextStepSchedule = () => {
    setStepSchedule(stepSchedule + 1);
  };

  const renderStepsSchedule = () => {
    switch (stepSchedule) {
      case 1:
        return (
          <>
            <ClassNameSetup nextStepSchedule={nextStepSchedule} />
            <ClassAddTeacherSetup nextStepSchedule={nextStepSchedule} id={id} />
          </>
        );
      case 2:
        return <EditSchedules id={id} />;
      default:
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getClassDetail(user_token, studio_id, id));
    }
  }, [id]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div fluid style={{ minHeight: "100vh" }}>
        {user_role === "owner" ? (
          <HeaderOwner title="Edit Class" />
        ) : (
          <HeaderTeacher title="Edit Class" />
        )}

        {isLoadingClass ? (
          <AtomLoading />
        ) : (
          <Row
            style={{ transform: "translateY(-77px)" }}
            className="d-flex flex-column align-items-center gap-3"
          >
            {renderStepsSchedule()}
          </Row>
        )}
      </div>
      <Footer />
    </Container>
  );
};
