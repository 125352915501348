import React from "react";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { HiOutlineInformationCircle } from "react-icons/hi";
import "./text-input.styles.scss";

const TextInput = ({
  value,
  type,
  label,
  onChange,
  placeholder,
  className,
  labelClassName,
  inputClassName,
  style,
  disabled,
  onKeyUp,
  max,
  min,
  autoFocus,
  readOnly,
  required,
  addTooltip,
}) => {
  const valueToString = value ? value.toString() : "";

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Class credits are what you use to book classes on our platform using a
      plan with class pass type.
    </Tooltip>
  );

  return (
    <div className={`form-group ${className}`}>
      <label className={`custom-form-label ${labelClassName}`}>
        {label}
        {label && required && <span className="text-danger">&nbsp;*</span>}
        {addTooltip && (
          <OverlayTrigger
            delay={{ hide: 400 }}
            placement="top"
            overlay={renderTooltip}
          >
            <div
              style={{ fontSize: "14px" }}
              className="d-flex align-items-center"
            >
              <HiOutlineInformationCircle className="text-warning" />
            </div>
          </OverlayTrigger>
        )}
      </label>

      <input
        required={required}
        type={"text"}
        value={type === "number" ? valueToString?.replace(/\D/g, "") : value}
        onChange={onChange}
        placeholder={placeholder}
        className={`${inputClassName} text-input`}
        style={style}
        readOnly={readOnly}
        disabled={disabled}
        onKeyUp={onKeyUp}
        maxLength={max}
        minLength={min}
        autoFocus={autoFocus}
      />
    </div>
  );
};

export default TextInput;
