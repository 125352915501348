import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";

import "./owner.styles.scss";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
  Table,
} from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";
import SelectInput from "../../components/SelectInput/select-input.component";
import iconRemove from "./../../assets/images/Icon feather-delete.png";

import {
  addIdTeacher,
  addClass,
  removeIdTeacher,
  setStatusDefault,
  getTeacher,
} from "../../redux/owner/owner.action";

const ClassAddTeacherSetup = ({ nextStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    addClass: addClassReducer,
    teacher,
    teacher: listTeacher,
    isLoadingClass,
    addClassStatus,
  } = useSelector((state) => state.owner);

  const [cookies] = useCookies(["subdomain", "user"]);

  const [idTeacher, setIdteacher] = useState("");

  const handleAddIdTeacher = () => {
    let dataTeacher = teacher?.data.find((e) => e.id === idTeacher);
    let params = {
      teacher_id: dataTeacher.id,
      first_name: dataTeacher.first_name,
      last_name: dataTeacher.last_name,
    };
    dispatch(addIdTeacher(params));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let teacherIds = "";
    addClassReducer.teacher.map((e, idx) => {
      if (idx === 0) {
        teacherIds = e.teacher_id;
      } else {
        teacherIds = teacherIds.concat(",", e.teacher_id);
      }
    });

    let params =
      addClassReducer.is_online === 1
        ? {
            // type: addClassReducer.type,
            class_name: addClassReducer.class_name,
            is_online: addClassReducer.is_online,
            location: addClassReducer.location,
            room: addClassReducer.room,
            slots: addClassReducer.slots,
            notes: addClassReducer.notes,
            start_date: addClassReducer.start_date,
            end_date: addClassReducer.end_date,
            // stop_repeating: addClassReducer.stop_repeating,
            repeat_every: addClassReducer.repeat_every,
            repeat_duration: addClassReducer.repeat_duration,
            teacher_ids: teacherIds,
            class_link: addClassReducer.class_link,
            additional_information: addClassReducer.additional_information,
            schedule: addClassReducer.schedule,
            class_price: addClassReducer.class_price,
            class_tax: addClassReducer.class_tax,
            credits: addClassReducer.credits,
          }
        : {
            // type: addClassReducer.type,
            class_name: addClassReducer.class_name,
            is_online: addClassReducer.is_online,
            location: addClassReducer.location,
            room: addClassReducer.room,
            slots: addClassReducer.slots,
            notes: addClassReducer.notes,
            start_date: addClassReducer.start_date,
            end_date: addClassReducer.end_date,
            // stop_repeating: addClassReducer.stop_repeating,
            repeat_every: addClassReducer.repeat_every,
            repeat_duration: addClassReducer.repeat_duration,
            teacher_ids: teacherIds,
            schedule: addClassReducer.schedule,
            class_price: addClassReducer.class_price,
            class_tax: addClassReducer.class_tax,
            credits: addClassReducer.credits,
          };
    dispatch(addClass(params, cookies.user?.token, cookies.user?.studio_id));
  };

  useEffect(() => {
    dispatch(getTeacher(cookies.user?.token, cookies.user?.studio_id, 1));
  }, []);

  useEffect(() => {
    if (addClassStatus === "success") {
      dispatch(setStatusDefault());
      nextStep();
    }
  }, [addClassStatus]);

  const removeTeacher = (teacher_id) => {
    let array = addClassReducer.teacher;
    const afterDelete = array.filter((e) => e.teacher_id !== teacher_id);
    dispatch(removeIdTeacher(afterDelete));
  };
  return (
    <Col lg={4}>
      <div className="custom-card" style={{ paddingTop: 20 }}>
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Form.Group style={{ marginBottom: 10 }}>
            <SelectInput
              required
              label="Choose Teacher"
              data={teacher?.data}
              type="listTeacher"
              onChange={(event) => {
                setIdteacher(event.target.value);
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-between w-100">
            <div></div>
            <div>
              <Button
                style={{ marginTop: 10, marginBottom: 10 }}
                type="primary"
                title="Add Teacher"
                onClick={() => {
                  handleAddIdTeacher();
                }}
              />
            </div>
          </div>
          <div>
            <Table responsive="sm">
              <tbody>
                {addClassReducer.teacher?.length > 0
                  ? addClassReducer.teacher.map((element, idx) => (
                      <tr key={idx + 1}>
                        <td>{`#${idx + 1}`}</td>
                        <td>
                          {element.first_name} {element.last_name}
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              removeTeacher(element.teacher_id);
                            }}
                            className="pointer custom-hover"
                          >
                            <img src={iconRemove} />
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
          <div className=" py-3 d-flex justify-content-end">
            {/* <Button
              isLoading={isLoadingClass}
              type="secondary"
              title="Skip for now"
              onClick={() => {
                nextStep();
              }}
            /> */}
            <Button
              buttonType="submit"
              isLoading={
                isLoadingClass || addClassReducer.teacher?.length === 0
              }
              type="primary"
              title="ADD CLASS"
            />
          </div>
        </Form>
      </div>
    </Col>
  );
};

export default ClassAddTeacherSetup;
