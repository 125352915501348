import { Row, Col, Navbar, Nav, Container } from "react-bootstrap";
import { useCookies } from "react-cookie";

import logo from "./../../assets/images/bgheader.png";
import logoStudio from "./../../assets/images/swift_logo.png";

import { logout } from "../../redux/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const HeaderAdmin = ({ title }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies, setCookies, removeCookies] = useCookies([
    "user",
    "subdomain",
  ]);

  const { user } = useSelector((state) => state.user);

  const menu = [
    { name: "Dashboard", href: "/" },
    { name: "Subscription", href: "/subscription" },
    { name: "Transactions", href: "/transactions" },
    { name: "Studios", href: "/studios" },
    { name: "Banks", href: "/banks" },
  ];

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }

    dispatch(logout(user?.token));

    history.push("/login");
  };

  return (
    <Row>
      <Col lg={12} className="p-0">
        <Navbar className="header-owner" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/dashboard">
              <img
                alt="studio-logo"
                src={logoStudio}
                width="60"
                height="60"
                className="rounded-circle position-absolute"
                style={{ zIndex: 10, top: 30 }}
              />
              <div style={{ transform: "translateX(60px)" }}>
                <b className="text-uppercase ps-3" style={{ fontSize: "14px" }}>
                  {user?.name}
                </b>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="text-center" id="basic-navbar-nav">
              <Nav className="mx-auto">
                {menu.map((el, idx) => (
                  <Nav.Link key={idx} as={Link} to={el.href}>
                    {el.name}
                  </Nav.Link>
                ))}
              </Nav>
              <Nav.Link
                className="nav"
                onClick={() => {
                  handleLogout();
                }}
              >
                Log Out
              </Nav.Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="subheader d-flex justify-content-center p-4 position-relative">
          <h2 className="heading1 m-0" style={{ color: "#fff" }}>
            {title}
          </h2>
          <img
            src={logo}
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default HeaderAdmin;
