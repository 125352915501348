import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Form } from "react-bootstrap";

import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import AtomLoading from "../../../../components/loding-indicator";
import OnboardingStatus from "../../../../components/onboarding-status";

import {
  addStudent,
  getStudentDetail,
  updateStudent,
  setStatusDefault,
} from "../../../../redux/owner/owner.action";
import { ModalLogin } from "../../../StudioLogin/modal";
import { activateAccountUser } from "../../../../redux/user/user.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";

export const AddStudent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies, setCookies] = useCookies(["user"]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendEmail, setSendEmail] = useState(0);
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);

  const {
    isLoadingStudent,
    addStudentStatus,
    updateStudentStatus,
    student_detail,
    onboarding_status,
    add_student_res,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const id = props.location.state?.id;
  const isOnboarding = props.location.state?.isOnboarding;
  const isCreating = window.location.pathname.includes("add") ? true : false;
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleActivateAccount = () => {
    try {
      dispatch(
        activateAccountUser(user_token, studio_id, {
          email: email,
          role: "student",
        })
      );
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      send_welcome_email: sendEmail,
    };

    isCreating
      ? dispatch(addStudent(params, user_token, studio_id))
      : dispatch(
          updateStudent(
            {
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone_number: phoneNumber,
            },
            user_token,
            studio_id,
            id
          )
        );
  };

  useEffect(() => {
    if (id) dispatch(getStudentDetail(user_token, studio_id, id));
  }, [id]);

  useEffect(() => {
    if (!isCreating) {
      setFirstName(student_detail?.first_name);
      setLastName(student_detail?.last_name);
      setEmail(student_detail?.email);
      setPhoneNumber(student_detail?.phone_number);
    }
  }, [student_detail]);

  useEffect(() => {
    if (add_student_res?.is_account_active === 0) {
      setShowModalReactivateStudent(true);
      return;
    }
    if (addStudentStatus === "success" || updateStudentStatus === "success") {
      history.goBack();
    } else if (isOnboarding && addStudentStatus === "success") {
      onboarding_status?.first_teacher === false
        ? history.push({
            pathname: "/add-teacher",
            state: { isOnboarding: true },
          })
        : onboarding_status?.schedule_class === false
        ? history.push({
            pathname: "/add-class",
            state: { isOnboarding: true },
          })
        : onboarding_status?.plan === false
        ? history.push({
            pathname: "/add-plan",
            state: { isOnboarding: true },
          })
        : onboarding_status?.term_and_condition === false
        ? history.push({
            pathname: "/setting-terms-and-conditions",
            state: { isOnboarding: true, step: 2 },
          })
        : onboarding_status?.cancellation_setting === false
        ? history.push({
            pathname: "/setting-cancellation",
            state: { isOnboarding: true, step: 2 },
          })
        : onboarding_status?.payment_information === false
        ? history.push({
            pathname: "/setting-payment",
            state: { isOnboarding: true, step: 2 },
          })
        : history.goBack();
    }
    dispatch(setStatusDefault());
  }, [addStudentStatus, updateStudentStatus, isOnboarding]);

  return (
    <>
      <OrganismLayout
        maxWidth="32rem"
        title={isCreating ? "Add Student" : "Edit Student"}
        onBoardingStatus={
          isOnboarding && <OnboardingStatus selectedStep={1} activeStep={1} />
        }
      >
        {isLoadingStudent ? (
          <AtomLoading />
        ) : (
          <Col>
            <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  autoFocus
                  label="First Name"
                  placeholder="John"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  label="Last Name"
                  placeholder="Chena"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  inputClassName={`${!isCreating && "border-0"}`}
                  required={isCreating}
                  readOnly={!isCreating}
                  label="Email Address"
                  placeholder="John@gmail.com"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  type="number"
                  label="Phone Number"
                  placeholder="087878****"
                  value={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                />
              </Form.Group>
              {isCreating && (
                <Form.Group>
                  <Form.Check
                    id="sent"
                    type="checkbox"
                    label="Send welcome email"
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#000",
                    }}
                    onChange={(event) => {
                      setSendEmail(event.target.checked ? 1 : 0);
                    }}
                  />
                </Form.Group>
              )}
              <div className="mt-3 mb-2 d-flex justify-content-center gap-3">
                <Button
                  type={"outline-secondary"}
                  title={isOnboarding ? "Skip for Now" : "Cancel"}
                  onClick={() => {
                    if (isOnboarding) {
                      onboarding_status?.first_teacher === false
                        ? history.push({
                            pathname: "/add-teacher",
                            state: { isOnboarding: true },
                          })
                        : onboarding_status?.schedule_class === false
                        ? history.push({
                            pathname: "/add-class",
                            state: { isOnboarding: true },
                          })
                        : onboarding_status?.plan === false
                        ? history.push({
                            pathname: "/add-plan",
                            state: { isOnboarding: true },
                          })
                        : onboarding_status?.term_and_condition === false
                        ? history.push({
                            pathname: "/setting-terms-and-conditions",
                            state: { isOnboarding: true, step: 2 },
                          })
                        : onboarding_status?.cancellation_setting === false
                        ? history.push({
                            pathname: "/setting-cancellation",
                            state: { isOnboarding: true, step: 2 },
                          })
                        : onboarding_status?.payment_information === false
                        ? history.push({
                            pathname: "/setting-payment",
                            state: { isOnboarding: true, step: 2 },
                          })
                        : history.goBack();
                    } else {
                      history.goBack();
                    }
                  }}
                />
                <Button
                  buttonType="submit"
                  type="primary"
                  title="CONTINUE"
                  isLoading={isLoadingStudent}
                />
              </div>
            </Form>
          </Col>
        )}
      </OrganismLayout>

      <ModalLogin
        show={showModalReactivateStudent}
        title="The Account Is Currently Being Deactivated"
        label="Continue to reactivate the student account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"REACTIVATE"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={handleActivateAccount}
      />
    </>
  );
};
