import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  HiCheckCircle,
  HiChevronLeft,
  HiChevronRight,
  HiOutlineInformationCircle,
} from "react-icons/hi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OnboardingStatus = ({
  activeStep,
  selectedStep,
  closeButton,
  setIsClose,
  setSelectedStep,
  onboarding,
}) => {
  const history = useHistory();

  const [step, setStep] = useState(selectedStep);

  const { onboarding_status, teacher } = useSelector((state) => state.owner);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please add your teacher first before adding your class.
    </Tooltip>
  );

  const data_onboarding =
    step === 1
      ? [
          {
            title: "Add First Student",
            value: onboarding_status?.first_student,
            pathname: "/add-student",
            step: 1,
          },
          {
            title: "Add Teacher",
            value: onboarding_status?.first_teacher,
            pathname: "/add-teacher",
            step: 2,
          },
          {
            title: "Schedule Your Class",
            value: onboarding_status?.schedule_class,
            pathname: "/add-class",
            step: 3,
          },
          {
            title: "Add plans",
            value: onboarding_status?.plan,
            pathname: "/add-plan",
            step: 4,
          },
        ]
      : [
          {
            title: "Insert terms and conditions",
            value: onboarding_status?.term_and_condition,
            pathname: "/setting-terms-and-conditions",
            step: 5,
          },
          {
            title: "Set Cancellation settings",
            value: onboarding_status?.cancellation_setting,
            pathname: "/setting-cancellation",
            step: 6,
          },
          {
            title: "Add payment information",
            value: onboarding_status?.payment_information,
            pathname: "/setting-payment",
            step: 7,
          },
        ];

  useEffect(() => {
    setStep(selectedStep);
  }, [selectedStep]);

  return (
    <div
      style={{
        width: "40rem",
        transform: "translateY(-90px)",
      }}
      className="mx-auto"
    >
      {closeButton && (
        <div
          onClick={() => setIsClose()}
          className="d-flex justify-content-end fs-10 text-white pointer custom-hover"
        >
          Close
        </div>
      )}
      <div className="shadow-sm p-3 rounded-10 bg-white">
        <div
          style={{ width: "250px" }}
          className="mx-auto d-flex justify-content-between align-items-center"
        >
          {step === 1 ? (
            <div></div>
          ) : (
            <HiChevronLeft
              onClick={() => {
                if (step === 1) return;
                setStep(step - 1);
              }}
              className={`pointer greyblue fs-4 custom-hover`}
            />
          )}
          <b>{step === 1 ? "1. Basic Settings" : "2. Advance Settings"}</b>
          {step === 2 ? (
            <div></div>
          ) : (
            <HiChevronRight
              onClick={() => {
                if (step === 2) return;
                setStep(step + 1);
              }}
              className={`pointer greyblue fs-4 custom-hover`}
            />
          )}
        </div>
        <div className="d-flex justify-content-between fs-12 p-2">
          {data_onboarding.map((el, idx) =>
            el.title === "Schedule Your Class" ? (
              <div key={idx} className="d-flex">
                <div
                  onClick={() => {
                    if (teacher.data?.length === 0 || el?.value) return;
                    if (onboarding) {
                      setSelectedStep(el?.step);
                    } else {
                      history.push({
                        pathname: el.pathname,
                        state: { isOnboarding: true },
                      });
                    }
                  }}
                  className={`${
                    el.value === false && teacher.data?.length > 0
                      ? "pointer"
                      : "disabled"
                  } d-flex align-items-center gap-1 custom-hover`}
                >
                  <HiCheckCircle
                    className={`${
                      el.value ? "greyblue" : "text-black-50"
                    } fs-5`}
                  />
                  {el.title}
                </div>
                <OverlayTrigger
                  delay={{ hide: 400 }}
                  placement="top"
                  overlay={renderTooltip}
                >
                  <div
                    style={{ fontSize: "14px" }}
                    className="d-flex align-items-center"
                  >
                    <HiOutlineInformationCircle className="text-warning ms-1" />
                  </div>
                </OverlayTrigger>
              </div>
            ) : (
              <div
                key={idx}
                onClick={() => {
                  if (el.value) return;
                  if (onboarding) {
                    setSelectedStep(el?.step);
                  } else {
                    history.push({
                      pathname: el.pathname,
                      state: { isOnboarding: true },
                    });
                  }
                }}
                className={`${
                  el.value ? "disabled" : "pointer"
                } d-flex align-items-center gap-1 custom-hover`}
              >
                <HiCheckCircle
                  className={`${el.value ? "greyblue" : "text-black-50"} fs-5`}
                />
                <span
                  className={`${
                    el.step === activeStep ? "greyblue" : "text-blue-50"
                  }`}
                >
                  {el.title}
                </span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingStatus;
