import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./register.styles.scss";
import basicSetting from "./../../assets/images/basic_setting.png";

import { FaExclamationCircle } from "react-icons/fa";

import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import Header from "../../components/Header/header.component";

import { verifyOtp } from "../../redux/user/user.action";
import { EditStudio } from "../../redux/studio/studio.action";

const StudioSetup = ({ nextStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { studio, status, isLoading } = useSelector((state) => state.studio);
  const { user } = useSelector((state) => state.user);

  const [studioName, setStudioName] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [headerImage, setHeaderImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  const studio_id = studio.studios?.at(-1).id;

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      studio_name: studioName,
      subdomain: subdomain,
      header_image: headerImage,
      logo: logoImage,
    };

    dispatch(EditStudio(params, studio._id ?? studio_id, user.token ?? studio.token));
  };

  useEffect(() => {
    if (status === "success") {
      nextStep();
    }
  }, [status]);

  return (
    <Row
      style={{ transform: "translateY(-77px)" }}
      className="justify-content-md-center"
    >
      <Col lg={7}>
        <div className="custom-card align-items-center p-3">
          <Row>
            <Col lg={5}>
              <img src={basicSetting} style={{ width: "100%" }} />
            </Col>
            <Col lg={7}>
              <p className="regular-text">
                Your students can easily access your class schedule, book and
                pay for online classes.
              </p>
              <Form onSubmit={handleSubmit}>
                <div className="d-flex align-items-center">
                  <TextInput
                    required
                    label="Studio Name"
                    placeholder="Yoga class"
                    className="mt-4"
                    value={studioName}
                    onChange={(event) => {
                      setStudioName(event.target.value);
                    }}
                  />
                </div>
                <div className="url-warning d-flex align-items-center my-3">
                  <FaExclamationCircle
                    color="#ffc107"
                    size={20}
                    style={{ marginRight: "10px" }}
                  />
                  <p className="regular-text m-0">
                    Pick a short and easy to remember URL. <br />
                    You can’t change this URL.
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <TextInput
                    required
                    placeholder="Subdomain Name"
                    onChange={(event) => {
                      setSubdomain(event.target.value);
                    }}
                    value={subdomain}
                    style={{ width: "95%" }}
                  />
                  <h6 className="m-0">.gigsclassroom.com</h6>
                </div>
                <p className="regular-text mt-2 mb-2">
                  Spaces and special characters are not allowed <br />
                  (excluding dashes).
                </p>

                <text className="gray-text">Upload your header image.</text>
                <div className="d-flex align-items-center ">
                  <Form.Group controlId="formFileSm" className="mb-2 mt-2">
                    <Form.Control
                      required
                      type="file"
                      name="headerImage"
                      onChange={(event) => {
                        setHeaderImage(event.target.files[0]);
                      }}
                      size="sm"
                    />
                  </Form.Group>
                </div>
                <p className="regular-text mb-2" style={{ fontSize: 10 }}>
                  Min. 1200 x 300 pixel dimension and max 1 MB file size.
                </p>
                <span className="gray-text">Upload Logo.</span>
                <div className="d-flex align-items-center ">
                  <Form.Group controlId="formFileSm" className="mb-2 mt-2">
                    <Form.Control
                      required
                      type="file"
                      name="logoImage"
                      onChange={(event) => {
                        setLogoImage(event.target.files[0]);
                      }}
                      size="sm"
                    />
                  </Form.Group>
                </div>
                <p className="regular-text mt-2 mb-2" style={{ fontSize: 10 }}>
                  Min. 300 x 300 pixel dimension and max 1 MB file size.
                </p>
                <Button
                  buttonType="submit"
                  isLoading={isLoading}
                  type="primary"
                  title="LAUNCH MY CLASS"
                  style={{ width: "60%" }}
                  className="mx-auto mt-3 mb-3"
                />
              </Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default StudioSetup;
