import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import StudioReducer from "./studio/studio.reducer";
import UserReducer from "./user/user.reducer";
import OwnerReducer from "./owner/owner.reducer";
import BanksReducer from "./bank/bank.reducer";
import StudentReducer from "./student/student.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: UserReducer,
  studio: StudioReducer,
  owner: OwnerReducer,
  banks: BanksReducer,
  student: StudentReducer,
});

export default persistReducer(persistConfig, rootReducer);
