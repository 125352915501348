import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "../classes.styles.scss";

import { Row, Col, Form, Table } from "react-bootstrap";
import Button from "../../../../../components/Button/button.component";
import SelectInput from "../../../../../components/SelectInput/select-input.component";
import iconRemove from "../../../../../assets/images/Icon feather-delete.png";

import {
  addIdTeacher,
  addClass,
  removeIdTeacher,
  setStatusDefault,
  getTeacher,
  updateClass,
  clearIdTeacher,
} from "../../../../../redux/owner/owner.action";
import { toast } from "react-toastify";

const ClassAddTeacherSetup = ({ prevStepSchedule, nextStepSchedule, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["subdomain", "user"]);
  const [validated, setValidated] = useState(false);
  const [idTeacher, setIdteacher] = useState("");
  const [teachers, setTeachers] = useState([]);

  const {
    class_id,
    addClass: addClassReducer,
    teacher,
    teacher: listTeacher,
    isLoadingClass,
    addClassStatus,
    updateClassStatus,
    class_detail,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const isCreating = window.location.pathname.includes("add") ? true : false;

  const data_teachers = isCreating ? addClassReducer.teacher : teachers;

  const handleAddIdTeacher = () => {
    if (idTeacher === "") {
      toast.error("Please Choose a Teacher");
      return;
    }

    let dataTeacher = teacher.data?.find((e) => e?.id === idTeacher);

    let params = {
      teacher_id: dataTeacher?.id,
      first_name: dataTeacher?.first_name,
      last_name: dataTeacher?.last_name,
    };
    isCreating
      ? dispatch(addIdTeacher(params))
      : setTeachers([...teachers, params]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (data_teachers.length === 0) {
      toast.error("Please Add Teacher");
      return;
    }
    let teacherIds = "";
    data_teachers.map((e, idx) => {
      if (idx === 0) {
        teacherIds = e.teacher_id ?? e.id;
      } else {
        teacherIds = teacherIds.concat(",", e.teacher_id ?? e.id);
      }
    });

    let params =
      addClassReducer.is_online === 1
        ? {
            // type: addClassReducer.type,
            class_name: addClassReducer.class_name,
            is_online: addClassReducer.is_online,
            location: addClassReducer.location,
            room: addClassReducer.room,
            slots: addClassReducer.slots,
            notes: addClassReducer.notes,
            start_date: addClassReducer.start_date,
            end_date: addClassReducer.end_date,
            // stop_repeating: addClassReducer.stop_repeating,
            repeat_every: addClassReducer.repeat_every,
            repeat_duration: addClassReducer.repeat_duration,
            teacher_ids: teacherIds,
            class_link: addClassReducer.class_link,
            additional_information: addClassReducer.additional_information,
            schedule: addClassReducer.schedule,
            class_price: addClassReducer.class_price,
            class_tax: addClassReducer.class_tax,
            credits: addClassReducer.credits,
          }
        : {
            // type: addClassReducer.type,
            class_name: addClassReducer.class_name,
            is_online: addClassReducer.is_online,
            location: addClassReducer.location,
            room: addClassReducer.room,
            slots: addClassReducer.slots,
            notes: addClassReducer.notes,
            start_date: addClassReducer.start_date,
            end_date: addClassReducer.end_date,
            // stop_repeating: addClassReducer.stop_repeating,
            repeat_every: addClassReducer.repeat_every,
            repeat_duration: addClassReducer.repeat_duration,
            teacher_ids: teacherIds,
            schedule: addClassReducer.schedule,
            class_price: addClassReducer.class_price,
            class_tax: addClassReducer.class_tax,
            credits: addClassReducer.credits,
          };
    isCreating
      ? dispatch(addClass(params, user_token, studio_id))
      : dispatch(updateClass(params, user_token, studio_id, id));
  };

  const removeTeacher = (idx) => {
    let deletedItem = data_teachers[idx];
    const afterDelete = data_teachers.filter((e) => e !== deletedItem);
    isCreating
      ? dispatch(removeIdTeacher(afterDelete))
      : setTeachers(afterDelete);
  };

  useEffect(() => {
    if (addClassStatus === "success" || updateClassStatus === "success") {
      dispatch(setStatusDefault());
      history.push({
        pathname: `/class/${class_id?._id}/detail`,
        state: { id: class_id?._id },
      });
    }
  }, [addClassStatus, updateClassStatus]);

  useEffect(() => {
    if (!isCreating) setTeachers(class_detail?.teachers_detail);
  }, [class_detail]);

  useEffect(() => {
    dispatch(getTeacher(user_token, studio_id, 1));
  }, []);

  return (
    <Col lg={4}>
      <Form onSubmit={handleSubmit}>
        <div
          className="custom-card1 align-items-center"
          style={{ paddingTop: 20 }}
        >
          <Form.Group style={{ marginBottom: 10, width: "100%" }}>
            <SelectInput
              required={data_teachers?.length === 0}
              label="Choose Teacher"
              data={teacher?.data}
              type="listTeacher"
              onChange={(event) => {
                setIdteacher(event.target.value);
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-between w-100">
            <div></div>
            <div>
              <Button
                style={{ marginTop: 10, marginBottom: 10 }}
                type="primary"
                title="Add Teacher"
                onClick={() => {
                  handleAddIdTeacher();
                }}
              />
            </div>
          </div>
          <div>
            <Table responsive="sm" style={{ minWidth: 380 }}>
              <tbody>
                {data_teachers.length > 0
                  ? data_teachers.map((element, idx) => (
                      <tr key={idx + 1}>
                        <td>{`#${idx + 1}`}</td>
                        <td>
                          {element?.first_name} {element?.last_name}
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              removeTeacher(idx);
                            }}
                            className="pointer custom-hover"
                          >
                            <img src={iconRemove} />
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
        </div>
        <div className=" p-3 d-flex justify-content-between gap-2">
          <Button
            type="outline-secondary"
            title={isCreating ? "Back" : "Cancel"}
            onClick={() => {
              if (isCreating) {
                prevStepSchedule();
              } else {
                // dispatch(clearIdTeacher());
                history.goBack();
              }
            }}
          />
          {/* {!isCreating && (
            <Button
              type="primary"
              title="Edit Schedules"
              onClick={nextStepSchedule}
            />
          )} */}
          <Button
            isLoading={isLoadingClass}
            type="primary"
            title={isCreating ? "ADD CLASS" : "Save"}
            buttonType="submit"
          />
        </div>
      </Form>
    </Col>
  );
};

export default ClassAddTeacherSetup;
