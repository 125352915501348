import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Container, Row, Col, Modal, Table } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import HeaderOwner from "../../../../components/HeaderOwner/header-owner.component";
import AtomLoading from "../../../../components/loding-indicator";
import TextInput from "../../../../components/TextInput/text-input.component";
import DropdownCard from "../../../../components/Button/dropdown.component";
import Pagination from "../../../../components/pagination";

import {
  HiChevronDown,
  HiSearch,
  HiLocationMarker,
  HiOfficeBuilding,
} from "react-icons/hi";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useCookies } from "react-cookie";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  changeStatusClass,
  getClassDetail,
  getClassParticipant,
  getClassTimeParticipant,
  updatePaymentStatus,
  updateStatusAttended,
} from "../../../../redux/owner/owner.action";
import AtomNumberFormat from "../../../../components/number-format";
import HeaderTeacher from "../../../../components/HeaderTeacher";
import EmptyData from "../../../../components/no-data";
import StudentModal from "../Students/student-modal";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import {
  addSession,
  deleteSession,
  editSession,
  getSchedules,
} from "../../../../services/classes";
import { toast } from "react-toastify";
import DatePicker from "../../../../components/date-picker";
import TimePicker from "react-time-picker";

const DetailClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const currentDate = new Date();
  const [cookies, setCookies] = useCookies(["user"]);
  const [selectedSchedule, setSelectedSchedule] = useState(currentDate);
  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [newSession, setNewSession] = useState({});
  const [modifySession, setModifySession] = useState({});
  const [deletedSession, setDeletedSession] = useState({});
  const [time, setTime] = useState("");
  const [search, setSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [index, setIndex] = useState("");
  const [index1, setIndex1] = useState("");
  const [fetch, setFetch] = useState(true);
  const [status, setStatus] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [isAttended, setIsAttended] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("10:00");
  const [title, setTitle] = useState("Add");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const {
    class_detail,
    class_participant,
    class_time_participant,
    isLoading,
    isLoadingClass,
    isLoadingClassParticipant,
  } = useSelector((state) => state.owner);

  const id = props.location.state?.id;
  const isBrowseClasses = props.location?.state;
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const status_class =
    modalChangeStatus.status === "active" ? "cancel" : "active";

  // useOutsideOnclick(wrapperRef, setShow);

  const setDate = (e) => {
    setSelectedDate(e);
    setShow2(false);
    setTime("");
  };

  const setScheduleDate = (e) => {
    setSelectedSchedule(e);
    setShow9(false);
  };

  const attendedStatus = [
    { label: "Select Status", value: "", selected: true },
    { label: "Attended", value: 1, selected: false },
    { label: "Unattended", value: 0, selected: false },
  ];

  const payment_status =
    show6?.payment_status === "pending"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "payment verification"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "settlement"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "refund request" && [
          { label: "Select Status", value: "", selected: true },
          { label: "Refunded", value: "refunded", selected: false },
        ];

  const handleChangeSatusClass = () => {
    dispatch(
      changeStatusClass(
        user_token,
        studio_id,
        modalChangeStatus.id,
        status_class
      )
    );
    setModalChangeStatus({ visible: false });
  };

  const updateClassAttendance = (e) => {
    e.preventDefault();
    let params = {
      order_id: show4?.id,
      is_attended: isAttended,
    };

    dispatch(updateStatusAttended(user_token, studio_id, params));

    setFetch(true);
    setShow4({ visible: false });
    setShow1(false);
  };

  const cancelClass = (class_order_number) => {
    let params = {
      payment_status: "cancelled",
    };

    dispatch(
      updatePaymentStatus(user_token, studio_id, params, class_order_number)
    );

    setFetch(true);
    setShow5({ visible: false });
    setShow1(false);
  };

  const updateStatusPayment = (e) => {
    e.preventDefault();
    let params = {
      payment_status: paymentStatus,
    };

    dispatch(updatePaymentStatus(user_token, studio_id, params, show6?.id));

    setFetch(true);
    setShow6({ visible: false });
    setShow1(false);
  };

  const handleGetSchedules = async () => {
    setLoadingSchedules(true);
    try {
      const res = await getSchedules(user_token, studio_id, id, active1);
      setSchedules(res.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoadingSchedules(false);
    }
  };

  const handleModifySession = async (session_id) => {
    setLoading(true);
    try {
      let params = {
        class_date: moment(selectedSchedule).format("YYYY-MM-DD"),
        class_start_time: startTime,
        class_end_time: endTime,
      };

      title === "Add"
        ? await addSession(user_token, studio_id, params, session_id)
        : await editSession(user_token, studio_id, params, session_id);

      handleGetSchedules();
      toast.success(
        title === "Add" ? "Succesfully Added!" : "Succesfully Updated!"
      );
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoading(false);
      setShow8(false);
      setScheduleDate(currentDate);
      setStartTime("09:00");
      setEndTime("10:00");
      setModifySession({ visible: false });
    }
  };

  const handleDeleteSession = async (session_id) => {
    setLoading(true);
    try {
      await deleteSession(user_token, studio_id, session_id);
      handleGetSchedules();
      setDeletedSession({ visible: false });
      toast.success("Succesfully Deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoading(false);
      setShow8(false);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getClassDetail(user_token, studio_id, id));
    }
  }, [id]);

  useEffect(() => {
    handleGetSchedules();
  }, [active1]);

  useEffect(() => {
    if (selectedDate !== "") {
      dispatch(
        getClassTimeParticipant(
          user_token,
          studio_id,
          id,
          moment(selectedDate).format("YYYY-MM-DD")
        )
      );
    }
  }, [active, selectedDate]);

  useEffect(() => {
    if (fetch) {
      dispatch(
        getClassParticipant(
          user_token,
          studio_id,
          id,
          active,
          searchInput,
          moment(selectedDate).format("YYYY-MM-DD"),
          time.split("-")[0],
          time.split("-")[1]
        )
      );
      setFetch(false);
    }
  }, [fetch]);

  useEffect(() => {
    dispatch(
      getClassParticipant(
        user_token,
        studio_id,
        id,
        active,
        searchInput,
        moment(selectedDate).format("YYYY-MM-DD"),
        time.split("-")[0],
        time.split("-")[1]
      )
    );
  }, [searchInput, selectedDate, time, active]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      {user_role === "owner" ? (
        <HeaderOwner isBrowseClasses={isBrowseClasses} title="Class Detail" />
      ) : (
        <HeaderTeacher isBrowseClasses={isBrowseClasses} title="Class Detail" />
      )}
      {isLoadingClass ? (
        <AtomLoading />
      ) : (
        <Row
          style={{
            maxWidth: "28rem",
            transform: "translateY(-77px)",
            wordBreak: "break-all",
          }}
          className="d-flex flex-column mx-auto"
        >
          <Col className="mx-auto shadow-sm p-3 mb-4 bg-body rounded-lg">
            <div className="d-flex justify-content-between">
              <p>
                {class_detail?.is_online === 1 ? "Online" : "Offline"} Class
              </p>
              <div ref={wrapperRef} className="position-relative">
                <span className="text-capitalize greyblue me-2">
                  {class_detail?.status}
                </span>
                <button
                  onClick={() => setShow((show) => !show)}
                  className="option-button"
                >
                  ...
                </button>
                {show && (
                  <DropdownCard
                    width="6rem"
                    className="dropdown-text"
                    positionTop
                    positionLeft={85}
                    data={[
                      {
                        name: "Edit Class",
                        onClick: () =>
                          history.push({
                            pathname: `/class/${id}/edit`,
                            state: { id: id },
                          }),
                      },
                      {
                        name: "Deactivate",
                        onClick: () => {
                          setModalChangeStatus({
                            id: id,
                            visible: true,
                            name: class_detail?.class_name,
                            date: moment(class_detail?.created_at).format("ll"),
                            status: class_detail?.status,
                          });
                        },
                      },
                      {
                        name: "Add Session",
                        onClick: () => {
                          setTitle("Add");
                          setModifySession({
                            visible: true,
                            id: id,
                          });
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </div>
            <span>{class_detail?.class_name}</span> <br />
            <div className="heading3 mb-3">
              {class_detail?.is_online === 1 &&
              class_detail?.location === null ? null : (
                <>
                  <span>
                    <HiLocationMarker className="text-pink" />{" "}
                    {class_detail?.location}
                  </span>
                  <br />
                </>
              )}
              {class_detail?.is_online === 1 &&
              class_detail?.room === null ? null : (
                <span>
                  <HiOfficeBuilding className="text-pink" />{" "}
                  {class_detail?.room}
                </span>
              )}
            </div>
            {class_detail?.is_online === 1 && (
              <>
                <span className="heading3">Class Link</span>
                <p>{class_detail?.class_link}</p>
                <span className="heading3">Additional Information</span>
                <p>{class_detail?.additional_information ?? "-"}</p>
              </>
            )}
            <Row className="mb-3">
              <Col>
                <span className="heading3">Class Price</span> <br />
                <AtomNumberFormat
                  prefix="IDR "
                  value={class_detail?.class_price}
                />
              </Col>
              <Col>
                <span className="heading3">Tax (%)</span> <br />
                {class_detail?.class_tax}
              </Col>
            </Row>
            <span className="heading3">Teacher</span>
            <p>{class_detail.teachers}</p>
            <span className="heading3">Notes</span>
            <p>{class_detail?.notes ?? "-"}</p>
          </Col>

          <b>Sessions</b>
          {loadingSchedules ? (
            <AtomLoading />
          ) : (
            <Col className="mx-auto shadow-sm p-3 mt-2 mb-4 bg-body rounded-lg">
              <Table responsive="sm" hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {schedules.data?.length > 0
                    ? schedules.data.map((el, idx) => (
                        <tr key={idx}>
                          <td className="position-relative">
                            <button
                              onClick={() => {
                                setShow8((show8) => !show8);
                                setIndex1(idx);
                              }}
                              className="option-button"
                            >
                              ...
                            </button>
                            {index1 === idx && show8 && (
                              <DropdownCard
                                width="5rem"
                                className="dropdown-text"
                                positionTop
                                positionLeft={40}
                                data={[
                                  {
                                    name: "Edit",
                                    onClick: () => {
                                      if (
                                        moment(currentDate).format(
                                          "YYYY-MM-DD HH:mm"
                                        ) >
                                        `${el?.class_date} ${el?.class_start_time}`
                                      ) {
                                        toast.error(
                                          "Cannot Edit Session in the Past."
                                        );
                                        return;
                                      }
                                      setTitle("Edit");
                                      setModifySession({
                                        visible: true,
                                        id: el?.id,
                                      });
                                      setSelectedSchedule(
                                        new Date(el?.class_date)
                                      );
                                      setStartTime(el?.class_start_time);
                                      setEndTime(el?.class_end_time);
                                    },
                                  },
                                  {
                                    name: "Delete",
                                    onClick: () => {
                                      setDeletedSession({
                                        id: el?.id,
                                        visible: true,
                                        day: el?.day,
                                        date: moment(el?.class_date).format(
                                          "ll"
                                        ),
                                        time: `${el?.class_start_time} - ${el?.class_end_time}`,
                                      });
                                    },
                                  },
                                ]}
                              />
                            )}
                          </td>
                          <td>
                            {moment(el?.class_date).format("DD MMM YYYY")}
                          </td>
                          <td>{el?.day}</td>
                          <td>
                            {el?.class_start_time} - {el?.class_end_time}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
              {schedules.data?.length > 0 && (
                <Pagination
                  // className="py-3"
                  active={active1}
                  setActive={setActive1}
                  current_page={schedules.meta.pagination?.current_page}
                  total_pages={schedules.meta.pagination?.total_pages}
                />
              )}
            </Col>
          )}

          <b>Participants</b>
          <Col className="mx-auto shadow-sm p-3 my-2 bg-body rounded-lg b-greyblue fs-12">
            <Row>
              <Col onClick={() => setShow2(true)} className="border-end">
                <div className="mt-1 d-flex justify-content-between align-items-center pointer dropdown-text">
                  {selectedDate
                    ? moment(selectedDate).format("DD/MM/YYYY")
                    : "Date"}
                  <HiChevronDown />
                </div>
              </Col>
              <Col
                onClick={() => setShow3((show3) => !show3)}
                className="border-end position-relative"
              >
                <div className="mt-1 pointer dropdown-text d-flex justify-content-between align-items-center">
                  {time !== "" ? time : "Time"}
                  <HiChevronDown />
                </div>
                {show3 && (
                  <DropdownCard
                    width="7.5rem"
                    className="dropdown-text"
                    data={[
                      ...class_time_participant.map((el) => ({
                        name: el,
                        onClick: () => setTime(el),
                      })),
                      {
                        name: "Reset",
                        onClick: () => setTime(""),
                      },
                    ]}
                  />
                )}
              </Col>

              <Col>
                {search === false ? (
                  <button
                    onClick={() => setSearch(true)}
                    className="border-0 bg-greyblue text-white rounded-3 py-1 px-2 custom-hover"
                  >
                    Search
                  </button>
                ) : (
                  <div className="position-relative">
                    <TextInput
                      search
                      autoFocus
                      placeholder="Search"
                      onKeyUp={(e) => setSearchInput(e.target.value)}
                    />
                    <HiSearch
                      className="greyblue position-absolute fs-5"
                      style={{ top: 8, right: 0 }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          {isLoadingClassParticipant ? (
            <AtomLoading />
          ) : (
            <Col className="mx-auto shadow-sm p-3 my-3 bg-body rounded-lg">
              <p className="heading3">
                {class_participant.data?.length}/{class_participant?.total}{" "}
                Users
              </p>
              {class_participant.data?.length > 0 ? (
                class_participant?.data.map((el, idx) => (
                  <div key={idx} className="">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column fs-12">
                        <h6>{el?.student_name}</h6>
                        <span>
                          {el?.student_email} - {el?.student_phone_number}
                        </span>
                        <span className="lightaquamarine">
                          <AtomNumberFormat prefix="IDR " value={el?.price} /> /{" "}
                          {el?.payment_status}
                        </span>
                        <span className="text-capitalize">
                          Payment Method: {el?.payment_method}
                        </span>
                        <span>
                          Plan:{" "}
                          {/* {el?.payment_method !== "online"
                            ? el?.plan_name
                            : "-"} */}
                          {el?.plan_name !== "" ? el?.plan_name : "-"}
                        </span>
                      </div>

                      <div
                        ref={wrapperRef}
                        className="fs-12 d-flex flex-column align-items-end position-relative"
                      >
                        <button
                          onClick={() => {
                            setShow1((show1) => !show1);
                            setIndex(idx);
                          }}
                          className="option-button "
                        >
                          ...
                        </button>
                        {index === idx && show1 && (
                          <DropdownCard
                            className="dropdown-text"
                            positionLeft={130}
                            positionTop
                            data={[
                              {
                                name: "Edit Status Attended",
                                onClick: () =>
                                  setShow4({
                                    id: el?.order_id,
                                    visible: true,
                                    name: class_detail?.class_name,
                                    invoice: el?.order_number,
                                    attended_status: el?.is_attended,
                                  }),
                              },
                              {
                                name: "Edit Status Booking",
                                onClick: () =>
                                  setShow7({
                                    id: el?.order_id,
                                    visible: true,
                                    name: class_detail?.class_name,
                                    invoice: el?.order_number,
                                    booking_status: el?.status,
                                  }),
                              },
                              {
                                name: "Edit Status Payment",
                                onClick: () =>
                                  setShow6({
                                    id: el?.order_number,
                                    visible: true,
                                    name: el?.name,
                                    invoice: el?.order_number,
                                    payment_status: el?.payment_status,
                                  }),
                              },
                              {
                                name: "Cancel Order",
                                onClick: () =>
                                  setShow5({
                                    id: el?.order_number,
                                    visible: true,
                                    name: class_detail?.class_name,
                                    student_name: el?.student_name,
                                  }),
                              },
                            ]}
                          />
                        )}
                        <span className="text-capitalize">
                          {el?.booking_class_status}
                        </span>
                        <span>{el?.is_attended}</span>
                        <span className="fs-10">{el?.class_date}</span>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <EmptyData />
              )}
              {/* {class_participant.data?.length > 0 && (
                <Pagination
                  active={active}
                  setActive={setActive}
                  current_page={class_participant?.current_page}
                  total_pages={class_participant?.total}
                />
              )} */}
            </Col>
          )}
        </Row>
      )}

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size={"sm"}
        centered
        contentClassName="rounded-lg"
      >
        <div className="p-3 text-center">
          <Calendar
            locale="en"
            tileClassName={"rounded-circle"}
            className="border-0"
            prevLabel={<FaAngleLeft className="fs-4 greyblue" />}
            nextLabel={<FaAngleRight className="fs-4 greyblue" />}
            // minDate={selectedDate}
            onChange={(e) => setDate(e)}
            value={selectedDate}
          />
          <div onClick={() => setShow2(false)} className="">
            <p className="pt-3 m-0 pointer custom-hover greyblue">Cancel</p>
          </div>
        </div>
      </Modal>

      <StudentModal
        title="Edit Booking Status"
        data={show7}
        setShow={() => setShow7({ visible: false })}
        status={status}
        currentStatus={show7?.booking_status}
      />

      <StudentModal
        title="Edit Attended Status"
        data={show4}
        setShow={() => setShow4({ visible: false })}
        status={attendedStatus}
        currentStatus={show4?.attended_status}
        onChange={(e) => setIsAttended(e.target.value)}
        handleSubmit={updateClassAttendance}
      />

      <StudentModal
        title="Edit Payment Status"
        data={show6}
        setShow={() => setShow6({ visible: false })}
        status={payment_status}
        currentStatus={show6?.payment_status}
        onChange={(e) => setPaymentStatus(e.target.value)}
        handleSubmit={updateStatusPayment}
      />

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show5?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Cancel Order</h2>
          <p>Cancel this Order?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{show5.name}</b>
              <br />
              <span className="heading3">for {show5?.student_name}</span>
            </p>
          </div>
          <span className="mb-3 text-danger f-12">
            You need to return the money manually to your customer
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow5({ visible: false })}
            />
            <Button
              title="Submit"
              type="primary"
              onClick={() => cancelClass(show5?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modalChangeStatus?.visible}
        onHide={() => setModalChangeStatus({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">
              {modalChangeStatus === "active" ? "Deactivate " : "Activate "}
              Class
            </h2>
            <p className="pb-3">
              Please confirm you want to
              {modalChangeStatus === "active"
                ? " deactivate "
                : " activate "}{" "}
              this Class.
            </p>
            <div className="b-gray justify-content-center rounded-lg mb-3 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Class Name</span> <br />
                  {modalChangeStatus?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Date</span> <br />
                  {modalChangeStatus?.date}
                </Col>
              </Row>
            </div>
            {modalChangeStatus === "active" && (
              <span className="text-danger f-12">
                Deactivate this class will cancel all the bookings schedules.
              </span>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setModalChangeStatus({ visible: false })}
            />
            <Button
              isLoading={isLoading}
              title="SEND REQUEST"
              type="primary"
              onClick={handleChangeSatusClass}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={deletedSession?.visible}
        onHide={() => setDeletedSession({ visible: false })}
      >
        <div className="py-4 px-3 text-center">
          <h2 className="heading1 m-0">
            Are you sure want to delete this session ?
          </h2>
          <div className="mx-5 p-2 my-4 rounded">
            <Row>
              <Col>
                <p>{deletedSession?.date}</p>
              </Col>
              <Col>
                <p>{deletedSession?.day}</p>
              </Col>
              <Col className="text-decoration-underline">
                <p>{deletedSession?.time}</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center gap-5">
            <Button
              type="secondary"
              title="Cancel"
              onClick={() => setDeletedSession({ visible: false })}
            />
            <Button
              isLoading={loading}
              type="primary"
              title="Delete"
              onClick={() => handleDeleteSession(deletedSession?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modifySession?.visible}
        onHide={() => setModifySession({ visible: false })}
      >
        <div className="py-3 px-4">
          <h2 className="text-center heading1">
            {title === "Add" ? "Add Schedule" : "Edit Schedule"}
          </h2>
          <Row className="mb-3">
            <Col>
              <DatePicker
                title="Select Date"
                currentDate={currentDate}
                selectedDate={selectedSchedule}
                show={show9}
                setShow={setShow9}
                setDate={setScheduleDate}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <div className={`form-group`}>
                <label className="custom-form-label mt-0">Start Time</label>
                <TimePicker
                  format="HH:mm"
                  onChange={(event) => {
                    setStartTime(event);
                  }}
                  disableClock={true}
                  value={startTime}
                />
              </div>
            </Col>
            <Col>
              <div className={`form-group`}>
                <label className="custom-form-label mt-0">End Time</label>
                <TimePicker
                  format="HH:mm"
                  onChange={(event) => {
                    setEndTime(event);
                  }}
                  disableClock={true}
                  value={endTime}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-center gap-5 mt-4">
            <Button
              type="secondary"
              title="Cancel"
              onClick={() => {
                setScheduleDate(currentDate);
                setModifySession({ visible: false });
              }}
            />
            <Button
              isLoading={loading}
              type="primary"
              title="Confirm"
              onClick={() => {
                handleModifySession(modifySession?.id);
              }}
            />
          </div>
        </div>
      </Modal>

      <Footer />
    </Container>
  );
};

export default DetailClass;
