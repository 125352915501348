import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Row, Col, Modal, Table } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import {
  changeStatusClass,
  deleteClass,
  getClass,
  getStudents,
  getTeacher,
} from "./../../../../redux/owner/owner.action";
import DropdownCard from "../../../../components/Button/dropdown.component";
import AtomNumberFormat from "../../../../components/number-format";
import Pagination from "../../../../components/pagination";
import AtomLoading from "../../../../components/loding-indicator";
import EmptyData from "../../../../components/no-data";
import iconRemove from "./../../../../assets/images/Icon feather-delete.png";
import { sendActivities } from "../../../../services/classes";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import OrganismLayout from "../../../../components/Layout/organism-layout";

export const Classes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user", "subdomain"]);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({
    id: "",
    visible: false,
    name: "",
    date: null,
  });
  const [modal1, setModal1] = useState({
    id: null,
    visible: false,
  });
  const [modalChangeStatus, setModalChangeStatus] = useState({
    id: "",
    visible: false,
    name: "",
    date: null,
    status: "",
  });
  const [active, setActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalSendAllActivities, setModalSendAllActivities] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [listSelectedOption, setListSelectedOption] = useState([]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const {
    owner_classes,
    isLoadingClass,
    addClass: addClassReducer,
    class_detail,
    isLoading,
    status,
    students,
  } = useSelector((state) => state.owner);
  const { user, teacher, loginFromStudio } = useSelector((state) => state.user);

  const status_class =
    modalChangeStatus.status === "active" ? "cancel" : "active";
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const tableHead = [
    "",
    "Date",
    "Class Name",
    "Teacher",
    "Type",
    "Price",
    "Status",
  ];

  const options =
    students.data?.length > 0 &&
    students.data.map((el) => ({
      label: `${el.first_name} ${el.last_name ?? ""}`,
      value: el.email,
    }));

  // useOutsideOnclick(wrapperRef, setShow);

  const handleDeleteClass = (id) => {
    dispatch(deleteClass(user_token, studio_id, id));

    setModal({ visible: false });
  };

  const handleInvitation = async () => {
    setLoading(true);
    try {
      let params = {
        activity_id: modal1.id ?? 0,
        emails: listSelectedOption.map((el) => el.user_email).join(","),
      };
      await sendActivities(params, user_token, studio_id);

      setModal1(false);
      setModalSendAllActivities(false);
      toast.success("Sent Successfully");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSatusClass = () => {
    dispatch(
      changeStatusClass(
        user_token,
        studio_id,
        modalChangeStatus.id,
        status_class
      )
    );
    setModalChangeStatus({ visible: false });
  };

  const handleDelete = (idx) => {
    let deletedItem = listSelectedOption[idx];
    let newData = listSelectedOption.filter((e) => e !== deletedItem);

    setListSelectedOption(newData);
  };

  useEffect(() => {
    let params = {
      token_jwt: user_token,
      studio_id: studio_id,
    };
    dispatch(getStudents(params, active));
    dispatch(getTeacher(user_token, studio_id, 1));
  }, []);

  useEffect(() => {
    if (selectedOption !== null) {
      setListSelectedOption([
        ...listSelectedOption,
        { user_email: selectedOption?.value },
      ]);
    }
  }, [selectedOption]);

  useEffect(() => {
    dispatch(getClass(user_token, studio_id, active));
  }, [isLoading, active]);

  return (
    <>
      <OrganismLayout title="Classes" maxWidth="56rem" className="p-0">
        <Col>
          <div className="d-flex justify-content-between p-3">
            <Button
              isLoading={teacher?.data?.length === 0}
              title="Add Class"
              type="primary"
              onClick={() => {
                history.push("/add-class");
              }}
            />
            <Button
              isLoading={teacher?.data?.length === 0}
              title="Send All Classes"
              type="primary"
              onClick={() => setModalSendAllActivities(true)}
            />
          </div>
          {isLoadingClass ? (
            <AtomLoading />
          ) : (
            <Table responsive hover size="sm" className="">
              <thead>
                <tr>
                  {tableHead?.map((el, idx) => (
                    <th
                      className="py-3 fw-bold"
                      style={{ backgroundColor: "#ededed" }}
                      key={idx}
                    >
                      {el}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody ref={wrapperRef}>
                {owner_classes.data?.length ? (
                  owner_classes.data?.map((el, idx) => (
                    <tr key={idx}>
                      <td className="">
                        <button
                          onClick={() => {
                            setShow((show) => !show);
                            setIndex(idx);
                          }}
                          className="option-button"
                        >
                          ...
                        </button>
                        {index === idx && show && (
                          <DropdownCard
                            className="dropdown-text"
                            // positionTop
                            positionLeft={40}
                            data={[
                              {
                                name: "Edit",
                                onClick: () =>
                                  history.push({
                                    pathname: `/class/${el?.id}/edit`,
                                    state: { id: el?.id },
                                  }),
                              },
                              {
                                name: "Send Class",
                                onClick: () => {
                                  setShow(false);
                                  setModal1({
                                    id: el?.id,
                                    visible: true,
                                    isOnline: el?.is_online,
                                    name: el?.class_name,
                                    teachers: el?.teachers,
                                  });
                                },
                              },
                              {
                                name: "Delete",
                                onClick: () => {
                                  setShow(false);
                                  setModal({
                                    id: el?.id,
                                    visible: true,
                                    name: el?.class_name,
                                    date: moment(el?.created_at).format("ll"),
                                  });
                                },
                              },
                              {
                                name:
                                  el?.status === "active"
                                    ? "Deactivate"
                                    : "Activate",
                                onClick: () => {
                                  setShow(false);
                                  setModalChangeStatus({
                                    id: el?.id,
                                    visible: true,
                                    name: el?.class_name,
                                    date: moment(el?.created_at).format("ll"),
                                    status: el?.status,
                                  });
                                },
                              },
                            ]}
                          />
                        )}
                      </td>
                      <td>{moment(el?.created_at).format("ll")}</td>
                      <td>
                        <Link
                          className="text-black"
                          to={{
                            pathname: `/class/${el?.id}/detail`,
                            state: { id: el?.id },
                          }}
                        >
                          {el?.class_name}
                        </Link>
                      </td>
                      <td>{el?.teachers}</td>
                      <td>{el?.is_online === 1 ? "Online" : "Offline"}</td>
                      <td>
                        <AtomNumberFormat
                          prefix="IDR "
                          value={el?.class_price}
                        />
                      </td>
                      <td className="text-capitalize">{el?.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <EmptyData />
                    </td>
                  </tr>
                )}
              </tbody>
              {/* <tfoot className="bg-white">
                <tr>
                  <td className="rounded-bottom border-0" colSpan={10}>
                    {owner_classes.data?.length > 0 && (
                      <Pagination
                        className="py-4"
                        active={active}
                        setActive={setActive}
                        current_page={
                          owner_classes.meta.pagination?.current_page
                        }
                        total_pages={owner_classes.meta.pagination?.total_pages}
                      />
                    )}
                  </td>
                </tr>
              </tfoot> */}
            </Table>
          )}
          {owner_classes.data?.length > 0 && (
            <Pagination
              className="py-4"
              active={active}
              setActive={setActive}
              current_page={owner_classes.meta.pagination?.current_page}
              total_pages={owner_classes.meta.pagination?.total_pages}
            />
          )}
        </Col>
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modal?.visible}
        onHide={() => setModal({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Delete Class</h2>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{modal?.name}</b> <br />
              <span className="heading3">{modal?.date}</span>
            </p>
          </div>
          <span className="mb-3 text-danger f-12">
            Deleting this data will delete all related bank id in order or
            transactions history.
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setModal({ visible: false })}
            />
            <Button
              title="SUBMIT"
              type="primary"
              onClick={() => handleDeleteClass(modal?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modalChangeStatus?.visible}
        onHide={() => setModalChangeStatus({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">
              {modalChangeStatus === "active" ? "Deactivate " : "Activate "}
              Class
            </h2>
            <p className="pb-3">
              Please confirm you want to
              {modalChangeStatus === "active"
                ? " deactivate "
                : " activate "}{" "}
              this Class.
            </p>
            <div className="b-gray justify-content-center rounded-lg mb-3 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Class Name</span> <br />
                  {modalChangeStatus?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Date</span> <br />
                  {modalChangeStatus?.date}
                </Col>
              </Row>
            </div>
            {modalChangeStatus === "active" && (
              <span className="text-danger f-12">
                Deactivate this class will cancel all the bookings schedules.
              </span>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setModalChangeStatus({ visible: false })}
            />
            <Button
              isLoading={isLoading}
              title="SEND REQUEST"
              type="primary"
              onClick={handleChangeSatusClass}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modal1.visible}
        onHide={() => setModal1({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">SEND CLASS</h2>
          </div>
          <p>Sent this class ?</p>
          <div className="rounded-10 shadow-sm px-2 py-3 mb-3">
            <p className="heading3 m-0">
              {modal1.isOnline === 1 ? "Online" : "Offline"}
            </p>
            <span>{modal1.name}</span>
            <hr />
            <p className="heading3 m-0">Teacher(s)</p>
            <span className="greyblue">{modal1.teachers}</span>
          </div>
          <p>Send to :</p>
          <Select
            isClearable
            placeholder="search user or type email address"
            onChange={setSelectedOption}
            options={options}
            className="mb-3"
          />
          <Table responsive="sm">
            <tbody>
              {listSelectedOption.length > 0
                ? listSelectedOption.map((el, idx) => (
                    <tr key={idx}>
                      <td className="d-flex justify-content-between">
                        <span>{el?.user_email}</span>
                        <div
                          onClick={() => {
                            handleDelete(idx);
                          }}
                          className="pointer custom-hover"
                        >
                          <img src={iconRemove} />
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => {
                setListSelectedOption([]);
                setModal1({ visible: false });
              }}
            />
            <Button
              isLoading={loading}
              title="SEND"
              type="primary"
              onClick={handleInvitation}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modalSendAllActivities}
        onHide={() => setModalSendAllActivities(false)}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">SEND ALL CLASSES</h2>
          </div>
          <p>Sent to :</p>
          <Select
            isClearable
            placeholder="search user or type email address"
            onChange={setSelectedOption}
            options={options}
            className="mb-3"
          />
          <Table responsive="sm">
            <tbody>
              {listSelectedOption.length > 0
                ? listSelectedOption.map((el, idx) => (
                    <tr key={idx}>
                      <td className="d-flex justify-content-between">
                        <span>{el?.user_email}</span>
                        <div
                          onClick={() => {
                            handleDelete(idx);
                          }}
                          className="pointer custom-hover"
                        >
                          <img src={iconRemove} />
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => {
                setListSelectedOption([]);
                setModalSendAllActivities(false);
              }}
            />
            <Button
              isLoading={loading}
              title="SEND"
              type="primary"
              onClick={handleInvitation}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
