import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import DropdownCard from "../../../../../components/Button/dropdown.component";
import Button from "../../../../../components/Button/button.component";
import AtomNumberFormat from "../../../../../components/number-format";
import AtomLoading from "../../../../../components/loding-indicator";

import { useHistory } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import {
  deactivePlan,
  deletePlan,
  getDetailPlans,
  setStatusDefault,
} from "../../../../../redux/owner/owner.action";
import { useOutsideOnclick } from "../../../../../services/clickOutside";
import OrganismLayout from "../../../../../components/Layout/organism-layout";
import ModalDelete from "../../../../../components/Modal/delete";

const DetailPlan = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user"]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, detail_plan, deactive_status } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const id = props.location.state?.id;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  useOutsideOnclick(wrapperRef, setShow);

  const handleDeletePlan = (id) => {
    dispatch(deletePlan(user_token, studio_id, id));

    history.goBack();
  };

  const handleDeactivatePlan = (id, value) => {
    dispatch(deactivePlan(user_token, studio_id, id, value));

    setShow(false);
    setShow2({ visible: false });
  };

  useEffect(() => {
    if (deactive_status === "success") {
      dispatch(getDetailPlans(user_token, studio_id, id));
      dispatch(setStatusDefault());
    } else {
      dispatch(getDetailPlans(user_token, studio_id, id));
    }
  }, [deactive_status]);

  return (
    <>
      <OrganismLayout title="Detail Plan" maxWidth="28rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Col className="">
            <div className="d-flex justify-content-between">
              <span>
                <b>{detail_plan?.plan_name}</b> <br />
                <span>
                  Updated At{" "}
                  {moment(detail_plan?.updated_at).format("DD MMM YYYY")}
                </span>
              </span>
              <div ref={wrapperRef} className="position-relative">
                <button
                  onClick={() => setShow((show) => !show)}
                  className="option-button"
                >
                  ...
                </button>
                {show && (
                  <DropdownCard
                    className="dropdown-text"
                    positionTop
                    positionLeft={40}
                    data={[
                      {
                        name: "Edit",
                        onClick: () =>
                          history.push({
                            pathname: "/edit-plan",
                            state: { id: detail_plan?.id },
                          }),
                      },
                      {
                        name: "Delete",
                        onClick: () =>
                          setShow1({
                            id: detail_plan?.id,
                            visible: true,
                            name: detail_plan?.plan_name,
                            plan_type: detail_plan?.plan_type,
                          }),
                      },
                      {
                        name:
                          detail_plan?.status === "active"
                            ? "Deactivate"
                            : "Activate",
                        onClick: () =>
                          setShow2({
                            id: detail_plan?.id,
                            value:
                              detail_plan?.status === "active"
                                ? "inactive"
                                : "active",
                            visible: true,
                            title:
                              detail_plan?.status === "active"
                                ? "Deactivate"
                                : "Activate",
                            name: detail_plan?.plan_name,
                            plan_type: detail_plan?.plan_type,
                          }),
                      },
                    ]}
                  />
                )}
              </div>
            </div>
            <hr style={{ height: "2px" }} />
            <Row className="heading3">
              <Col>Price</Col>
              <Col>Tax</Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <AtomNumberFormat prefix="IDR " value={detail_plan?.price} />
              </Col>
              <Col>{detail_plan?.tax} (%)</Col>
            </Row>
            <Row className="heading3">
              <Col>
                {detail_plan?.time_type === "duration"
                  ? "Duration"
                  : detail_plan?.time_type === "valid_date"
                  ? "Valid Date"
                  : "Duration"}
              </Col>
              <Col>
                {detail_plan?.plan_type === "membership"
                  ? "Usage Limit"
                  : "Credits"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {detail_plan?.time_type === "valid_date"
                  ? `${detail_plan?.start_valid_date} - ${detail_plan?.end_valid_date}`
                  : `${detail_plan?.valid_for} ${detail_plan?.duration_type}`}
              </Col>
              <Col>
                {detail_plan?.plan_type === "membership"
                  ? detail_plan?.usage_limit
                  : detail_plan?.credits}
              </Col>
            </Row>
            <Row className="heading3">
              <Col>Notes</Col>
            </Row>
            <Row>
              <Col>{detail_plan?.notes ?? "-"}</Col>
            </Row>
            <div className="d-flex justify-content-center pt-5">
              <Button
                type="outline-secondary"
                title="Back"
                onClick={() => history.goBack()}
              />
            </div>
          </Col>
        )}
      </OrganismLayout>

      <ModalDelete
        show={show1}
        setShow={setShow1}
        subject="Plan"
        messages="Deleting this data will delete all membership data."
        handleDelete={() => handleDeletePlan(show1?.id)}
      />

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show2?.visible}
        onHide={() => setShow2({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">{show2?.title} Plan</h2>
            <p className="pb-3">
              Please confirm you want to {show2?.title} this plan.
            </p>{" "}
            <div className="b-gray justify-content-center rounded-lg mb-5 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Plan Name</span> <br />
                  {show2?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Plan Type</span> <br />
                  {show2?.plan_type}
                </Col>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow2({ visible: false })}
            />
            <Button
              title={show2?.title}
              type="primary"
              onClick={() => handleDeactivatePlan(show2?.id, show2.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailPlan;
