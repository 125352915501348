import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import { Container, Row, Col, Accordion, Carousel } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import Pagination from "./../../../../components/pagination";
import DropdownCard from "./../../../../components/Button/dropdown.component";

import "./../students.styles.scss";

import {
  HiClock,
  HiUser,
  HiOutlineCalendar,
  HiOutlineCreditCard,
} from "react-icons/hi";
import { FaAngleLeft, FaAngleRight, FaEllipsisH } from "react-icons/fa";
import { useCookies } from "react-cookie";
import {
  getActivePlan,
  getClasses,
  getProfile,
  getUserRole,
} from "../../../../redux/student/student.action";
import AtomLoading from "../../../../components/loding-indicator";
import { getPaymentDetail } from "../../../../redux/owner/owner.action";
import { toast } from "react-toastify";
import { getClientKey } from "../../../../redux/studio/studio.action";
import { changeRole } from "../../../../services/auth";
import {
  setLoginFromStudio,
  setNewUser,
} from "../../../../redux/user/user.action";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";

const StudentDashBoard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [active, setActive] = useState(1);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(["user"]);
  const [status, setStatus] = useState("registered");

  const { client_key, user_studio, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { user_role, profile, activePlan, classes, isLoadingClasses } =
    useSelector((state) => state.student);

  const currentDate = new Date();
  const subdomain = window.location.host.split(".")[0];
  const payment_pending = props.location?.state;
  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;
  const userRoles = user_role.roles
    ? user_role.roles?.filter((item) => item !== role)
    : [];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      preview == false ? setPreview(true) : setPreview(false)
    );

    return (
      <div
        className="custom-hover"
        style={{ cursor: "pointer" }}
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  };

  const data = [
    ...userRoles.map((el) => ({
      name: `View as ${el.replace(/\b\w/g, (l) => l.toUpperCase())}`,
      onClick: () => setRoles(el),
    })),
    {
      name: "Edit Profile",
      href: "/student-profile",
    },
  ];

  const setRoles = async (role) => {
    try {
      const params = {
        role: role,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data));

      // if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      // }
      dispatch(setLoginFromStudio());
      history.push("/dashboard");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const setPending = () => {
    if (payment_pending) setStatus("pending");
  };

  useEffect(() => {
    setPending();
    dispatch(getClientKey(user_token, studio_id));
    dispatch(getUserRole(user_token, studio_id));
    dispatch(getProfile(user_token, studio_id));
    dispatch(getPaymentDetail(user_token, studio_id));
    dispatch(getActivePlan(user_token, studio_id, "active"));
  }, []);

  useEffect(() => {
    dispatch(
      getClasses(
        user_token,
        studio_id,
        status === "past"
          ? `passed=true&page=${active}`
          : `status=${status}&page=${active}`
      )
    );
  }, [status, active]);

  useEffect(() => {
    //change this to the script source you want to load, for example this is snap.js sandbox env
    const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_SNAP_URL;
    //change this according to your client-key
    const myMidtransClientKey = client_key?.client_key;

    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);

    document.body.appendChild(scriptTag);
    console.log("Done appending snap.js");
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [client_key]);

  const continuePayment = (midtrans_token) => {
    window.snap.pay(midtrans_token, {
      onSuccess: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/online-payment-success`;
        console.log(result, "result succes");
      },
      onPending: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/dashboard`;
        console.log(result, "result pending");
      },
      onError: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/dashboard`;
        console.log(result, "result error");
      },
      onClose: function (result) {
        /* You may add your own implementation here */
        window.location.href =
          process.env.REACT_APP_PROTOCOL +
          subdomain +
          `.${process.env.REACT_APP_HOST}/dashboard`;
        console.log(result, "result close");
      },
    });
  };
  console.log("2022-07-12" < moment(currentDate).format("YYYY-MM-DD"), "date");
  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <Header title="Dashboard" />
        {isLoadingClasses ? (
          <AtomLoading />
        ) : (
          <Row style={{ maxWidth: "48rem" }} className="gap-3 mx-auto mt-1">
            <Col className="text-white bg-lightaquamarine py-2 px-4 rounded-lg">
              <div className="d-flex justify-content-between align-items-end">
                <span className="heading3">Welcome</span>
                <div
                  className="position-relative"
                  onClick={() => setOpen((open) => !open)}
                >
                  <FaEllipsisH className="custom-hover pointer" />
                  {open && (
                    <DropdownCard
                      positionRight
                      data={data}
                      className="text-lightaquamarine custom-hover"
                    />
                  )}
                </div>
              </div>
              <span className="heading1">
                {profile?.first_name} {profile?.last_name}
              </span>
              <br />
              <span className="heading3 text-capitalize">as Student</span>
            </Col>
            <Col className="b-gray p-3 rounded-lg bg-white">
              {activePlan.plans?.length === 0 ? (
                <>
                  <p className="text-danger text-center fs-12">
                    You don't have plan yet.
                  </p>
                  <button
                    onClick={() => history.push("/buy-plan")}
                    className="custom-button1 w-100 rounded-3 p-2 fw-bold"
                  >
                    Buy Plan
                  </button>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-between heading3">
                    <p>You have {activePlan.plans?.length} active plan(s) :</p>
                    {/* <a
                      href={`plan-transaction/exampleId/detail`}
                      className="text-decoration-none greyblue custom-hover pointer"
                    >
                      Detail
                    </a> */}
                  </div>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <Carousel
                      as="div"
                      prevIcon={
                        <FaAngleLeft className="greyblue fs-4 pointer" />
                      }
                      nextIcon={
                        <FaAngleRight className="greyblue fs-4 pointer" />
                      }
                    >
                      {activePlan.plans?.map((el, idx) => (
                        <Carousel.Item className="px-5" key={idx}>
                          <b className="fs-12">{el?.plan_name}</b> <br />
                          <span className="fs-10">
                            Valid Date : {el?.expired_date}
                          </span>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </>
              )}
            </Col>
            <p className="my-2 fw-bold">Your Classes</p>
            <div className="d-flex justify-content-between gap-2">
              <div className="d-flex gap-3">
                {[
                  { status: "Upcoming", value: "upcoming" },
                  { status: "Registered", value: "registered" },
                  { status: "Wait List", value: "waiting list" },
                  { status: "Pending", value: "pending" },
                  { status: "Cancelled", value: "cancelled" },
                ].map((item, index) => (
                  <div
                    onClick={() => {
                      setStatus(item?.value);
                      setActive(1);
                    }}
                    key={index}
                    className={`${
                      status === item?.value
                        ? "custom-button2"
                        : "b-gray custom-hover4 pointer"
                    } p-2 heading3 rounded-3`}
                  >
                    {item?.status}
                  </div>
                ))}
              </div>
              <div
                onClick={() => setStatus("past")}
                className={`${
                  status === "past"
                    ? "custom-button2"
                    : "b-gray custom-hover4 pointer"
                } p-2 heading3 rounded-3`}
              >
                Past
              </div>
            </div>
            {isLoadingClasses ? (
              <AtomLoading />
            ) : classes.data?.length === 0 ? (
              <div className="text-center text-danger p-3 bg-white rounded-lg shadow-sm fs-12 mb-5">
                You don't have any classes.
              </div>
            ) : (
              <div>
                <div className="p-3 bg-white rounded-lg shadow-sm mb-5 regular-text">
                  {classes.data?.map((el, idx) => (
                    <Accordion key={idx}>
                      <CustomToggle eventKey="0">
                        <div className="d-flex justify-content-between">
                          <p className="fw-bold">{el?.class_name}</p>
                          <p
                            className={`${
                              el?.status === "registered"
                                ? "bg-registered"
                                : el?.status === "cancelled"
                                ? "bg-cancel"
                                : el?.status === "pending"
                                ? "bg-pending"
                                : el?.status === "waiting list"
                                ? "bg-waitlist"
                                : "bg-cancel"
                            } fs-10 rounded-pill p-1 px-3`}
                          >
                            {el?.status}
                          </p>
                        </div>
                        <Row className="fs-12 mb-3">
                          <Col>
                            <HiOutlineCalendar className="text-pink fs-5" />{" "}
                            {el?.class_start_date}
                          </Col>
                          <Col>
                            <HiClock className="text-pink fs-5" />{" "}
                            {el?.class_start_time}
                          </Col>
                          <Col sm={4}>
                            <HiUser className="text-pink fs-5" />{" "}
                            {el?.teachers.join(", ")}
                          </Col>
                          <Col></Col>
                        </Row>
                      </CustomToggle>
                      <Accordion.Collapse eventKey="0">
                        <>
                          <Row>
                            <Col>
                              <span className="fw-bold">Slots</span>
                              <p>{el?.slots}</p>
                              <span className="fw-bold">Location</span>
                              <p>{el?.location}</p>
                              {el?.is_online === 1 && (
                                <>
                                  <span className="fw-bold">Class Link</span>{" "}
                                  <br />
                                  {el?.class_link === null ? (
                                    <p className="heading3 text-black-50">
                                      "Link will be available 30 minutes before
                                      the class starts"
                                    </p>
                                  ) : (
                                    <a target="_blank" href={el?.class_link}>
                                      {el?.class_link}
                                    </a>
                                  )}
                                </>
                              )}
                            </Col>
                            <Col>
                              <span className="fw-bold">Class</span>
                              <p>
                                {el?.is_online === 1 ? "Online" : "Offline"}
                              </p>
                              <span className="fw-bold">Room</span>
                              <p>{el?.room}</p>
                              {el?.is_online === 1 && (
                                <>
                                  <span className="fw-bold">
                                    Additional Information
                                  </span>
                                  <p
                                    className={`heading3 ${
                                      el?.additional_information === null
                                        ? "text-black-50"
                                        : "text-black"
                                    }`}
                                  >
                                    {el?.additional_information === null
                                      ? '"Additional information will be available 30 minutes before the class starts"'
                                      : el?.additional_information}
                                  </p>
                                </>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <span className="fw-bold">Notes</span>
                            <Col>{el?.notes ?? "-"}</Col>
                          </Row>
                          <div className="d-flex justify-content-center align-items-center pointer gap-3">
                            {status === "past" ||
                            status === "cancelled" ||
                            el?.class_start_date <
                              moment(currentDate).format("YYYY-MM-DD") ? (
                              <p></p>
                            ) : (
                              <p
                                onClick={() =>
                                  history.push({
                                    pathname: "/cancel-class",
                                    state: {
                                      order_id: el?.order_id,
                                      class_name: el?.class_name,
                                      class_date: el?.class_start_date,
                                      class_time: el?.class_start_time,
                                      teachers: el?.teachers.join(","),
                                    },
                                  })
                                }
                                className="custom-form-label mt-3 custom-hover"
                              >
                                Cancel Class
                              </p>
                            )}
                            {el?.status === "pending" &&
                            el.payment?.payment_method === "manual" &&
                            el.payment?.payment_status === "pending" ? (
                              <Link
                                to={{
                                  pathname: "/payment-verification",
                                  state: {
                                    category: el?.category,
                                    id: el?.order_id,
                                    total: el.payment?.total_amount,
                                    order_number: el?.order_number,
                                    bank_image: el?.bank_image,
                                    bank_name:
                                      el.payment?.transfer_to.bank_name,
                                    account_number:
                                      el.payment?.transfer_to
                                        .bank_account_number,
                                    account_holder_name:
                                      el.payment?.transfer_to
                                        .account_holder_name,
                                  },
                                }}
                                className="text-decoration-none custom-form-label text-pink custom-hover d-flex align-items-center"
                              >
                                <HiOutlineCreditCard className="fs-5" /> Confirm
                                Payment
                              </Link>
                            ) : el?.status === "pending" &&
                              el.payment?.payment_method === "online" &&
                              el.payment?.payment_status === "pending" ? (
                              <div
                                onClick={() =>
                                  continuePayment(el?.midtrans_token_id)
                                }
                                className="custom-form-label greyblue custom-hover d-flex align-items-center"
                              >
                                <HiOutlineCreditCard className="fs-5" /> Pay Now
                              </div>
                            ) : null}
                          </div>
                        </>
                      </Accordion.Collapse>
                      <hr />
                    </Accordion>
                  ))}
                  {classes.data?.length > 0 && (
                    <Pagination
                      active={active}
                      setActive={setActive}
                      current_page={classes.meta.pagination?.current_page}
                      total_pages={classes.meta.pagination?.total_pages}
                    />
                  )}
                </div>
              </div>
            )}
          </Row>
        )}
      </div>
      <Footer />
    </Container>
  );
};

export default StudentDashBoard;
