import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import moment from "moment";

import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "../../../../../components/Footer/footer.component";
import Header from "../../../../../components/HeaderOwner/header-owner.component";
import Button from "../../../../../components/Button/button.component";
import TextInput from "../../../../../components/TextInput/text-input.component";
import SelectInput from "../../../../../components/SelectInput/select-input.component";
import DatePicker from "../../../../../components/date-picker";

import {
  addPlan,
  getDetailPlans,
  setStatusDefault,
  updatePlan,
} from "../../../../../redux/owner/owner.action";
import AtomLoading from "../../../../../components/loding-indicator";
import OnboardingStatus from "../../../../../components/onboarding-status";

const ModifyPLan = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = props.location.state?.id;
  const isOnboarding = props.location.state?.isOnboarding;

  const [cookies] = useCookies(["subdomain", "user"]);

  const {
    addPlanStatus,
    isLoadingPlan,
    detail_plan,
    isLoading,
    onboarding_status,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const currentDate = new Date();
  const [selectPlan, setSelectPlan] = useState("membership");
  const [planName, setPlanName] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [addCredit, setAddCredit] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [selectTime, setSelectTime] = useState("valid_date");
  const [startValidDate, setStartValidDate] = useState(currentDate);
  const [endValidDate, setEndValidDate] = useState(currentDate);
  const [notes, setNotes] = useState("");
  const [validFor, setValidFor] = useState("");
  const [duration, setDuration] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = "";
    let finalStartDate = moment(startValidDate).format("YYYY-MM-DD");
    let finalEndDate = moment(endValidDate).format("YYYY-MM-DD");
    if (selectPlan === "membership" && selectTime === "valid_date") {
      params = {
        plan_type: selectPlan,
        plan_name: planName,
        price: price,
        tax: tax,
        time_type: selectTime,
        start_valid_date: finalStartDate,
        end_valid_date: finalEndDate,
        usage_limit: usageLimit,
        notes: notes,
      };
    } else if (selectPlan === "membership" && selectTime === "duration") {
      params = {
        plan_type: selectPlan,
        plan_name: planName,
        price: price,
        tax: tax,
        time_type: selectTime,
        valid_for: validFor,
        duration_type: duration,
        usage_limit: usageLimit,
        notes: notes,
      };
    } else if (selectPlan === "class pass") {
      params = {
        plan_type: selectPlan,
        plan_name: planName,
        price: price,
        tax: tax,
        credits: addCredit,
        valid_for: validFor,
        duration_type: duration,
        usage_limit: usageLimit,
        notes: notes,
      };
    }

    id
      ? dispatch(updatePlan(params, user_token, studio_id, id))
      : dispatch(addPlan(params, user_token, studio_id));
  };

  const handleStartValidDate = (e) => {
    setStartValidDate(e);
    setShow(false);
  };

  const handleEndValidDate = (e) => {
    setEndValidDate(e);
    setShow1(false);
  };

  useEffect(() => {
    if (addPlanStatus === "success") {
      history.push("/setting-plans");
    } else if (addPlanStatus === "success" && isOnboarding) {
      onboarding_status?.term_and_condition === false
        ? history.push({
            pathname: "/setting-terms-and-conditions",
            state: { isOnboarding: true },
          })
        : onboarding_status?.cancellation_setting === false
        ? history.push({
            pathname: "/setting-cancellation",
            state: { isOnboarding: true },
          })
        : onboarding_status?.payment_information === false
        ? history.push({
            pathname: "/setting-payment",
            state: { isOnboarding: true },
          })
        : history.goBack();
    }
    dispatch(setStatusDefault());
  }, [addPlanStatus, isOnboarding]);

  useEffect(() => {
    if (id) {
      dispatch(getDetailPlans(user_token, studio_id, id));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      if (
        detail_plan?.plan_type === "membership" &&
        detail_plan?.time_type === "valid_date"
      ) {
        setSelectPlan(detail_plan?.plan_type);
        setPlanName(detail_plan?.plan_name);
        setPrice(detail_plan?.price);
        setTax(detail_plan?.tax);
        setSelectTime(detail_plan?.time_type);
        setStartValidDate(detail_plan?.start_valid_date);
        setEndValidDate(detail_plan?.end_valid_date);
        setUsageLimit(detail_plan?.usage_limit);
        setNotes(detail_plan?.notes);
      } else if (
        detail_plan?.plan_type === "membership" &&
        detail_plan?.time_type === "duration"
      ) {
        setSelectPlan(detail_plan?.plan_type);
        setPlanName(detail_plan?.plan_name);
        setPrice(detail_plan?.price);
        setTax(detail_plan?.tax);
        setSelectTime(detail_plan?.time_type);
        setDuration(detail_plan?.duration_type);
        setValidFor(detail_plan?.valid_for);
        setUsageLimit(detail_plan?.usage_limit);
        setNotes(detail_plan?.notes);
      } else if (detail_plan?.plan_type === "class pass") {
        setSelectPlan(detail_plan?.plan_type);
        setPlanName(detail_plan?.plan_name);
        setPrice(detail_plan?.price);
        setTax(detail_plan?.tax);
        setSelectTime(detail_plan?.time_type);
        setDuration(detail_plan?.duration_type);
        setValidFor(detail_plan?.valid_for);
        setAddCredit(detail_plan?.credits);
        setNotes(detail_plan?.notes);
      }
    }
  }, [detail_plan]);

  const listDuration = [
    { label: "- Select -", value: "", selected: id ? false : true },
    {
      label: "Day",
      value: "days",
      selected: detail_plan?.duration_type === "days" ? true : false,
    },
    {
      label: "Week",
      value: "weeks",
      selected: detail_plan?.duration_type === "weeks" ? true : false,
    },
    {
      label: "Month",
      value: "months",
      selected: detail_plan?.duration_type === "months" ? true : false,
    },
    {
      label: "Year",
      value: "years",
      selected: detail_plan?.duration_type === "years" ? true : false,
    },
  ];

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <Header title={id ? "Edit PLan" : "Add Plan"} />
      {isLoading ? (
        <AtomLoading />
      ) : (
        <>
          {isOnboarding && <OnboardingStatus selectedStep={1} activeStep={4} />}
          <Row
            style={{ transform: "translateY(-77px)" }}
            className="justify-content-md-center mb-5"
          >
            <Col lg={5}>
              <div
                className="custom-card align-items-center"
                style={{
                  paddingLeft: 100,
                  paddingRight: 100,
                  paddingTop: 20,
                  paddingBottom: 50,
                }}
              >
                <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Form.Group style={{ marginBottom: 10 }}>
                    <Row>
                      <>
                        <Col>
                          <Form.Check
                            id="membership"
                            type="radio"
                            label="Membership"
                            value="membership"
                            name="plan"
                            onChange={(e) => setSelectPlan(e.target.value)}
                            checked={selectPlan === "membership"}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            id="classpass"
                            type="radio"
                            value="class pass"
                            label="Class Pass"
                            name="plan"
                            onChange={(e) => setSelectPlan(e.target.value)}
                            checked={selectPlan === "class pass"}
                          />
                        </Col>
                      </>
                    </Row>
                  </Form.Group>

                  {/* formMembership */}
                  {selectPlan === "membership" ? (
                    <>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Name"
                          placeholder="1 Month Premium class"
                          value={planName}
                          onChange={(event) => {
                            setPlanName(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Price (exclude taxes)"
                          placeholder="1.000.000"
                          value={price}
                          onChange={(event) => {
                            setPrice(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Tax (%)"
                          placeholder="10"
                          value={tax}
                          onChange={(event) => {
                            setTax(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <Row>
                          <>
                            <Col>
                              <Form.Check
                                type="radio"
                                label="Valid Date"
                                value="valid_date"
                                name="time"
                                id="time1"
                                onChange={(e) => setSelectTime(e.target.value)}
                                checked={selectTime === "valid_date"}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                type="radio"
                                label="Duration"
                                value="duration"
                                name="time"
                                id="time2"
                                onChange={(e) => setSelectTime(e.target.value)}
                                checked={selectTime === "duration"}
                              />
                            </Col>
                          </>
                        </Row>
                      </Form.Group>

                      {selectTime === "valid_date" ? (
                        <>
                          <Form.Group style={{ marginBottom: 10 }}>
                            <Row>
                              <>
                                <Col>
                                  <DatePicker
                                    title="Start Date"
                                    currentDate={currentDate}
                                    selectedDate={startValidDate}
                                    show={show}
                                    setShow={setShow}
                                    setDate={handleStartValidDate}
                                  />
                                </Col>
                                <Col>
                                  <DatePicker
                                    title="End Date"
                                    currentDate={currentDate}
                                    selectedDate={endValidDate}
                                    show={show1}
                                    setShow={setShow1}
                                    setDate={handleEndValidDate}
                                  />
                                </Col>
                              </>
                            </Row>
                          </Form.Group>
                          <Form.Group style={{ marginBottom: 10 }}>
                            <TextInput
                              required
                              label="Usage Limit"
                              value={usageLimit}
                              placeholder="100"
                              onChange={(event) => {
                                setUsageLimit(event.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group style={{ marginBottom: 10 }}>
                            <TextInput
                              label="Notes"
                              value={notes}
                              placeholder="Input Notes..."
                              onChange={(event) => {
                                setNotes(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </>
                      ) : (
                        // endformmembership

                        <>
                          <Form.Group style={{ marginBottom: 10 }}>
                            <Row>
                              <>
                                <Col>
                                  <TextInput
                                    required
                                    label="Valid for"
                                    value={validFor}
                                    placeholder="3"
                                    onChange={(event) => {
                                      setValidFor(event.target.value);
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <div className={`form-group`}>
                                    <SelectInput
                                      required
                                      label="Duration"
                                      data={listDuration}
                                      onChange={(event) => {
                                        setDuration(event.target.value);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </>
                            </Row>
                          </Form.Group>
                          <Form.Group style={{ marginBottom: 10 }}>
                            <TextInput
                              required
                              label="Usage Limit"
                              value={usageLimit}
                              placeholder="100"
                              onChange={(event) => {
                                setUsageLimit(event.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group style={{ marginBottom: 10 }}>
                            <TextInput
                              label="Notes"
                              value={notes}
                              placeholder="Input Notes..."
                              onChange={(event) => {
                                setNotes(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Name"
                          placeholder="10 Credits Class"
                          value={planName}
                          onChange={(event) => {
                            setPlanName(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Price (exclude taxes)"
                          placeholder="1.000.000"
                          value={price}
                          onChange={(event) => {
                            setPrice(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Tax (%)"
                          placeholder="10"
                          value={tax}
                          onChange={(event) => {
                            setTax(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          required
                          label="Add Credits"
                          placeholder="30"
                          value={addCredit}
                          onChange={(event) => {
                            setAddCredit(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <Row>
                          <>
                            <Col>
                              <TextInput
                                required
                                label="Valid for"
                                value={validFor}
                                placeholder="3"
                                onChange={(event) => {
                                  setValidFor(event.target.value);
                                }}
                              />
                            </Col>
                            <Col>
                              <div className={`form-group`}>
                                <SelectInput
                                  required
                                  label="Duration"
                                  data={listDuration}
                                  onChange={(event) => {
                                    setDuration(event.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                          </>
                        </Row>
                      </Form.Group>
                      <Form.Group style={{ marginBottom: 10 }}>
                        <TextInput
                          label="Notes"
                          value={notes}
                          placeholder="Input Notes..."
                          onChange={(event) => {
                            setNotes(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </>
                  )}
                  <Row style={{ marginTop: 20 }}>
                    <Col>
                      <Button
                        type={"outline-secondary"}
                        title={isOnboarding ? "Skip for Now" : "Cancel"}
                        onClick={() => {
                          if (isOnboarding) {
                            onboarding_status?.term_and_condition === false
                              ? history.push({
                                  pathname: "/setting-terms-and-conditions",
                                  state: { isOnboarding: true },
                                })
                              : onboarding_status?.cancellation_setting ===
                                false
                              ? history.push({
                                  pathname: "/setting-cancellation",
                                  state: { isOnboarding: true },
                                })
                              : onboarding_status?.payment_information === false
                              ? history.push({
                                  pathname: "/setting-payment",
                                  state: { isOnboarding: true },
                                })
                              : history.goBack();
                          } else {
                            history.goBack();
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        buttonType="submit"
                        isLoading={isLoadingPlan}
                        type="primary"
                        title="CONTINUE"
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </>
      )}
      <Footer />
    </Container>
  );
};

export default ModifyPLan;
