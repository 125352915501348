import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./owner.styles.scss";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
} from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";

import AddStudent from "./add-student-component";
import AddTeacher from "./add-teacher-component";
import AddSchedule from "./add-schedule-component";
import AddPlan from "./add-plan-component";

import { Stepper } from "react-form-stepper";
import OnboardingStatus from "../../components/onboarding-status";
import SettingPayment from "./payment";
import { Cancellation } from "./cancellation";
import { TermsAndConditions } from "./terms-and-conditions";
import { useCookies } from "react-cookie";
import {
  getOnboardingStatus,
  getTeacher,
} from "../../redux/owner/owner.action";

const AddClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stepFromDashboard = props.location.state?.stepFromDashboard;

  const [cookies, setCookies] = useCookies(["user"]);
  const [step, setStep] = useState(1);
  const [selectedStep, setSelectedStep] = useState(1);
  const [prevStep, setPrevStep] = useState();

  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { isRegisterStudent } = useSelector((state) => state.studio);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const nextStep = () => {
    setStep(step + 1);
    setPrevStep(step > 2 ? step - 1 : step);
  };

  const nextSelectedStep = () => {
    setSelectedStep(2);
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return <AddStudent nextStep={nextStep} />;
      case 2:
        return <AddTeacher nextStep={nextStep} setSelectedStep={setStep} />;
      case 3:
        return <AddSchedule nextStep={nextStep} setSelectedStep={setStep} />;
      case 4:
        return (
          <AddPlan nextStep={nextStep} setSelectedStep={nextSelectedStep} />
        );
      case 5:
        return <TermsAndConditions nextStep={nextStep} />;
      case 6:
        return <Cancellation nextStep={nextStep} />;
      case 7:
        return <SettingPayment nextStep={nextStep} />;
      default:
        break;
    }
  };

  const setHeader = () => {
    switch (step) {
      case 1:
        return <Header title="Add Your First Student" />;
      case 2:
        return <Header title="Add Your First Teacher" />;
      case 3:
        return <Header title="Scheduling Your First Class" />;
      case 4:
        return <Header title="Add Plan" />;
      case 5:
        return <Header title="Add Terms and Conditions" />;
      case 6:
        return <Header title="Set Cancellation Settings" />;
      case 7:
        return <Header title="Add Payment Information" />;
      default:
        break;
    }
  };
  useEffect(() => {
    dispatch(getOnboardingStatus(user_token, studio_id));
    dispatch(getTeacher(user_token, studio_id, 1));
  }, [step]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        {setHeader()}
        <OnboardingStatus
          onboarding={true}
          setSelectedStep={setStep}
          selectedStep={selectedStep}
          prevStep={prevStep}
          activeStep={step}
        />
        <Row style={{ transform: "translateY(-77px)" }}>{renderSteps()}</Row>
      </div>
      <Footer />
    </Container>
  );
};

export default AddClass;
