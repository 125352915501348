import axiosInstance from "../axiosInstance";

export const sendActivities = async (params, token_jwt, studio_id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post("/classes/invitation", params, config);

  return res;
};

export const getSchedules = async (token_jwt, studio_id, id, page) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `classes/${id}/schedules?page=${page}`,
    config
  );

  return res;
};

export const addSession = async (token_jwt, studio_id, params, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post(`/class-session/${id}`, params, config);

  return res;
};

export const editSession = async (token_jwt, studio_id, params, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.put(`/class-session/${id}`, params, config);

  return res;
};

export const deleteSession = async (token_jwt, studio_id, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.delete(`/class-session/${id}`, config);

  return res;
};
