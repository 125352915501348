import React, { useEffect, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import AtomLoading from "../../../../components/loding-indicator";
import AtomNumberFormat from "../../../../components/number-format";

import "./../students.styles.scss";
import {
  HiArrowCircleRight,
  HiArrowCircleDown,
  HiArrowRight,
  HiArrowLeft,
  HiClock,
  HiUser,
} from "react-icons/hi";
import { getPublicListOfferingClasses } from "../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";
import DatePicker from "../../../../components/date-picker";
import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const PublicClassList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [active, setActive] = useState("");
  const [show, setShow] = useState(false);

  const { public_classes, isLoadingClasses } = useSelector(
    (state) => state.student
  );

  const subdomain = window.location.host.split(".")[0];

  const setDate = (e) => {
    setSelectedDate(e);
    setShow(false);
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      active === eventKey ? setActive("") : setActive(eventKey)
    );

    return (
      <div style={{ cursor: "pointer" }} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  const redirectToLogin = () => {
    history.push("/studio-login");
    toast.info("Please Register/Login to Continue.");
  };

  useEffect(() => {
    dispatch(
      getPublicListOfferingClasses(
        subdomain,
        moment(selectedDate).format("YYYY-MM-DD")
      )
    );
  }, [selectedDate]);

  return (
    <OrganismLayout
      isBrowseClasses={true}
      title="Class List"
      maxWidth="42rem"
    >
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <Col className="mx-auto p-3" style={{ maxWidth: "32rem" }}>
          <h1 className="heading1 text-center mb-5">Class Offerings</h1>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <DatePicker
              title="Start Date"
              width="10rem"
              currentDate={currentDate}
              selectedDate={new Date(selectedDate)}
              show={show}
              setShow={setShow}
              setDate={setDate}
            />

            <div className="d-flex gap-2">
              <button
                disabled={
                  moment(selectedDate).format("DD") <=
                  moment(currentDate).format("DD")
                }
                onClick={() =>
                  setSelectedDate(moment(selectedDate).subtract(6, "d"))
                }
                className="custom-button1 p-1 rounded-3 fs-4 d-flex align-items-center"
              >
                <HiArrowLeft />
              </button>
              <button
                onClick={() => setSelectedDate(currentDate)}
                className="custom-button3 rounded-3 py-2 px-4"
              >
                <span>Today</span>
              </button>
              <div
                onClick={() =>
                  setSelectedDate(moment(selectedDate).add(6, "d"))
                }
                className="custom-button1 p-1 rounded-3 fs-4 d-flex align-items-center"
              >
                <HiArrowRight />
              </div>
            </div>
          </div>
          <div className="text-center mb-4">
            <p className="fs-12">
              Class Available Dates : <br />{" "}
              <span className="fs-6">
                {moment(selectedDate).format("DD/MM/YYYY")} -{" "}
                {moment(selectedDate).add(6, "d").format("DD/MM/YYYY")}
              </span>
            </p>
          </div>
          {public_classes?.length > 0 ? (
            public_classes.map((data, idx) => (
              <div key={idx} className="">
                <div className="text-center custom-button w-100 p-2 fs-12 mb-3">
                  {moment(data?.class_date).format("DD/MM/YYYY")}
                </div>
                <Accordion>
                  {public_classes &&
                    public_classes
                      .filter(
                        (item) =>
                          item.class_schedules[0].class_date ===
                          data?.class_date
                      )
                      .map((element) =>
                        element.class_schedules.map((el, i) => (
                          <div key={i} className="mb-3 b-gray rounded-10 p-3">
                            <div className="fs-12 d-flex justify-content-between mb-2">
                              <span>
                                {el?.is_online === 1 ? "Online" : "Offline"}
                              </span>
                              <span>
                                <HiClock className="fs-5 text-pink" />{" "}
                                {el?.class_start_time} - {el?.class_end_time}
                              </span>
                              <span>
                                <HiUser className="fs-5 text-pink" />{" "}
                                {el?.teachers.join(",")}
                              </span>
                              <span className="fs-2">
                                <CustomToggle eventKey={i}>
                                  {active === i ? (
                                    <HiArrowCircleDown className="greyblue custom-hover" />
                                  ) : (
                                    <HiArrowCircleRight className="text-black-50 custom-hover" />
                                  )}
                                </CustomToggle>
                              </span>
                            </div>
                            <span className="fw-bold">{el?.class_name}</span>
                            <Accordion.Collapse eventKey={i}>
                              <>
                                <hr />
                                <Row>
                                  <Col className="fw-bold">
                                    <p>Schedule</p>
                                  </Col>
                                  <Col className="fs-12" xs={9}>
                                    {el?.class_date}, {el?.class_start_time} -{" "}
                                    {el?.class_end_time}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="fw-bold">
                                    <p>Slots</p>
                                  </Col>
                                  <Col className="fs-12" xs={9}>
                                    {el?.slots} Remaining
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="fw-bold">
                                    <p>Credits</p>
                                  </Col>
                                  <Col className="fs-12" xs={9}>
                                    {el?.credits}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="fw-bold">
                                    <p>Price</p>
                                  </Col>
                                  <Col className="fs-12" xs={9}>
                                    <AtomNumberFormat
                                      prefix="IDR "
                                      value={el?.total_amount}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="fw-bold">
                                    <p>Notes</p>
                                  </Col>
                                  <Col className="fs-12" xs={9}>
                                    {el?.notes}
                                  </Col>
                                </Row>
                                <div className="d-flex justify-content-center m-4">
                                  <Button
                                    onClick={redirectToLogin}
                                    type="primary"
                                    title="ENROLL NOW"
                                  />
                                </div>
                              </>
                            </Accordion.Collapse>
                          </div>
                        ))
                      )}
                </Accordion>
              </div>
            ))
          ) : (
            <EmptyData />
          )}
        </Col>
      )}
    </OrganismLayout>
  );
};

export default PublicClassList;
