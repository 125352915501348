import React from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import SelectInput from "../../../../components/SelectInput/select-input.component";

const StudentModal = ({
  category,
  title,
  data,
  status,
  setShow,
  currentStatus,
  onChange,
  handleSubmit,
}) => {
  return (
    <Modal
      centered
      contentClassName="rounded-lg"
      show={data?.visible}
      onHide={() => setShow()}
    >
      <div className="mx-5 p-3">
        <div className="text-center">
          <h2 className="heading1 mb-3">{title}</h2>
          <p>Please confirm you want to change this status.</p>
          <p>
            <b>From</b>
          </p>
          <div className="b-gray justify-content-center rounded-lg mb-5 p-3">
            <Row className="mb-3">
              <Col>
                <span className="lightaquamarine">Class Name</span> <br />
                {data?.name}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="lightaquamarine">Invoice</span> <br />{" "}
                {data?.invoice}
              </Col>
              <Col>
                <span className="lightaquamarine text-capitalize">
                  Current Status
                </span>{" "}
                <br />
                {currentStatus}
              </Col>
            </Row>
          </div>
          <p>
            <b>To</b>
          </p>
        </div>
        <Form onSubmit={handleSubmit}>
          {currentStatus === "canceled" || currentStatus === "refunded" ? (
            <div className="text-center mb-4">
              <em className="custom-form-label">
                You cannot change the payment status further
              </em>
            </div>
          ) : data?.category === "edit-plan-status" &&
            currentStatus === "active" ||
            currentStatus === "pending" ? (
            <div className="text-center mb-4">
              <em className="custom-form-label">
                You cannot change the status further
              </em>
            </div>
          ) : (
            <div className="b-gray py-4 px-5 rounded-lg mb-5">
              <SelectInput required data={status} onChange={onChange} />
            </div>
          )}
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow()}
            />
            <Button
              isLoading={
                currentStatus === "canceled" || currentStatus === "refunded"
              }
              buttonType="submit"
              title="Save"
              type="primary"
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default StudentModal;
