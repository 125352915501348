import React from "react";
import NoDataImg from "../assets/images/no-data.png"

const EmptyData = () => {
  return (
    <div
      // style={{ height: "75px" }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <img width={50} src={NoDataImg} alt="empty" />
      <span className="heading3">No Data</span>
    </div>
  );
};

export default EmptyData;
