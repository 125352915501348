import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
  Vi,
} from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import HeaderOwner from "./../../../../components/HeaderOwner/header-owner.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import {
  addStudent,
  getStudentDetail,
  updateStudent,
  setStatusDefault,
  addStudentNotes,
  getClass,
  updateStudentNotes,
} from "../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import SelectInput from "../../../../components/SelectInput/select-input.component";
import HeaderTeacher from "../../../../components/HeaderTeacher";
import OrganismLayout from "../../../../components/Layout/organism-layout";

export const AddNotes = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const student_id = props.location.state?.id;
  const notes_id = props.location.state?.notes_id;
  const class_id = props.location.state?.class_id;
  const state_title = props.location.state?.title;
  const state_description = props.location.state?.description;

  const isCreating = window.location.pathname.includes("add") ? true : false;

  const [cookies, setCookies] = useCookies(["user"]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [classId, setClassId] = useState("");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { owner_classes, isLoadingStudent, addNoteStatus } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const list_class = owner_classes.data?.length > 0 ? owner_classes.data : [];
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      title: title,
      description: description,
      class_id: isCreating ? classId.split(",")[0] : class_id,
    };

    isCreating
      ? dispatch(addStudentNotes(params, user_token, studio_id, student_id))
      : dispatch(updateStudentNotes(params, user_token, studio_id, notes_id));
  };

  useEffect(() => {
    dispatch(getClass(user_token, studio_id));
  }, []);

  useEffect(() => {
    if (!isCreating) {
      setTitle(state_title);
      setDescription(state_description);
      setClassId(class_id);
    }
  }, [!isCreating]);

  useEffect(() => {
    if (addNoteStatus === "success") {
      history.goBack();
      dispatch(setStatusDefault());
    }
  }, [addNoteStatus]);

  const classes = [
    { label: "Choose Your Class", value: "", selected: true },
    ...list_class,
  ];

  return (
    <OrganismLayout title="Add Notes" maxWidth="34rem">
      {false ? (
        <AtomLoading />
      ) : (
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Form.Group style={{ marginBottom: 10 }}>
            <div className={`form-group`}>
              {/* <label className="custom-form-label">{label}</label> */}
              <select
                onChange={(e) => setClassId(e.target.value)}
                className="select-input p-2"
                required
              >
                {classes
                  ? classes.map((element, idx) => (
                      <option
                        key={idx}
                        selected={
                          isCreating
                            ? element.selected
                            : element.id === class_id
                        }
                        value={
                          element.id
                            ? element?.id.concat(",", element?.bank_name)
                            : element.value
                        }
                      >
                        {element.label ?? element.class_name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            {/* <SelectInput
                      required
                      data={classes}
                      onChange={(e) => setClassId(e.target.value)}
                    /> */}
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              autoFocus
              label="Title"
              placeholder="Input Title..."
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <label htmlFor="desc" className="custom-form-label">
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              required
              className="text-input"
              name="desc"
              id="desc"
              cols="30"
              rows="10"
              placeholder="Input Description..."
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Form.Group>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              type="outline-secondary"
              title="Cancel"
              onClick={() => history.goBack()}
            />
            <Button
              buttonType="submit"
              type="primary"
              title={isCreating ? "ADD" : "UPDATE"}
              isLoading={isLoadingStudent}
            />
          </div>
        </Form>
      )}
    </OrganismLayout>
  );
};
