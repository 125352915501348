import React from "react";
import { Container } from "react-bootstrap";
import {
  HiArrowLeft,
  HiCalendar,
  HiClock,
  HiUser,
  HiUserGroup,
} from "react-icons/hi";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import HeaderTeacher from "../../../../components/HeaderTeacher";
import AtomNumberFormat from "../../../../components/number-format";
import SelectInput from "../../../../components/SelectInput/select-input.component";

const DashboardClassDetail = () => {
  const history = useHistory();

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <HeaderTeacher title="Class Detail" />
      <div
        style={{
          minHeight: "100vh",
          maxWidth: "48rem",
          transform: "translateY(-70px)",
        }}
        className="mx-auto"
      >
        <div className="">
          <div className="d-flex align-items-center text-white gap-2 mb-1">
            <HiArrowLeft onClick={() => history.goBack()} className="pointer custom-hover" />
            Back
          </div>
        </div>
        <div className="bg-white p-3 rounded-10">
          <b>class name</b>
          <div className="d-flex gap-3">
            <span>
              <HiCalendar className="text-pink" />{" "}
              {/* {el?.class_start_time} - {el?.class_end_time} */} class time
            </span>
            <span>
              <HiClock className="text-pink" />{" "}
              {/* {el?.class_start_time} - {el?.class_end_time} */} class time
            </span>
            <span>
              <HiUser className="text-pink" /> {/* {el?.teachers.join(",")} */}{" "}
              teachers
            </span>
            <span>
              <HiUserGroup className="text-pink" />{" "}
              {/* {el?.class_start_time} - {el?.class_end_time} */} class time
            </span>
          </div>
          <b>Slots</b>
          <p>slots</p>
          <b>Notes</b>
          <p>Notes</p>
        </div>
        <div className="d-flex justify-content-end py-3">
          {/* <Button title="Print Class List" type="outline-secondary" /> */}
        </div>
        <div className="bg-white p-3 rounded-10">
          <div className="">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <b>Stundet Name</b>
                <span>Booked with : -</span>
                <AtomNumberFormat prefix="IDR " value={55555} />
              </div>
              <div className="w-25">
                <SelectInput />
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default DashboardClassDetail;
