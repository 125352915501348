import React from "react";
import "./button.styles.scss";
import { Spinner } from 'react-bootstrap'

const Button = ({title, onClick, type, style, className, icon, isLoading,buttonType}) => {
    return (
        <button
            type={buttonType !== 'submit' ? 'button' : 'submit'}         
            disabled={isLoading}
            onClick={onClick} 
            className={`${isLoading && 'bg-secondary text-white disabled'} button ${className} ${type}`} 
            style={style}>
                {icon && !isLoading ? <img src={icon} style={{height: '25px', width: '25px', marginRight: '10px'}} /> : null}
                {title}
        </button>
    )
}

export default Button;