import { Row, Col, Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/user/user.action";
import logo from "./../../assets/images/bgheader.png";
import "./header.styles.scss";

const Header = ({ title, withLogout, isBrowseClasses, mainPage }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies, removeCookies] = useCookies(["user"]);

  const { user } = useSelector((state) => state.user);

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }
    dispatch(logout(user?.token));
    history.push("/login");
  };

  return (
    <Row>
      <Col lg={12} className="p-0">
        <div className="header d-flex align-items-center justify-content-between px-4">
          <div style={{ width: "70px" }}></div>
          <h2 className="heading2 m-0 text-white">GIGS CLASSROOM</h2>
          {withLogout ? (
            <span
              className="heading2 m-0 text-white pointer"
              onClick={() => {
                handleLogout();
              }}
            >
              Log Out
            </span>
          ) : isBrowseClasses ? (
            <Nav.Link
              className="heading2 m-0 text-white"
              href={mainPage ? "/login" : "/studio-login"}
            >
              Login
            </Nav.Link>
          ) : (
            <div style={{ width: "70px" }}></div>
          )}
        </div>
        <div className="subheader d-flex justify-content-center p-4 position-relative">
          <h2 className="heading1 m-0 text-white">{title}</h2>
          <img
            src={logo}
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Header;
