import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./register.styles.scss";
import logo from "./../../assets/images/swift_logo.png";
import registerImage1 from "./../../assets/images/register-studio.png";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import Header from "../../components/Header/header.component";

import {
  registerStudent,
  setIsRegisterStudent,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { toast } from "react-toastify";
import { requestOtpActivate, setLoginFromStudio, setNewUser } from "../../redux/user/user.action";
import { ModalLogin } from "../StudioLogin/modal";

const RegisterStudent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { login_email } = useSelector((state) => state.user);
  const { isLoading, status, user_studio } = useSelector(
    (state) => state.studio
  );

  const [email, setEmail] = useState(login_email);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);

  const subdomain = window.location.host.split(".")[0];

  const sendRequestOtpActivate = () => {
    let params = {
      email: email,
      role: "student",
    };

    dispatch(requestOtpActivate(params, subdomain));
    history.push({
      pathname: "/verify",
      state: { isReactivate: true, emailFromRegister: email },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
    };

    dispatch(registerStudent(params, subdomain));
  };

  useEffect(() => {
    if (user_studio.data?.token) {
      dispatch(setNewUser(user_studio?.data));
      dispatch(setLoginFromStudio());
      dispatch(setStatusDefault());
      history.push("/dashboard");
    } else if (user_studio?.is_account_active === 0) {
      setShowModalReactivateStudent(true);
    }
  }, [user_studio]);

  return (
    <Container fluid className="bg-light">
      <Form onSubmit={handleSubmit}>
        <Row
          style={{ minHeight: "100vh" }}
          className="justify-content-md-center p-3"
        >
          <div className="text-center mb-3">
            <img width={100} src={logo} alt="logo" /> <br />
            <b className="greyblue text-capitalize">{subdomain}</b>
          </div>

          <Col lg={5} className="me-5">
            <h1 className="heading1">Register</h1>
            <img src={registerImage1} />
          </Col>
          <Col lg={3}>
            <div className="mb-5"></div>
            <TextInput
              inputClassName="bg-light"
              required
              label="Your Email"
              placeholder="johndoe@mail.com"
              className="mb-2"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextInput
              inputClassName="bg-light"
              required
              label="First Name"
              placeholder="John"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
            <TextInput
              inputClassName="bg-light"
              label="Last Name"
              placeholder="Doe"
              className="mt-2"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
            <TextInput
              inputClassName="bg-light"
              label="Phone Number"
              placeholder="Input Phone Number..."
              className="mt-2"
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
            />
            <span className="mt-3 regular-text">
              By clicking `Submit` you are in agreement with the <br />
              <a
                target="_blank"
                href="/general-terms"
                className="fw-bold text-black custom-hover"
              >
                general terms.
              </a>
            </span>
            <div className="d-flex flex-row-reverse">
              <Button
                buttonType="submit"
                isLoading={isLoading}
                type="primary"
                title="Submit"
                className="mt-3"
              />
            </div>
          </Col>
        </Row>
      </Form>
      <Footer />

      <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      />
    </Container>
  );
};

export default RegisterStudent;
