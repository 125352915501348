import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Modal, Image, Table } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import HeaderOwner from "../../../../components/HeaderOwner/header-owner.component";
import AtomLoading from "../../../../components/loding-indicator";
import TextInput from "../../../../components/TextInput/text-input.component";
import DropdownCard from "../../../../components/Button/dropdown.component";
import Pagination from "../../../../components/pagination";
// import "./../students.styles.scss";
import sampleImg from "./../../../../assets/images/placeholder.png";
import { HiSearch, HiOutlineDownload } from "react-icons/hi";
import { useCookies } from "react-cookie";

import "react-calendar/dist/Calendar.css";
import {
  cancelClasses,
  getStudentClass,
  getStudentDetail,
  getStudentNotes,
  getStudentOrder,
  getStudentPlan,
  updatePaymentStatus,
  updateStatusAttended,
} from "../../../../redux/owner/owner.action";
import SelectInput from "../../../../components/SelectInput/select-input.component";
import StudentModal from "./student-modal";
import { deactivatePlan } from "../../../../redux/student/student.action";
import EmptyData from "../../../../components/no-data";
import HeaderTeacher from "../../../../components/HeaderTeacher";
import axiosInstance from "../../../../axiosInstance";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import ModalDelete from "../../../../components/Modal/delete";
import { useOutsideOnclick } from "../../../../services/clickOutside";
import { saveAs } from "file-saver";

const DetailStudent = (props) => {
  console.log(props.location.pathname.split("/")[2],'props')
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user"]);
  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [active2, setActive2] = useState(1);
  const [active3, setActive3] = useState(1);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [isAttended, setIsAttended] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isclick, setIsClick] = useState("class_history");

  const {
    student_detail,
    student_class,
    student_plan,
    student_order,
    student_notes,
    isLoadingStudent,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const id = props.location.pathname.split("/")[2];
  const setOrder = props.location.state?.setOrder;
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const extraParams = `page=${
    isclick === "class_history"
      ? active
      : isclick === "order_history"
      ? active1
      : isclick === "plan_history"
      ? active2
      : isclick === "notes" && active3
  }&${
    isclick === "order_history" ? "payment_status" : "status"
  }=${filterStatus}&search=${search}`;

  const tableHead =
    isclick === "class_history"
      ? [
          "",
          "Class Schedule",
          "Class Name",
          "Invoice",
          "Booking Status",
          "Attended Status",
        ]
      : isclick === "order_history"
      ? [
          "",
          "Transaction Date",
          "Invoice",
          "Category",
          "Name",
          "Payment",
          "Bank",
          "Total Price",
          "Payment Status",
          "Transfer Receipt",
        ]
      : isclick === "plans_history"
      ? [
          "",
          "Plan Name",
          "Category",
          "Credits Used",
          "Invoice",
          "Transaction Date",
          "Expired Date",
          "Plan Status",
        ]
      : ["", "Created Date", "Title", "Description", "Class Name", "Added By"];

  const attendedStatus = [
    { label: "Select Status", value: "", selected: true },
    { label: "Attended", value: 1, selected: false },
    { label: "Unattended", value: 0, selected: false },
  ];

  const payment_status =
    show6?.payment_status === "pending"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "payment verification"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "settlement"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "refund request" && [
          { label: "Select Status", value: "", selected: true },
          { label: "Refunded", value: "refunded", selected: false },
        ];

  // useOutsideOnclick(wrapperRef, setShow);
  // useOutsideOnclick(wrapperRef, setShow1);
  // useOutsideOnclick(wrapperRef, setShow2);
  // useOutsideOnclick(wrapperRef, setShow3);
  // useOutsideOnclick(wrapperRef, setShow4);

  const setOrderHistory = () => {
    if (setOrder) setIsClick("order_history");
  };

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const downloadTransferReceipt = (img_url) => {
    try {
      saveAs(img_url, "Transfer Receipt");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleDeleteStudent = async () => {
    try {
      await axiosInstance.delete(`/students/${show8?.id}`, config);

      history.goBack();
      setShow1({ visible: false });
      toast.success("Student account is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleDeleteNotes = async () => {
    try {
      await axiosInstance.delete(`/students/notes/${show9?.id}`, config);

      setShow1({ visible: false });
      setShow9({ visible: false });
      dispatch(getStudentNotes(user_token, studio_id, id, extraParams));
      toast.success("Student notes is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const updateClassAttendance = (e) => {
    e.preventDefault();
    let params = {
      order_id: show4?.id,
      is_attended: isAttended,
    };

    dispatch(updateStatusAttended(user_token, studio_id, params));
    dispatch(getStudentClass(user_token, studio_id, id, extraParams));

    setShow4({ visible: false });
    setShow1(false);
  };

  const cancelClass = (class_order_number) => {
    let params = {
      payment_status: "cancelled",
    };

    dispatch(
      updatePaymentStatus(user_token, studio_id, params, class_order_number)
    );
    dispatch(getStudentClass(user_token, studio_id, id, extraParams));

    setShow1(false);
    setShow5({ visible: false });
  };

  const updateStatusPayment = (e) => {
    e.preventDefault();
    let params = {
      payment_status: paymentStatus,
    };

    dispatch(updatePaymentStatus(user_token, studio_id, params, show6?.id));
    dispatch(getStudentOrder(user_token, studio_id, id, extraParams));

    setShow6({ visible: false });
    setShow1(false);
  };

  const handleDeactivatePlan = () => {
    let params = {
      message_deactived: "request by owner",
    };

    dispatch(deactivatePlan(user_token, studio_id, params, show7?.id));
    dispatch(getStudentPlan(user_token, studio_id, id, extraParams));

    setShow7({ visible: false });
    setShow1(false);
  };

  const truncateString = (str, num) => {
    if (str === null) {
      return "";
    } else {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "..";
    }
  };

  const SummonPagination = () => {
    if (student_class.data?.length > 0 && isclick === "class_history") {
      return (
        <Pagination
          className="py-4"
          active={active}
          setActive={setActive}
          current_page={student_class.meta.pagination?.current_page}
          total_pages={student_class.meta.pagination?.total_pages}
        />
      );
    } else if (student_order.data?.length > 0 && isclick === "order_history") {
      return (
        <Pagination
          className="py-3"
          active={active1}
          setActive={setActive1}
          current_page={student_order.meta.pagination?.current_page}
          total_pages={student_order.meta.pagination?.total_pages}
        />
      );
    } else if (student_plan.data?.length > 0 && isclick === "plans_history") {
      return (
        <Pagination
          className="py-3"
          active={active2}
          setActive={setActive2}
          current_page={student_plan.meta.pagination?.current_page}
          total_pages={student_plan.meta.pagination?.total_pages}
        />
      );
    } else if (student_notes.data?.length > 0 && isclick === "notes") {
      return (
        <Pagination
          className="py-3"
          active={active3}
          setActive={setActive3}
          current_page={student_notes.meta.pagination?.current_page}
          total_pages={student_notes.meta.pagination?.total_pages}
        />
      );
    }
  };

  const SummonSelectInput = () => {
    if (isclick === "class_history") {
      return (
        <SelectInput
          data={[
            { label: "Select Status", value: "", selected: true },
            { label: "Registered", value: "registered", selected: false },
            { label: "Waiting List", value: "waiting list", selected: false },
            { label: "Pending", value: "pending", selected: false },
            {
              label: "Cancel Request",
              value: "cancel request",
              selected: false,
            },
            { label: "Cancelled", value: "cancelled", selected: false },
          ]}
          selectClassName="bg-light"
          onChange={(event) => {
            setFilterStatus(event.target.value);
          }}
        />
      );
    } else if (isclick === "order_history") {
      return (
        <SelectInput
          data={status}
          selectClassName="bg-light"
          onChange={(event) => {
            setFilterStatus(event.target.value);
          }}
        />
      );
    } else if (isclick === "plans_history") {
      return (
        <SelectInput
          data={status}
          selectClassName="bg-light"
          onChange={(event) => {
            setFilterStatus(event.target.value);
          }}
        />
      );
    } else if (isclick === "notes") {
      return (
        <SelectInput
          data={status}
          selectClassName="bg-light"
          onChange={(event) => {
            setFilterStatus(event.target.value);
          }}
        />
      );
    }
  };

  useEffect(() => {
    const data_status =
      isclick === "class_history"
        ? [
            { label: "Select Status", value: "", selected: true },
            { label: "Registered", value: "registered", selected: false },
            { label: "Waiting List", value: "waiting list", selected: false },
            { label: "Pending", value: "pending", selected: false },
            {
              label: "Cancel Request",
              value: "cancel request",
              selected: false,
            },
            { label: "Cancelled", value: "cancelled", selected: false },
          ]
        : isclick === "order_history"
        ? [
            {
              label: "Select Status",
              value: "",
              selected: true,
            },
            { label: "Pending", value: "pending", selected: false },
            {
              label: "Payment Verification",
              value: "payment verification",
              selected: false,
            },
            { label: "Settlement", value: "settlement", selected: false },
            {
              label: "Refund Request",
              value: "refund request",
              selected: false,
            },
            { label: "Refunded", value: "refunded", selected: false },
            { label: "Cancelled", value: "cancelled", selected: false },
          ]
        : isclick === "plans_history"
        ? [
            { label: "Select Status", value: "", selected: true },
            { label: "Pending", value: "pending", selected: false },
            { label: "Active", value: "active", selected: false },
            { label: "Deactivated", value: "deactivated", selected: false },
          ]
        : isclick === "notes" && student_notes.data?.length > 0
        ? [
            {
              label: "Added By",
              value: "",
              selected: true,
            },
            ...student_notes?.data.map((el) => ({
              label: el?.created_by,
              value: el?.created_by,
            })),
          ]
        : [
            {
              label: "Added By",
              value: "",
              selected: true,
            },
          ];

    setStatus(data_status);
  }, [isclick]);

  useEffect(() => {
    if (id) {
      setOrderHistory();
      dispatch(getStudentDetail(user_token, studio_id, id));
      dispatch(getStudentClass(user_token, studio_id, id, extraParams));
      dispatch(getStudentOrder(user_token, studio_id, id, extraParams));
      dispatch(getStudentPlan(user_token, studio_id, id, extraParams));
      dispatch(getStudentNotes(user_token, studio_id, id, extraParams));
    }
  }, [id]);

  useEffect(() => {
    const extraParams = `page=${
      isclick === "class_history"
        ? active
        : isclick === "order_history"
        ? active1
        : isclick === "plan_history"
        ? active2
        : isclick === "notes" && active3
    }&${
      isclick === "order_history" ? "payment_status" : "status"
    }=${filterStatus}&search=${search}`;

    isclick === "class_history"
      ? dispatch(getStudentClass(user_token, studio_id, id, extraParams))
      : isclick === "order_history"
      ? dispatch(getStudentOrder(user_token, studio_id, id, extraParams))
      : isclick === "plan_history"
      ? dispatch(getStudentPlan(user_token, studio_id, id, extraParams))
      : isclick === "notes" &&
        dispatch(getStudentNotes(user_token, studio_id, id, extraParams));
  }, [id, active, active1, active2, active3, filterStatus, search]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      {user_role === "owner" ? (
        <HeaderOwner title="Detail Student" />
      ) : (
        <HeaderTeacher title="Detail Student" />
      )}

      <div
        style={{ transform: "translateY(-77px)", minHeight: "100vh" }}
        className="d-flex flex-column"
      >
        <div
          style={{ width: "28rem" }}
          className="mx-auto shadow-sm p-3 mb-3 bg-body rounded-lg"
        >
          <div className="d-flex justify-content-between">
            <div className="">
              <span>
                {student_detail?.first_name} {student_detail?.last_name}
              </span>{" "}
              <br />
              <div className="heading3">
                <span>{student_detail?.email}</span> <br />
                <span>{student_detail?.phone_number}</span>
              </div>
            </div>
            <div ref={wrapperRef} className="position-relative">
              <button
                onClick={() => setShow((show) => !show)}
                className="option-button"
              >
                ...
              </button>
              {show && (
                <DropdownCard
                  width="3rem"
                  className="dropdown-text"
                  positionTop
                  positionLeft={35}
                  data={[
                    {
                      name: "Edit",
                      onClick: () =>
                        history.push({
                          pathname: `/student/${student_detail?.id}/edit`,
                          state: { id: student_detail?.id },
                        }),
                    },
                    {
                      name: "Delete",
                      onClick: () => {
                        setShow(false);
                        setShow8({
                          id: student_detail?.id,
                          visible: true,
                          name: student_detail?.first_name,
                          last_name: student_detail?.last_name,
                          email: student_detail?.email,
                        });
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>

        <div
          style={{ maxWidth: "22rem" }}
          className="mx-auto shadow-sm mb-3 b-greyblue rounded-3 overflow-hidden heading3 d-flex"
        >
          <div
            onClick={() => {
              setIsClick("class_history");
              setShow1(false);
            }}
            className={`${
              isclick === "class_history"
                ? "bg-greyblue text-white"
                : "custom-hover4"
            } py-2 px-3 br-greyblue pointer`}
          >
            Class History
          </div>
          <div
            onClick={() => {
              setIsClick("order_history");
              setShow1(false);
            }}
            className={`${
              isclick === "order_history"
                ? "bg-greyblue text-white"
                : "custom-hover4"
            } py-2 px-2 br-greyblue pointer`}
          >
            Order History
          </div>
          <div
            onClick={() => {
              setIsClick("plans_history");
              setShow1(false);
            }}
            className={`${
              isclick === "plans_history"
                ? "bg-greyblue text-white"
                : "custom-hover4"
            } py-2 px-2 br-greyblue pointer`}
          >
            Plans History
          </div>
          <div
            onClick={() => {
              setIsClick("notes");
              setShow1(false);
            }}
            className={`${
              isclick === "notes" ? "bg-greyblue text-white" : "custom-hover4"
            } py-2 px-2 pointer`}
          >
            Notes
          </div>
        </div>

        <div style={{ minWidth: "56rem" }} className="mx-auto">
          {isclick === "notes" && (
            <Button
              onClick={() =>
                history.push({ pathname: "/add/notes", state: { id: id } })
              }
              className="mb-3"
              title="Add Notes"
              type="primary"
            />
          )}

          <div className="d-flex justify-content-between align-items-center">
            <div className="position-relative">
              <TextInput
                inputClassName="bg-light"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <HiSearch
                className="greyblue position-absolute fs-5"
                style={{ top: 8, right: 0 }}
              />
            </div>
            <div style={{ width: "200px" }}>
              {/* <SummonSelectInput /> */}
              <SelectInput
                data={status}
                selectClassName="bg-light"
                onChange={(event) => {
                  setFilterStatus(event.target.value);
                }}
              />
            </div>
          </div>

          {isLoadingStudent ? (
            <AtomLoading />
          ) : (
            <Table
              responsive
              hover
              size="sm"
              className="h-100 mt-3 mb-0 rounded-top overflow-hidden text-center"
              style={{ fontSize: "14px" }}
            >
              <thead>
                <tr>
                  {tableHead?.map((el, idx) => (
                    <th
                      className="py-3 fw-bold"
                      style={{ backgroundColor: "#ededed" }}
                      key={idx}
                    >
                      {el}
                    </th>
                  ))}
                </tr>
              </thead>
              {isclick === "class_history" ? (
                <tbody ref={wrapperRef} className="bg-body">
                  {student_class.data?.length > 0 ? (
                    student_class.data?.map((el, idx) => (
                      <tr key={idx}>
                        <td className="position-relative">
                          <button
                            onClick={() => {
                              setShow1((show1) => !show1);
                              setIndex(idx);
                            }}
                            className="option-button position-relative"
                          >
                            ...
                          </button>
                          {index === idx && show1 && (
                            <DropdownCard
                              className="dropdown-text"
                              positionTop
                              positionLeft={40}
                              data={[
                                {
                                  name: "Edit Booking Status",
                                  onClick: () =>
                                    setShow3({
                                      id: el?.class_id,
                                      visible: true,
                                      name: el?.class_name,
                                      invoice:
                                        el?.class_order_number.split("-")[0],
                                      booking_status: el?.status,
                                    }),
                                },
                                {
                                  name: "Edit Attended Status",
                                  onClick: () =>
                                    setShow4({
                                      id: el?.class_id,
                                      visible: true,
                                      name: el?.class_name,
                                      invoice:
                                        el?.class_order_number.split("-")[0],
                                      attended_status: el?.is_attended,
                                    }),
                                },
                                {
                                  name: "Cancel",
                                  onClick: () =>
                                    setShow5({
                                      id: el?.class_order_number.split("-")[0],
                                      visible: true,
                                      name: el?.class_name,
                                    }),
                                },
                              ]}
                            />
                          )}
                        </td>
                        <td>{el?.class_schedule}</td>
                        <td>
                          <Link
                            className="text-black"
                            to={{
                              pathname: `/class/${el?.class_id}/detail`,
                              state: { id: el?.class_id },
                            }}
                          >
                            {truncateString(el?.class_name, 34)}
                          </Link>
                        </td>
                        <td>
                          <u
                            className="pointer"
                            onClick={() => {
                              setIsClick("order_history");
                              setSearch(el?.class_order_number.split("-")[0]);
                            }}
                          >
                            {el?.class_order_number.split("-")[0]}
                          </u>
                        </td>
                        <td className="text-capitalize">{el?.status}</td>
                        <td>{el?.is_attended}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : isclick === "order_history" ? (
                <tbody ref={wrapperRef} className="bg-body">
                  {student_order.data?.length > 0 ? (
                    student_order.data?.map((el, idx) => (
                      <tr key={idx}>
                        <td className="position-relative">
                          <button
                            onClick={() => {
                              setShow1((show1) => !show1);
                              setIndex(idx);
                            }}
                            className="option-button"
                          >
                            ...
                          </button>
                          {index === idx && show1 && (
                            <DropdownCard
                              className="dropdown-text"
                              positionTop
                              positionLeft={40}
                              data={[
                                {
                                  name: "Edit Payment Status",
                                  onClick: () =>
                                    setShow6({
                                      id: el?.order_number,
                                      visible: true,
                                      name: el?.name,
                                      invoice: el?.order_number.split("-")[0],
                                      payment_status: el?.payment_status,
                                    }),
                                },
                              ]}
                            />
                          )}
                        </td>
                        <td>{el?.created_at}</td>
                        <td>{el?.order_number.split("-")[0]}</td>
                        <td className="text-capitalize">{el?.category}</td>
                        <td>{truncateString(el?.name, 34)}</td>
                        <td className="text-capitalize">
                          {el?.payment_type !== ""
                            ? el?.payment_type.replace("_", " ")
                            : "-"}
                        </td>
                        <td>{el?.bank_name !== "" ? el?.bank_name : "-"}</td>
                        <td>
                          IDR{" "}
                          {el?.total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </td>
                        <td className="text-capitalize">
                          {el?.payment_status}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center gap-3 fs-5 greyblue">
                            {el?.transfer_receipt === null ? null : (
                              <HiOutlineDownload
                                onClick={() =>
                                  setShow2({
                                    visible: true,
                                    transfer_receipt: el?.transfer_receipt,
                                  })
                                }
                                className="pointer custom-hover"
                              />
                            )}
                            {/* <HiOutlineEye
                              className="pointer custom-hover"
                            /> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : isclick === "plans_history" ? (
                <tbody ref={wrapperRef} className="bg-body">
                  {student_plan.data?.length > 0 ? (
                    student_plan.data?.map((el, idx) => (
                      <tr key={idx}>
                        <td className="position-relative">
                          <button
                            onClick={() => {
                              setShow1((show1) => !show1);
                              setIndex(idx);
                            }}
                            className="option-button"
                          >
                            ...
                          </button>
                          {index === idx && show1 && (
                            <DropdownCard
                              className="dropdown-text"
                              positionTop
                              positionLeft={40}
                              data={[
                                {
                                  name: "Edit Plan Status",
                                  onClick: () =>
                                    setShow7({
                                      category: "edit-plan-status",
                                      id: el?.plan_id,
                                      visible: true,
                                      name: el?.plan_name,
                                      invoice:
                                        el?.plan_order_number.split("-")[0],
                                      plan_status: el?.status,
                                    }),
                                },
                              ]}
                            />
                          )}
                        </td>
                        <td>
                          <Link
                            className="text-black"
                            to={{
                              pathname: `/detail-plan`,
                              state: { id: el?.plan_id },
                            }}
                          >
                            {truncateString(el?.plan_name, 34)}
                          </Link>
                        </td>
                        <td className="text-capitalize">{el?.plan_type}</td>
                        <td>{el?.number_of_used_credit}</td>
                        <td>
                          <u
                            className="pointer"
                            onClick={() => {
                              setIsClick("order_history");
                              setSearch(el?.plan_order_number);
                            }}
                          >
                            {el?.plan_order_number.split("-")[0]}
                          </u>
                        </td>
                        <td>{el?.plan_transaction_date}</td>
                        <td>{el?.end_valid_date}</td>
                        <td className="text-capitalize">{el?.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody ref={wrapperRef} className="bg-body">
                  {student_notes.data?.length > 0 ? (
                    student_notes.data?.map((el, idx) => (
                      <tr key={idx}>
                        <td className="position-relative">
                          <button
                            onClick={() => {
                              setShow1((show1) => !show1);
                              setIndex(idx);
                            }}
                            className="option-button"
                          >
                            ...
                          </button>
                          {index === idx && show1 && (
                            <DropdownCard
                              className="dropdown-text"
                              positionTop
                              positionLeft={40}
                              data={[
                                {
                                  name: "Delete",
                                  onClick: () => {
                                    setShow9({
                                      id: el?.id,
                                      name: el?.title,
                                      visible: true,
                                    });
                                  },
                                },
                                {
                                  name: "Edit",
                                  onClick: () =>
                                    history.push({
                                      pathname: `/edit/notes`,
                                      state: {
                                        notes_id: el?.id,
                                        class_id: el?.class_id,
                                        title: el?.title,
                                        description: el?.description,
                                      },
                                    }),
                                },
                              ]}
                            />
                          )}
                        </td>
                        <td>{el?.created_at}</td>
                        <td>{el?.title}</td>
                        <td>{el?.description}</td>
                        <td>
                          <Link
                            className="text-black"
                            to={{
                              pathname: `/class/${el?.class_id}/detail`,
                              state: { id: el?.class_id },
                            }}
                          >
                            {truncateString(el?.class_name, 34)}
                          </Link>
                        </td>
                        <td>{el?.created_by}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              <tfoot className="bg-white">
                <tr>
                  <td className="rounded-bottom border-0" colSpan={10}>
                    {SummonPagination()}
                  </td>
                </tr>
              </tfoot>
            </Table>
          )}
        </div>
      </div>

      <Footer />

      <Modal
        show={show2?.visible}
        onHide={() => setShow2({ visible: false })}
        centered
        contentClassName="rounded-lg"
      >
        <div className="py-5 mx-auto">
          <h1 className="heading1 text-center mb-3">Bank Transfer Receipt</h1>
          <div className="b-dashed bg-babyblue p-5 mb-4 d-flex flex-column align-items-center">
            <Image
              src={show2?.transfer_receipt ?? sampleImg}
              width={200}
              rounded
            />
            <div className="d-flex align-items-center mt-4">
              <Button
                type="outline-secondary"
                title="Download"
                onClick={() => {
                  downloadTransferReceipt(show2?.transfer_receipt);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              title="Back"
              onClick={() => {
                setShow2({ vsible: false });
              }}
            />
          </div>
        </div>
      </Modal>

      <StudentModal
        title="Edit Booking Status"
        data={show3}
        setShow={() => setShow3({ visible: false })}
        status={status}
        currentStatus={show3?.booking_status}
      />

      <StudentModal
        title="Edit Attended Status"
        data={show4}
        setShow={() => setShow4({ visible: false })}
        status={attendedStatus}
        currentStatus={show4?.attended_status}
        onChange={(e) => setIsAttended(e.target.value)}
        handleSubmit={updateClassAttendance}
      />

      <StudentModal
        title="Edit Payment Status"
        data={show6}
        setShow={() => setShow6({ visible: false })}
        status={payment_status}
        currentStatus={show6?.payment_status}
        onChange={(e) => setPaymentStatus(e.target.value)}
        handleSubmit={updateStatusPayment}
      />

      <StudentModal
        title="Edit Plan Status"
        data={show7}
        setShow={() => setShow7({ visible: false })}
        status={status}
        currentStatus={show7?.plan_status}
        handleSubmit={handleDeactivatePlan}
      />

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show5?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Cancel Class</h2>
          <p>Cancel this Classs?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{show5.name}</b>
              <br />
              <span className="heading3">
                for {student_detail?.first_name} {student_detail?.last_name}
              </span>
            </p>
          </div>
          <span className="mb-3 text-danger f-12">
            You need to return the money manually to your customer
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow5({ visible: false })}
            />
            <Button
              title="Submit"
              type="primary"
              onClick={() => cancelClass(show5?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show8?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Delete Student</h2>
          <p>Delete this Student?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">
                {show8?.name} {show8?.last_name}
              </b>
              <br />
              <span className="heading3">{show8?.email}</span>
            </p>
          </div>
          <div className="text-start p-3 bg-pink b-gray rounded mb-3">
            <b>Please do these before deleting the student account :</b>
            <ol className="heading3 m-0">
              <li>
                Cancel all the payment status (payment verification, pending,
                refund request, and settlement) related to in-progress and
                upcoming order by the student.
              </li>
              <li>
                Accept all the cancellation requests related to the student.
              </li>
            </ol>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow8({ visible: false })}
            />
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDeleteStudent()}
            />
          </div>
        </div>
      </Modal>

      <ModalDelete
        show={show9}
        setShow={setShow9}
        subject="Notes"
        messages="Student note will be dissapear after deleting"
        handleDelete={handleDeleteNotes}
      />
    </Container>
  );
};

export default DetailStudent;
