import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import Button from "../../components/Button/button.component";

import {
  updateGeneralTerms,
  setStatusDefault,
  getGeneralTerms,
} from "../../redux/studio/studio.action";

export const TermsAndConditions = ({ nextStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { onboarding_status } = useSelector((state) => state.owner);
  const { general_terms, status, isLoading } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      general_term_title: title,
      general_term_description: description,
    };

    dispatch(updateGeneralTerms(params, user_token, studio_id));
  };

  useEffect(() => {
    if (status === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [status]);

  return (
    <Col>
      <div
        style={{ maxWidth: "32rem" }}
        className="custom-card add-student-form mx-auto"
      >
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              autoFocus
              label="Title"
              placeholder="Input Title..."
              value={title || ""}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <label htmlFor="desc" className="custom-form-label">
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              required
              className="text-input"
              name="desc"
              id="desc"
              cols="30"
              rows="10"
              placeholder="Input Description..."
              value={description || ""}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Form.Group>
          <div className="mt-3 d-flex justify-content-between">
            <Button
              type="secondary"
              title="Skip for Now"
              onClick={() => {
                nextStep();
              }}
            />

            <Button
              buttonType="submit"
              type="primary"
              title="SAVE"
              isLoading={isLoading}
            />
          </div>
        </Form>
      </div>
    </Col>
  );
};
