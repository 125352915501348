import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import "./owner.styles.scss";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
  Table,
} from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";
import SelectInput from "../../components/SelectInput/select-input.component";

import TimePicker from "react-time-picker";

import iconRemove from "./../../assets/images/Icon feather-delete.png";

import {
  addSchedule,
  addDuration,
  removeSchedule,
} from "../../redux/owner/owner.action";
import DatePicker from "../../components/date-picker";
import { toast } from "react-toastify";

const ClassDurationSetup = ({ nextStepSchedule, setSelectedStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { addClass } = useSelector((state) => state.owner);

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [days, setDays] = useState("");
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("10:00");
  const [repeatAmount, setRepeatAmount] = useState('1');
  const [repeatDuration, setRepeatDuration] = useState("weeks");
  const [stopRepeating, setstopRepeating] = useState("after");

  const setDate = (e) => {
    setSelectedDate(e);
    setShow(false);
  };

  const setEndOfDate = (e) => {
    setEndDate(e);
    setShow1(false);
  };

  const handleSchedule = () => {
    if (days === "") {
      toast.error("Please Select Day!");
      return;
    }
    let newSchedule = {
      id: addClass.schedule ? addClass.schedule.length + 1 : 1,
      day: days,
      start_time: startTime,
      end_time: endTime,
    };
    dispatch(addSchedule(newSchedule));
  };

  const handleRemoveSchedule = (id) => {
    var array = addClass.schedule;
    const afterDelete = array.filter((e) => e.id !== id);
    dispatch(removeSchedule(afterDelete));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let finalStartDate = moment(selectedDate).format("YYYY-MM-DD");
    let params = {
      start_date: finalStartDate,
      end_date: moment(endDate).format("YYYY-MM-DD"),
      // stop_repeating: stopRepeating,      
      repeat_every: repeatAmount,
      repeat_duration: repeatDuration,
    };

    dispatch(addDuration(params));
  };

  useEffect(() => {
    if (addClass.start_date !== "") {
      nextStepSchedule();
    }
  }, [addClass.start_date]);

  const listDay = [
    { label: "- Select Day -", value: "", selected: true },
    { label: "Monday", value: "monday", selected: false },
    { label: "Tuesday", value: "tuesday", selected: false },
    { label: "Wednesday", value: "wednesday", selected: false },
    { label: "Thursday", value: "thursday", selected: false },
    { label: "Friday", value: "friday", selected: false },
    { label: "Saturday", value: "saturday", selected: false },
    { label: "Sunday", value: "sunday", selected: false },
  ];

  const listRepeat = [
    { label: "- Select -", value: "", selected: false },
    { label: "Days", value: "days", selected: false },
    { label: "Weeks", value: "weeks", selected: false },
    { label: "Months", value: "months", selected: true },
    { label: "Years", value: "years", selected: false },
  ];

  return (
    <Col lg={4}>
      <Form onSubmit={handleSubmit}>
      <div
          className="bg-white rounded-lg p-3 shadow-sm mb-3"
          style={{ paddingTop: 20, width: "100%" }}
        >
          <Form.Group style={{ marginBottom: 10 }}>
            <DatePicker
              title="Start Date"
              currentDate={currentDate}
              selectedDate={selectedDate}
              show={show}
              setShow={setShow}
              setDate={setDate}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: 10 }}>
            <DatePicker
              title="End Date"
              currentDate={currentDate}
              selectedDate={endDate}
              show={show1}
              setShow={setShow1}
              setDate={setEndOfDate}
            />
          </Form.Group>
        </div>

        <div className="bg-white rounded-lg p-3 shadow-sm" style={{ paddingTop: 20, width: "100%" }}>          
          <Form.Group style={{ marginBottom: 10 }}>
            <SelectInput
              label="Select Day"
              data={listDay}
              onChange={(event) => {
                setDays(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <Row>
              <Col>
                <div className={`form-group`}>
                  <label className="custom-form-label mt-0">Start Time</label>
                  <TimePicker
                    format="HH:mm"
                    onChange={(event) => {
                      setStartTime(event);
                    }}
                    disableClock={true}
                    value={startTime}
                  />
                </div>
              </Col>
              <Col>
                <div className={`form-group`}>
                  <label className="custom-form-label mt-0">End Time</label>
                  <TimePicker
                    format="HH:mm"
                    onChange={(event) => {
                      setEndTime(event);
                    }}
                    disableClock={true}
                    value={endTime}
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <div className="d-flex justify-content-between w-100">
            <div></div>
            <div>
              <Button
                style={{ marginTop: 10, marginBottom: 10 }}
                type="primary"
                title="Add Schedule"
                onClick={() => {
                  handleSchedule();
                }}
              />
            </div>
          </div>
          <div>
            <Table responsive="sm">
              <tbody>
                {addClass.schedule
                  ? addClass.schedule.map((element, idx) => (
                      <tr key={idx + 1}>
                        <td>{`#${idx + 1}`}</td>
                        <td>{element.day}</td>
                        <td>
                          {element.start_time} - {element.end_time}
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              handleRemoveSchedule(element.id);
                            }}
                            className="pointer custom-hover"
                          >
                            <img src={iconRemove} />
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
          {/* <Form.Group>
            <Row>
              <Col>
                <div className={`form-group fs-12`}>
                  <label className="custom-form-label fs-10 mt-0">
                    Stop Repeating
                  </label>
                  <>
                    <Row>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="After"
                          value="after"
                          name="time"
                          id="time1"
                          onChange={(e) => setstopRepeating(e.target.value)}
                          checked={stopRepeating === "after"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Never"
                          value="never"
                          name="time"
                          id="time2"
                          onChange={(e) => setstopRepeating(e.target.value)}
                          checked={stopRepeating === "never"}
                        />
                      </Col>
                    </Row>
                  </>
                </div>
              </Col>
              {stopRepeating === "after" && (
                <>
                  <Col>
                    <TextInput
                      type="number"
                      required={stopRepeating === "after"}
                      labelClassName="fs-10"
                      label="Repeat Amount"
                      placeholder="10"
                      onChange={(event) => {
                        setRepeatAmount(event.target.value);
                      }}
                      value={repeatAmount}
                    />
                  </Col>
                  <Col style={{ marginTop: "14px" }}>
                    <SelectInput
                      required={stopRepeating === "after"}
                      data={listRepeat}
                      onChange={(event) => {
                        setRepeatDuration(event.target.value);
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form.Group> */}
          <div className="text-center">
            <em className="custom-form-label">
              This class will be repeated every 1 weeks.
            </em>
          </div>
          <div className=" py-3 d-flex justify-content-between">
            <Button
              type="secondary"
              title="Skip for now"
              onClick={() => {
                setSelectedStep(4);
              }}
            />
            <Button
              isLoading={addClass.schedule?.length === 0}
              buttonType="submit"
              type="primary"
              title="CONTINUE"
            />
          </div>
        </div>
      </Form>
    </Col>
  );
};

export default ClassDurationSetup;
