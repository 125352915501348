import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Image,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderStudent/header-student.component";
import AtomNumberFormat from "../../../../components/number-format";
import "./../students.styles.scss";
import sampleImg from "./../../../../assets/images/placeholder.png";
import { useCookies } from "react-cookie";
import {
  addBank,
  editBank,
  getBankDetail,
} from "../../../../redux/bank/bank.action";
import {
  paymentVerification,
  setStatusDefault,
} from "../../../../redux/student/student.action";
import { toast } from "react-toastify";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const PaymentVerification = (props) => {
  const uploadLogo = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies(["user"]);

  const [logoBank, setLogoBank] = useState(null);
  const [preview, setPreview] = useState();

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoadingClasses, statusVerification, class_detail, order_resp } =
    useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const classDetail = class_detail[0]?.class_schedules[0];

  const state = props.location?.state;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleClick = (e) => {
    e.preventDefault();

    const params = {
      order_id: order_resp?._id ?? state?.id,
      payment_receipt: logoBank,
    };
    dispatch(paymentVerification(user_token, studio_id, params));
  };

  useEffect(() => {
    if (!logoBank) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(logoBank);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logoBank]);

  useEffect(() => {
    if (statusVerification === "success") {
      history.push("/payment-processed");
      dispatch(setStatusDefault());
    }
  }, [statusVerification]);

  return (
    <OrganismLayout maxWidth="42rem">
      <Col className="mx-auto p-3" style={{ maxWidth: "28rem" }}>
        <h1 className="heading1 text-center mb-5">Payment Verification</h1>
        <div className="b-gray rounded-lg p-3 mb-3">
          <Row>
            <Col>Total</Col>
            <Col className="d-flex justify-content-end fs-4">
              <AtomNumberFormat
                prefix="IDR "
                value={
                  order_resp.payment?.total_amount ??
                  classDetail?.total_payment ??
                  state?.total
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Order ID</Col>
            <Col className="d-flex justify-content-end">
              {order_resp?.order_number ?? state?.order_number}
            </Col>
          </Row>
        </div>
        <div className="b-gray rounded-lg p-3 mb-3">
          <Row>
            <Col>
              <p className="custom-form-label">Payment Method</p>
              <p>Transfer Bank</p>
              <Image
                src={order_resp?.bank_image ?? state?.bank_image ?? sampleImg}
                width={80}
              />
            </Col>
            <Col className="border-1 border-start">
              <p className="custom-form-label">Bank</p>
              <p>
                {order_resp.payment?.transfer_to?.bank_name ?? state?.bank_name}
              </p>
              <p className="custom-form-label">Account Number</p>
              <p>
                {order_resp.payment?.transfer_to?.bank_account_number ??
                  state?.account_number}
              </p>
              <p className="custom-form-label">Account Name</p>
              <p>
                {order_resp.payment?.transfer_to?.account_holder_name ??
                  state?.account_holder_name}
              </p>
            </Col>
          </Row>
        </div>
        <Form onSubmit={handleClick}>
          <div className="b-dashed bg-babyblue p-5 mb-4 d-flex flex-column align-items-center">
            <Image src={preview ?? sampleImg} width={80} rounded />
            <span className="gray-text mt-1">Upload Payment Receipt</span>
            <span className="fs-10">for fasten the payment process</span>
            <p className="regular-text mt-2 mb-2" style={{ fontSize: 10 }}>
              File format:.jpg, .png, .pdf - max 1 MB
            </p>
            <div className="d-flex align-items-center mt-4">
              <Button
                type="outline-secondary"
                title="Browse"
                onClick={(e) => {
                  e.preventDefault();
                  uploadLogo.current.click();
                }}
              />
              <Form.Group controlId="formFileSm" className="d-none">
                <Form.Control
                  ref={uploadLogo}
                  type="file"
                  name="logoBank"
                  onChange={(event) => {
                    setLogoBank(event.target.files[0]);
                  }}
                  size="sm"
                />
              </Form.Group>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-4">
            <Button
              onClick={() => history.push("/dashboard")}
              type="outline-secondary"
              title="Back"
            />
            <Button
              isLoading={logoBank === null}
              buttonType="submit"
              type="primary"
              title="CONFIRM"
            />
          </div>
        </Form>
      </Col>
    </OrganismLayout>
  );
};

export default PaymentVerification;
