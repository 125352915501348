import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./owner.styles.scss";

import {
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  Form,
  Vi,
} from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import Header from "../../components/Header/header.component";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";

import { setclassNameSetup } from "../../redux/owner/owner.action";
import SelectInput from "../../components/SelectInput/select-input.component";

const ClassNameSetup = ({ nextStepSchedule, setSelectedStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { addClass } = useSelector((state) => state.owner);

  const [className, setClassName] = useState("");
  const [isOnline, setIsOnline] = useState(0);
  const [location, setLocation] = useState("");
  const [room, setRoom] = useState(null);
  const [slots, setSlots] = useState(null);
  const [classlink, setCassLink] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [notes, setNotes] = useState("");
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [credits, setCredits] = useState(0);
  const [activityType, setActivityType] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      // type: activityType,
      class_name: className,
      is_online: isOnline,
      class_link: "",
      additional_information: "",
      location: location,
      room: room,
      slots: slots,
      class_link: classlink,
      additional_information: additionalInformation,
      notes: notes,
      class_price: price,
      class_tax: tax,
      credits: credits,
    };

    dispatch(setclassNameSetup(params));
  };

  useEffect(() => {
    if (addClass.class_name !== "") {
      nextStepSchedule();
    }
  }, [addClass.class_name]);

  return (
    <Col lg={4}>
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div className="custom-card" style={{ paddingTop: 20 }}>
          <Form.Group>
            <Form.Check
              id="isOnline"
              type="checkbox"
              label="Online"
              style={{ fontFamily: "Roboto", fontSize: 14, color: "#000" }}
              onChange={(event) => setIsOnline(event.target.checked ? 1 : 0)}
            />
          </Form.Group>
          {/* <Form.Group style={{ marginBottom: 10 }}>
            <SelectInput
              required
              label="Activity Type"
              data={[
                { label: "Select Activity Type", value: "", selected: true },
                { label: "Event", value: "event", selected: false },
                { label: "Class", value: "class", selected: false },
                {
                  label: "Registration",
                  value: "registration",
                  selected: false,
                },
              ]}
              onChange={(e) => setActivityType(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              autoFocus
              label="Class Name"
              placeholder="Healty and young yoga"
              onChange={(event) => {
                setClassName(event.target.value);
              }}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  type="number"
                  label="Price"
                  placeholder="Input Price..."
                  value={price}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  type="number"
                  label="Tax (%)"
                  placeholder="input Tax..."
                  value={tax}
                  onChange={(event) => {
                    setTax(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              addTooltip
              labelClassName="d-flex"
              required
              type="number"
              value={credits}
              label="Credits"
              placeholder="Input credits..."
              onChange={(event) => {
                setCredits(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required={isOnline === 0}
              label="Location"
              placeholder="Jakarta"
              onChange={(event) => {
                setLocation(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <Row>
              <Col>
                <TextInput
                  required={isOnline === 0}
                  label="Room"
                  placeholder="2A"
                  onChange={(event) => {
                    setRoom(event.target.value);
                  }}
                />
              </Col>
              <Col>
                <TextInput
                  type="number"
                  required
                  label="Available Slots"
                  placeholder="100"
                  value={slots}
                  onChange={(event) => {
                    setSlots(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Form.Group>

          {isOnline ? (
            <>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Class Link"
                  placeholder="https://zoom.us/yogayoung"
                  onChange={(event) => {
                    setCassLink(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  label="Additional Information"
                  placeholder="Meeting ID : 1233434 Password : yogayoung123"
                  onChange={(event) => {
                    setAdditionalInformation(event.target.value);
                  }}
                />
              </Form.Group>
            </>
          ) : null}

          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              label="Notes"
              placeholder="Input Notes..."
              onChange={(event) => {
                setNotes(event.target.value);
              }}
            />
          </Form.Group>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: 20 }}
          >
            <Button
              buttonType="button"
              type="secondary"
              title="Skip for now"
              onClick={() => {
                setSelectedStep(4);
              }}
            />
            <Button
              buttonType="submit"
              type="primary"
              title="CONTINUE"
              // style={{ marginLeft: "5%" }}
            />
          </div>
        </div>
      </Form>
    </Col>
  );
};

export default ClassNameSetup;
