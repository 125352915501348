import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Container, Row, Col } from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderOwner/header-owner.component";
import Pagination from "./../../../../components/pagination";
import DropdownCard from "./../../../../components/Button/dropdown.component";
import AtomLoading from "../../../../components/loding-indicator";
import OnboardingStatus from "../../../../components/onboarding-status";

import { HiUser, HiUserGroup } from "react-icons/hi";
import { FaEllipsisH } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import {
  getProfile,
  getUserRole,
} from "../../../../redux/student/student.action";
import {
  getStatisticPaymentVerification,
  getStatisticProfile,
  getStatisticCancellation,
  getStatisticUpcomingClass,
  getStatisticClassInProgress,
  getOnboardingStatus,
  getTeacher,
} from "../../../../redux/owner/owner.action";
import { setLoginFromStudio, setNewUser } from "../../../../redux/user/user.action";
import EmptyData from "../../../../components/no-data";
import { useOutsideOnclick } from "../../../../services/clickOutside";
import { changeRole } from "../../../../services/auth";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";

const DashBoard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [active2, setActive2] = useState(1);
  const [active3, setActive3] = useState(1);
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [cookies, setCookie, removeCookies] = useCookies(["user"]);
  const [listActivities, setListActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const {
    profile_statistic,
    list_payment_verification,
    list_cancellation_request,
    list_upcoming_class,
    list_class_in_progress,
    isLoading,
    onboarding_status,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { status_change_role, user, loginFromStudio } = useSelector(
    (state) => state.user
  );
  const { user_role, profile, activePlan, classes, isLoadingClasses } =
    useSelector((state) => state.student);

  const subdomain = window.location.host.split(".")[0];
  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;

  const userRoles = user_role.roles
    ? user_role.roles?.filter((item) => item !== role)
    : [];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const data = [
    ...userRoles.map((el) => ({
      name: `View as ${el.toLowerCase().replace(/\w/, (x) => x.toUpperCase())}`,
      onClick: () => setRoles(el),
    })),
    {
      name: "Edit Profile",
      href: "/profile",
    },
  ];

  const summary = [
    {
      title: "Classes",
      value: profile_statistic?.total_class,
      pathname: "/list-statistic",
    },
    {
      title: "Registrants",
      value: profile_statistic?.total_participant,
      pathname: "/list-statistic",
    },
    {
      title: "Attendants",
      value: profile_statistic?.total_attended,
      pathname: "/list-statistic",
    },
    {
      title: "Transactions",
      value: profile_statistic?.total_transaction,
      pathname: "/list-statistic",
    },
  ];

  const setRoles = async (role) => {
    try {
      const params = {
        role: role,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data))

      // if (cookies.user) {
        removeCookies("user", {
          path: "/",
          domain: process.env.REACT_APP_HOST,
          // domain: ".swift.local",
        });
        removeCookies("subdomain", {
          path: "/",
          domain: process.env.REACT_APP_HOST,
          // domain: ".swift.local",
        });
      // }
      dispatch(setLoginFromStudio());
      history.push("/dashboard");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useOutsideOnclick(wrapperRef, setOpen);

  useEffect(() => {
    dispatch(getUserRole(user_token, studio_id));
    dispatch(getProfile(user_token, studio_id));
    dispatch(getOnboardingStatus(user_token, studio_id));
    dispatch(getTeacher(user_token, studio_id, 1));
  }, []);

  useEffect(() => {
    dispatch(
      getStatisticProfile(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM")
      )
    );
  }, [selectedDate]);

  useEffect(() => {
    dispatch(
      getStatisticPaymentVerification(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active
      )
    );
  }, [selectedDate, active]);

  useEffect(() => {
    dispatch(
      getStatisticClassInProgress(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active1
      )
    );
  }, [selectedDate, active1]);

  useEffect(() => {
    dispatch(
      getStatisticCancellation(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active2
      )
    );
  }, [selectedDate, active2]);

  useEffect(() => {
    dispatch(
      getStatisticUpcomingClass(
        user_token,
        studio_id,
        moment(selectedDate).format("YYYY-MM"),
        active3
      )
    );
  }, [selectedDate, active3]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <Header title="Dashboard" />
        {isLoading ? (
          <AtomLoading />
        ) : (
          <>
            {!isClose ? (
              <OnboardingStatus
                closeButton
                selectedStep={1}
                setIsClose={() => setIsClose(true)}
              />
            ) : null}
            <div
              style={{
                maxWidth: "52rem",
                transform: isClose ? "translateY(0px)" : "translateY(-77px)",
              }}
              className={`mx-auto mb-5 fs-12 ${isClose ? "mt-1" : "mt-0"}`}
            >
              <Row
                style={{
                  gap: "0.8rem",
                }}
              >
                <Col
                  sm={4}
                  className="text-white shadow-sm bg-lightaquamarine py-2 px-4 rounded-10"
                >
                  <div className="d-flex justify-content-between">
                    <span className="heading3">Welcome</span>
                    <div
                      ref={wrapperRef}
                      className="position-relative"
                      onClick={() => setOpen((open) => !open)}
                    >
                      <FaEllipsisH className="custom-hover pointer" />
                      {open && (
                        <DropdownCard
                          positionRight
                          data={data}
                          className="text-lightaquamarine custom-hover"
                        />
                      )}
                    </div>
                  </div>
                  <span className="heading1">
                    {profile?.first_name} {profile?.last_name}
                  </span>
                  <br />
                  <span className="heading3 text-capitalize">as {role}</span>
                </Col>
                <Col className="p-0">
                  <div className="shadow-sm p-1 mb-2 rounded-10 bg-white">
                    <div
                      style={{ width: "250px" }}
                      className="mx-auto d-flex justify-content-between align-items-center"
                    >
                      <IoIosArrowBack
                        onClick={() =>
                          setSelectedDate(moment(selectedDate).subtract(1, "M"))
                        }
                        className="greyblue fs-4 pointer custom-hover"
                      />
                      <span className="lightaquamarine">
                        {moment(selectedDate).format("MMMM, YYYY")}
                      </span>
                      <IoIosArrowForward
                        onClick={() =>
                          setSelectedDate(moment(selectedDate).add(1, "M"))
                        }
                        className="greyblue fs-4 pointer custom-hover"
                      />
                    </div>
                  </div>

                  <div className="mx-auto shadow-sm p-3 rounded-10 bg-white d-flex justify-content-between text-center">
                    {summary.map((el, idx) => (
                      <div key={idx} className="d-flex">
                        <div
                          style={{
                            marginLeft: idx === 0 && "20px",
                            marginRight: idx === 3 && "20px",
                          }}
                        >
                          <b>{el?.value}</b> <br />
                          <Link
                            to={{
                              pathname: el.pathname,
                              state: {
                                title: el?.title,
                                selectedDate:
                                  moment(selectedDate).format("YYYY-MM"),
                              },
                            }}
                            className="heading3 text-decoration-none pointer custom-hover-greyblue"
                          >
                            {el?.title}
                          </Link>
                        </div>
                        {idx + 1 === summary.length ? null : (
                          <div className="border-end ps-5"></div>
                        )}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              <Row
                style={{
                  gap: "0.8rem",
                }}
              >
                <Col
                  sm={4}
                  className="shadow-sm p-2 my-2 rounded-10 bg-white h-100"
                >
                  <p className="lightaquamarine">Payment Verification</p>
                  {list_payment_verification.data?.length > 0 ? (
                    list_payment_verification.data?.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Link
                            to={{
                              pathname: `/student/${el?.student_id}/detail`,
                              state: { id: el?.student_id },
                            }}
                            className="text-black"
                          >
                            <b>{el?.order_number}</b>
                          </Link>
                          <span
                            className={`${
                              el?.category === "class"
                                ? "text-danger"
                                : "greyblue"
                            } heading3`}
                          >
                            {el.category?.replace(/\b\w/g, (l) =>
                              l.toUpperCase()
                            )}
                          </span>
                        </div>
                        <Row className="heading3">
                          <Col>
                            {el?.bank_name} -{" "}
                            {moment(el?.payment_verification_date).format(
                              "DD MMM YYYY"
                            )}
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {list_payment_verification.data?.length > 0 && (
                    <Pagination
                      active={active}
                      setActive={setActive}
                      current_page={
                        list_payment_verification.meta.pagination?.current_page
                      }
                      total_pages={
                        list_payment_verification.meta.pagination?.total_pages
                      }
                    />
                  )}
                </Col>
                <Col className="shadow-sm p-2 my-2 rounded-10 bg-white h-100">
                  <p className="lightaquamarine">Class In Progress</p>
                  {list_class_in_progress.data?.length > 0 ? (
                    list_class_in_progress.data?.map((el, idx) => (
                      <div key={idx} className="">
                        <div className="d-flex justify-content-between">
                          <b>{el?.class_name}</b>
                          <span className="heading3">
                            {el?.is_online === 1 ? "Online" : "Offline"}
                          </span>
                        </div>
                        <div className="heading3 d-flex justify-content-between">
                          <div>
                            <HiUser className="text-pink fs-6" /> {el?.teachers}
                          </div>
                          <div>
                            <HiUserGroup className="text-pink fs-6" />{" "}
                            {el?.participant} Registrants
                          </div>
                          <div>
                            <HiUserGroup className="text-pink fs-6" />{" "}
                            {el?.attended} Attendants
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {list_class_in_progress.data?.length > 0 && (
                    <Pagination
                      active={active1}
                      setActive={setActive1}
                      current_page={
                        list_class_in_progress.meta.pagination?.current_page
                      }
                      total_pages={
                        list_class_in_progress.meta.pagination?.total_pages
                      }
                    />
                  )}
                </Col>
              </Row>

              <Row
                style={{
                  gap: "0.8rem",
                }}
              >
                <Col sm={4} className="shadow-sm p-2 rounded-10 bg-white h-100">
                  <p className="lightaquamarine">Cancellation Request</p>
                  {list_cancellation_request.data?.length > 0 ? (
                    list_cancellation_request.data?.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Link
                            to={{
                              pathname: `/student/${el?.student_id}/detail`,
                              state: { id: el?.student_id, setOrder: true },
                            }}
                            className="text-black"
                          >
                            <b>{el?.student_name}</b>
                          </Link>
                          <span
                            className={`${
                              el?.category === "class"
                                ? "text-danger"
                                : "greyblue"
                            } heading3`}
                          >
                            {el?.category?.replace(/\b\w/g, (l) =>
                              l.toUpperCase()
                            )}
                          </span>
                        </div>
                        <Row className="heading3">
                          <Col>
                            {moment(el?.cancel_date).format(
                              "dddd, DD MMM YYYY"
                            )}
                          </Col>{" "}
                          <br />
                          <span>{el?.cancellation_reason ?? "-"}</span>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {list_cancellation_request.data?.length > 0 && (
                    <Pagination
                      active={active2}
                      setActive={setActive2}
                      current_page={
                        list_cancellation_request.meta.pagination?.current_page
                      }
                      total_pages={
                        list_cancellation_request.meta.pagination?.total_pages
                      }
                    />
                  )}
                </Col>
                <Col className="shadow-sm p-2 rounded-10 bg-white h-100">
                  <p className="lightaquamarine">Upcoming Class</p>
                  {list_upcoming_class.data?.length > 0 ? (
                    list_upcoming_class.data?.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between align-items-center">
                          <b>{el?.class_name}</b>
                          <span className="heading3 d-flex gap-1">
                            <HiUserGroup className="text-pink fs-6" />
                            {el?.participant}
                          </span>
                        </div>
                        <Row className="heading3">
                          <Col>{el?.date_time_class}</Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {list_upcoming_class.data?.length > 0 && (
                    <Pagination
                      active={active3}
                      setActive={setActive3}
                      current_page={
                        list_upcoming_class.meta.pagination?.current_page
                      }
                      total_pages={
                        list_upcoming_class.meta.pagination?.total_pages
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
      <Footer />
    </Container>
  );
};

export default DashBoard;
