import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/button.component";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomLoading from "../../../../components/loding-indicator";
import EmptyData from "../../../../components/no-data";
import AtomNumberFormat from "../../../../components/number-format";
import Pagination from "../../../../components/pagination";
import { getListStatistic } from "../../../../services/dashboard";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";

const ListStatistic = (props) => {
  const { title, selectedDate } = props.location.state;

  const [cookies, setCookies] = useCookies(["user"]);
  const [tableHead, setTableHead] = useState([]);
  const [listStatistic, setListStatistic] = useState([]);
  const [active4, setActive4] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState({});
  const [actionType, setActionType] = useState("");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const first = () => {
    switch (title) {
      case "Classes":
        setActionType("list-activities");
        break;
      case "Registrants":
        setActionType("list-registrant");
        break;
      case "Attendants":
        setActionType("list-attendance");
        break;
      case "Transactions":
        setActionType("list-transactions");
        break;
      default:
        break;
    }
  };

  const getTableHead = () => {
    switch (title) {
      case "Classes":
        setTableHead([
          "Class Name",
          // "Schedule",
          "Location",
          "Room",
          "Credit",
          "Price",
          // "Type of Class",
        ]);
        break;
      case "Registrants":
        setTableHead(["Name", "Registration Date", "Status", "Class Name"]);
        break;
      case "Attendants":
        setTableHead(["Name", "Registration Date", "Status", "Class Name"]);
        break;
      case "Transactions":
        setTableHead([
          "Order Number",
          "Transaction Name",
          "Category",
          "Payment Type",
          "Settlement Date",
          "Total Amount",
        ]);
        break;
      default:
        break;
    }
  };

  const handleGetListStatistic = async () => {
    setIsLoading(true);
    try {
      const res = await getListStatistic(
        user_token,
        studio_id,
        selectedDate,
        actionType,
        active4
      );

      setListStatistic(res.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    first();
    getTableHead();
  }, []);

  useEffect(() => {
    handleGetListStatistic();
  }, [actionType, active4]);

  return (
    <>
      <OrganismLayout title={title} maxWidth="56rem" className="p-0">
        <Col>
          {isLoading ? (
            <AtomLoading />
          ) : (
            <Table
              responsive
              hover
              className="mb-0 overflow-hidden px-3 text-center"
              style={{ borderRadius: "10px 10px 0px 0px", fontSize: "14px" }}
            >
              <thead>
                <tr>
                  {tableHead?.map((el, idx) => (
                    <th
                      className="py-3 fw-bold"
                      style={{ backgroundColor: "#ededed" }}
                      key={idx}
                    >
                      {el}
                    </th>
                  ))}
                </tr>
              </thead>
              {title === "Classes" ? (
                <tbody>
                  {listStatistic.data?.length ? (
                    listStatistic.data?.map((el, idx) => (
                      <tr key={idx} className="py-2">
                        <td>
                          {/* <Link
                          className="text-black"
                          to={{
                            pathname: `activity/${el?.id}/detail`,
                            state: { id: el?.id },
                          }}
                        > */}
                          {el?.class_name}
                          {/* </Link> */}
                        </td>
                        {/* <td
                          onClick={() =>
                            setScheduleOpen({
                              visible: true,
                              name: el?.class_name,
                              schedules: el?.schedules,
                            })
                          }
                          className="text-decoration-underline pointer greyblue"
                        >
                          View
                        </td> */}
                        <td>{el?.location}</td>
                        <td>{el?.room}</td>
                        <td>{el?.credits}</td>
                        <td>
                          <AtomNumberFormat
                            prefix="IDR "
                            value={el?.class_price}
                          />
                        </td>
                        {/* <td className="text-capitalize">{el?.type}</td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="p-5">
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : title === "Transactions" ? (
                <tbody>
                  {listStatistic.data?.length ? (
                    listStatistic.data?.map((el, idx) => (
                      <tr key={idx} className="py-2">
                        <td>
                          {/* <Link
                        className="text-black"
                        to={{
                          pathname: `activity/${el?.id}/detail`,
                          state: { id: el?.id },
                        }}
                      > */}
                          {el?.order_number.split("-")[0]}
                          {/* </Link> */}
                        </td>
                        <td>{el?.name}</td>
                        <td>{el?.category}</td>
                        <td>{el?.payment_type}</td>
                        <td>{el?.settlement_date}</td>
                        <td>
                          <AtomNumberFormat
                            prefix="IDR "
                            value={el?.total_amount}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="p-5">
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {listStatistic.data?.length ? (
                    listStatistic.data?.map((el, idx) => (
                      <tr key={idx} className="py-2">
                        <td>
                          {/* <Link
                      className="text-black"
                      to={{
                        pathname: `activity/${el?.id}/detail`,
                        state: { id: el?.id },
                      }}
                    > */}
                          {el?.registrant_name}
                          {/* </Link> */}
                        </td>
                        <td>{el?.registration_date}</td>
                        <td>{el?.booking_class_status}</td>
                        <td>{el?.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="p-5">
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </Table>
          )}
          {listStatistic.data?.length > 0 && (
            <Pagination
              className="py-4"
              active={active4}
              setActive={setActive4}
              current_page={listStatistic.meta.pagination?.current_page}
              total_pages={listStatistic.meta.pagination?.total_pages}
            />
          )}
        </Col>
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={scheduleOpen?.visible}
        onHide={() => setScheduleOpen({ visible: false })}
      >
        <div className="py-4 px-3 text-center">
          <h2 className="heading1 m-0">{scheduleOpen?.name}</h2>
          <div className="mx-5 p-2 my-4 rounded">
            {scheduleOpen.schedules?.map((el, idx) => (
              <Row key={idx}>
                <Col>
                  <p>{el?.day}</p>
                </Col>
                <Col className="text-decoration-underline">
                  <p>
                    {el?.start_time} - {el?.end_time}
                  </p>
                </Col>
              </Row>
            ))}
          </div>
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={() => setScheduleOpen({ visible: false })}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListStatistic;
