import React, { useEffect } from "react";
import Footer from "./../../components/Footer/footer.component";
import Button from "./../../components/Button/button.component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { getGeneralTermsPublic } from "../../redux/studio/studio.action";
import AtomLoading from "../../components/loding-indicator";

const GeneralTerms = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subdomain = window.location.host.split(".")[0];

  const { isLoading, general_terms_public } = useSelector(
    (state) => state.studio
  );

  const isRegisterStudio = props.location.state?.isRegisterStudio;

  useEffect(() => {
    if (isRegisterStudio) return;
    else dispatch(getGeneralTermsPublic(subdomain));
  }, [isRegisterStudio]);

  return (
    <Container fluid className="bg-light">
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Row style={{ minHeight: "100vh" }} className="p-5">
          <Col className="p-5">
            <div style={{ height: "400px" }}>
              <h1 className="heading1 mb-3">
                {general_terms_public?.title ?? "Terms and Conditions"}
              </h1>
              {general_terms_public?.description ? (
                <p>{general_terms_public?.description}</p>
              ) : (
                <em className="custom-form-label">"Not Available"</em>
              )}
            </div>
            <Button
              onClick={() => window.close()}
              type="primary"
              title="BACK"
            />
          </Col>
        </Row>
      )}
      <Footer />
    </Container>
  );
};

export default GeneralTerms;
