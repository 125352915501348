import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./login.styles.scss";

import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";

import {
  chooseStudio,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { getAllActiveStudio } from "../../services/auth";
import OrganismLayout from "../../components/Layout/organism-layout";

const ChooseStudio = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["user", "subdomain"]);
  const [studios, setStudios] = useState([]);

  const { user, isLoading } = useSelector((state) => state.user);

  const { status, subdomain, user_studio } = useSelector(
    (state) => state.studio
  );

  const handleClick = (item) => {
    dispatch(chooseStudio(item?.id, item?.subdomain));
  };

  const studioActive = async () => {
    try {
      const res = await getAllActiveStudio(user?.token);

      setStudios(res.data.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useEffect(() => {
    studioActive();
  }, []);

  useEffect(() => {
    if (status === "success") {
      setCookie("user", user_studio, {
        path: "/",
        // domain: ".swift.local",
        domain: process.env.REACT_APP_HOST,
      });
      setCookie("subdomain", subdomain, {
        path: "/",
        // domain: ".swift.local",
        domain: process.env.REACT_APP_HOST,
      });

      window.location.href =
        process.env.REACT_APP_PROTOCOL +
        subdomain +
        `.${process.env.REACT_APP_HOST}/dashboard`;

      dispatch(setStatusDefault());
    }
  }, [status]);

  return (
    <OrganismLayout withLogout>
      <Col
        lg={4}
        className="mx-auto d-flex align-items-center justify-content-center flex-column p-5"
        style={{ height: "80%" }}
      >
        <h1 className="heading1">Welcome, {user.name.split(" ")[0]}</h1>
        <p className="regular-text mt-2">
          Which Studio do you want to sign in?
        </p>
        {studios.map((item, idx) => {
          return (
            <button
              key={idx}
              className="studio-button"
              onClick={() => handleClick(item)}
            >
              {item?.studio_name}
            </button>
          );
        })}
      </Col>
    </OrganismLayout>
  );
};

export default ChooseStudio;
