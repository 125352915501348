import React from "react";
import { Col, Spinner } from "react-bootstrap";

const AtomLoading = () => {
  return (
    <Col
      className="d-flex justify-content-center flex-column align-items-center"
      style={{ height: "400px" }}
    >
      <div className="text-center fs-12">
        <Spinner variant="info" animation="grow" /> <br /> <br />
        <span>Loading Please Wait ...</span>
      </div>
    </Col>
  );
};

export default AtomLoading;
