import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./login.styles.scss";
import logo from "./../../assets/images/swift_logo.png";
import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.png";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";

import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import {
  requestOtp,
  setStatusVerifyDefault,
  logout,
  setNewUser,
} from "../../redux/user/user.action";

import * as authService from "../../services/auth";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, isLoading, message, statusVerify } = useSelector(
    (state) => state.user
  );

  const [email, setEmail] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const responseSocialAuth = async (provider, response) => {
    try {
      const access_token = response?.accessToken;

      setFirstName(
        response.profileObj?.givenName ?? response?.name.split(" ")[0]
      );
      setLastName(
        response.profileObj?.familyName ?? response?.name.split(" ")[1]
      );
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmedMain(
          provider,
          access_token
        );
        if (result.data.data.token) {
          dispatch(setNewUser(result.data.data));
          history.push("/choose-studio");
        }
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
      }
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value.trim());
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(requestOtp(email));
  };

  const redirectToRegister = () => {
    history.push({
      pathname: "/register",
      state: { firstName: firstName, lastName: lastName, email: email },
    });
  };

  useEffect(() => {
    if (message === "User not registered") {
      setShowModalRegister(true);
      dispatch(setStatusVerifyDefault());
    }
  }, [message]);

  useEffect(() => {
    if (statusVerify === "success") {
      history.push("/verify");
      dispatch(setStatusVerifyDefault());
    }
  }, [statusVerify]);

  useEffect(() => {
    if (user.token) {
      dispatch(logout(user?.token));
    }
  }, [user.token]);

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row style={{ minHeight: "100vh" }}>
        <Col lg={7} md={7} className="login-left-image"></Col>
        <Col lg={5} className="d-flex justify-content-center">
          <div className="login-content py-5">
            <img src={logo} className="swift-logo mb-2" />
            <h1 className="heading1 mt-4 mb-5">Log In</h1>
            <Form className="w-100" onSubmit={handleClick}>
              <TextInput
                required
                autoFucus
                label="Email"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value.trim())}
              />
              <Button
                buttonType="submit"
                title="CONTINUE"
                type="primary"
                className="mt-4"
                style={{ width: "100%" }}
                isLoading={isLoading}
              />
            </Form>
            <span className="custom-form-label mt-3 mb-3">Or, use</span>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => {
                return (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="google-btn custom-hover"
                  >
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "10px",
                      }}
                      src={google}
                    />{" "}
                    Continue with Google
                  </button>
                );
              }}
              onSuccess={(res) => responseSocialAuth("google", res)}
              onFailure={(res) => responseSocialAuth("google", res)}
            />
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              size="small"
              textButton="Continue with Facebook"
              icon={
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    marginRight: "10px",
                  }}
                  src={facebook}
                />
              }
              fields="name,email,picture"
              callback={(res) => responseSocialAuth("facebook", res)}
              cssClass="facebook-btn mt-3 custom-hover"
            />
            <hr style={{ width: "100%" }} className="mt-3 mb-3" />
            <span className="fs-12 fw-bold">
              Don't have account? <a href="/register">Sign up here</a>{" "}
            </span>
          </div>
        </Col>
      </Row>
      <Footer />

      <Modal size="sm" show={showModalRegister} centered>
        <Row>
          <Col lg={12} className="p-4 text-center">
            <h2 className="heading2 mb-3">Email Not Registered</h2>
            <span className="custom-form-label">
              Continue register with
            </span>{" "}
            <br />
            <b>{email}</b>
            <div className="d-flex gap-2">
              <Button
                title="CHANGE"
                type="outline-secondary"
                className="mt-4"
                onClick={() => setShowModalRegister(false)}
                isLoading={isLoading}
              />
              <Button
                title="CONFIRM"
                type="primary"
                className="mt-4"
                onClick={redirectToRegister}
                isLoading={isLoading}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </Container>
  );
};

export default Login;
