import React from "react";

const DropdownCard = ({
  data,
  className,
  positionTop,
  positionBottom,
  positionRight,
  positionLeft,
  width,
}) => {
  const styles = {
    top: positionTop && 0,
    bottom: positionBottom,
    right: positionRight && 0,
    left: positionLeft,
  };
  return (
    <div
      style={{ ...styles, width: width ?? "9rem", zIndex: 10 }}
      className="shadow position-absolute rounded-3 bg-white p-2 fs-12"
    >
      {data?.map((item, i) => (
        <div onClick={item?.onClick} key={i}>
          <a href={item?.href} className="text-decoration-none">
            <span className={`${className} pointer`}>{item?.name}</span>

            {i + 1 === data?.length ? null : (
              <hr className="dropdown-divider" />
            )}
          </a>
        </div>
      ))}
    </div>
  );
};

export default DropdownCard;
