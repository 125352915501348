const StudioActionTypes = {
  REGISTER_STUDIO: "REGISTER_STUDIO",
  REGISTER_STUDIO_SUCCESS: "REGISTER_STUDIO_SUCCESS",
  REGISTER_STUDIO_FAILED: "REGISTER_STUDIO_FAILED",

  REGISTER_STUDENT: "REGISTER_STUDENT",
  REGISTER_STUDENT_SUCCESS: "REGISTER_STUDENT_SUCCESS",
  REGISTER_STUDENT_FAILED: "REGISTER_STUDENT_FAILED",

  EDIT_STUDIO: "EDIT_STUDIO",
  EDIT_STUDIO_SUCCESS: "EDIT_STUDIO_SUCCESS",
  EDIT_STUDIO_FAILED: "EDIT_STUDIO_FAILED",

  CHOOSE_STUDIO: "CHOOSE_STUDIO",
  CHOOSE_STUDIO_SUCCESS: "CHOOSE_STUDIO_SUCCESS",
  CHOOSE_STUDIO_FAILED: "CHOOSE_STUDIO_FAILED",

  GET_CLIENT_KEY: "GET_CLIENT_KEY",
  GET_CLIENT_KEY_SUCCESS: "GET_CLIENT_KEY_SUCCESS",
  GET_CLIENT_KEY_FAILED: "GET_CLIENT_KEY_FAILED",

  GET_CANCELLATION_SETTING: "GET_CANCELLATION_SETTING",
  GET_CANCELLATION_SETTING_SUCCESS: "GET_CANCELLATION_SETTING_SUCCESS",
  GET_CANCELLATION_SETTING_FAILED: "GET_CANCELLATION_SETTING_FAILED",

  GET_GENERAL_TERMS_PUBLIC: "GET_GENERAL_TERMS_PUBLIC",
  GET_GENERAL_TERMS_PUBLIC_SUCCESS: "GET_GENERAL_TERMS_PUBLIC_SUCCESS",
  GET_GENERAL_TERMS_PUBLIC_FAILED: "GET_GENERAL_TERMS_PUBLIC_FAILED",

  GET_GENERAL_TERMS: "GET_GENERAL_TERMS",
  GET_GENERAL_TERMS_SUCCESS: "GET_GENERAL_TERMS_SUCCESS",
  GET_GENERAL_TERMS_FAILED: "GET_GENERAL_TERMS_FAILED",

  UPDATE_GENERAL_TERMS: "UPDATE_GENERAL_TERMS",
  UPDATE_GENERAL_TERMS_SUCCESS: "UPDATE_GENERAL_TERMS_SUCCESS",
  UPDATE_GENERAL_TERMS_FAILED: "UPDATE_GENERAL_TERMS_FAILED",

  UPDATE_CANCELLATION: "UPDATE_CANCELLATION",
  UPDATE_CANCELLATION_SUCCESS: "UPDATE_CANCELLATION_SUCCESS",
  UPDATE_CANCELLATION_FAILED: "UPDATE_CANCELLATION_FAILED",

  REGISTER_STUDIO_WITH_SOCMED_SUCCESS: "REGISTER_STUDIO_WITH_SOCMED_SUCCESS",
  SET_STATUS_DEFAULT: "SET_STATUS_DEFAULT",
  SET_IS_REGISTER_STUDENT: "SET_IS_REGISTER_STUDENT",
};

export default StudioActionTypes;
