import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Container, Row, Col, Form } from "react-bootstrap";
import { HiClock, HiUser } from "react-icons/hi";
import Button from "../../../../components/Button/button.component";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import "./../students.styles.scss";

import {
  getDetailOfferingClasses,
  getMyActivePlan,
} from "./../../../../redux/student/student.action";

import AtomLoading from "../../../../components/loding-indicator";
import AtomNumberFormat from "../../../../components/number-format";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const PaymentClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [selectPlan, setSelectPlan] = useState("");
  const [validated, setValidated] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { isLoadingClasses, class_detail, active_plan } = useSelector(
    (state) => state.student
  );

  const id = props.location.state?.id;
  const classDetail = class_detail[0]?.class_schedules[0];
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    history.push({
      patname: "/choose-payment",
      state: {
        buy_class: true,
        class_schedule_id: classDetail?.class_schedule_id,
      },
    });
  };

  const submitPayment = () => {
    if (selectPlan.split(",")[2] === "plan") {
      history.push({
        pathname: "/confirm-class",
        state: { selected_plan: selectPlan },
      });
    } else {
      history.push({
        pathname: "/choose-payment",
        state: {
          buy_class: true,
          class_schedule_id: classDetail?.class_schedule_id,
        },
      });
    }
  };

  useEffect(() => {
    dispatch(getDetailOfferingClasses(user_token, studio_id, id));
    dispatch(getMyActivePlan(user_token, studio_id));
  }, []);

  return (
    <OrganismLayout maxWidth="42rem">
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <Col className="mx-auto p-3" style={{ maxWidth: "32rem" }}>
          <div className="text-center">
            <h1 className="heading1 mb-5">Payment</h1>
            {false && (
              <p className="text-danger">
                Youd don't have valid membership plan for this class
              </p>
            )}
          </div>
          <div className="mb-3 b-gray rounded-10 p-3">
            <div className="fs-12 d-flex justify-content-between mb-2">
              <span>{classDetail?.is_online === 1 ? "Online" : "Offline"}</span>
              <span>
                <HiClock className="fs-5 text-pink" />{" "}
                {classDetail?.class_start_time} - {classDetail?.class_end_time}
              </span>
              <span>
                <HiUser className="fs-5 text-pink" />{" "}
                {classDetail?.teachers.join(",")}
              </span>
              <span></span>
            </div>
            <span className="fw-bold">{classDetail?.class_name}</span>
            <hr />
            <Row>
              <Col className="fw-bold">
                <p>Schedule</p>
              </Col>
              <Col className="fs-12" xs={9}>
                {class_detail[0]?.class_date}, {classDetail?.class_start_time} -{" "}
                {classDetail?.class_end_time}
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Slots</p>
              </Col>
              <Col className="fs-12" xs={9}>
                {classDetail?.slots} Remaining
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Credits</p>
              </Col>
              <Col className="fs-12" xs={9}>
                {classDetail?.credits}
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Price</p>
              </Col>
              <Col className="fs-12" xs={9}>
                <AtomNumberFormat
                  prefix="IDR "
                  value={classDetail?.total_amount}
                />
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Notes</p>
              </Col>
              <Col className="fs-12" xs={9}>
                {classDetail?.notes}
              </Col>
            </Row>
          </div>
          {false ? (
            <h2 className="text-center">
              This session for <strong>10 USD</strong>
            </h2>
          ) : (
            <Form
            // noValidate
            // validated={validated}
            // onSubmit={(event) => handleSubmit(event)}
            >
              <p>How do you pay for it?</p>

              {active_plan &&
                active_plan
                  .filter(
                    (item) =>
                      (item.plan?.plan_type === "membership" &&
                        item?.current_usage_limit >= 1) ||
                      (item.plan?.plan_type === "class pass" &&
                        item?.current_credits >= classDetail?.credits)
                  )
                  .map((el, idx) => (
                    <Form.Group key={idx} className="fs-12 fw-bold">
                      <Form.Check
                        // required
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                        id={el.plan?.plan_name}
                        type="radio"
                        value={el.plan?.plan_name
                          .concat(",", el?._id)
                          .concat(",", el?.category)}
                        label={el.plan?.plan_name}
                        name={el.plan?.plan_name}
                        onChange={(e) => setSelectPlan(e.target.value)}
                        checked={
                          selectPlan.split(",")[0] === el.plan?.plan_name
                        }
                      />
                    </Form.Group>
                  ))}
              <Form.Group className="fs-12 fw-bold">
                <Form.Check
                  // required
                  feedback="You must agree before submitting."
                  feedbackType="invalid"
                  id="option3"
                  type="radio"
                  value={`Buy this class for IDR ${classDetail?.total_amount}`}
                  label={`Buy this class for IDR ${classDetail?.total_amount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
                  name="option3"
                  onChange={(e) => setSelectPlan(e.target.value)}
                  checked={
                    selectPlan ===
                    `Buy this class for IDR ${classDetail?.total_amount}`
                  }
                />
              </Form.Group>
              <div className="d-flex flex-column align-items-center mt-4">
                <Button
                  type={true ? "primary" : "outline-secondary"}
                  title={true ? "CONFIRM" : "Booking"}
                  onClick={submitPayment}
                />
                <div
                  onClick={() => history.goBack()}
                  className="fw-bolder greyblue mt-4 pointer custom-hover"
                >
                  Cancel
                </div>
              </div>
            </Form>
          )}
        </Col>
      )}
    </OrganismLayout>
  );
};

export default PaymentClass;
