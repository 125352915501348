import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./../classes.styles.scss";

import { Row, Col, Form } from "react-bootstrap";
import Button from "../../../../../components/Button/button.component";
import TextInput from "../../../../../components/TextInput/text-input.component";

import { setclassNameSetup } from "../../../../../redux/owner/owner.action";
import SelectInput from "../../../../../components/SelectInput/select-input.component";

const ClassNameSetup = ({ isOnboarding, nextStepSchedule }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies] = useCookies(["user"]);

  const [className, setClassName] = useState("");
  const [isOnline, setIsOnline] = useState(0);
  const [location, setLocation] = useState("");
  const [room, setRoom] = useState("");
  const [slots, setSlots] = useState("");
  const [classlink, setCassLink] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [notes, setNotes] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [credits, setCredits] = useState("");
  const [activityType, setActivityType] = useState("");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { addClass, class_detail, isLoadingClasses, onboarding_status } =
    useSelector((state) => state.owner);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const isCreating = window.location.pathname.includes("add") ? true : false;

  const handleSubmit = (e) => {
    if (isCreating) e.preventDefault();
    let params = {
      // type: activityType,
      class_name: className,
      is_online: isOnline,
      location: location,
      room: room,
      slots: slots,
      class_link: classlink,
      additional_information: additionalInformation,
      notes: notes,
      class_price: price,
      class_tax: tax,
      credits: credits,
    };
    dispatch(setclassNameSetup(params));
    if (isCreating) nextStepSchedule();
  };

  // useEffect(() => {
  //   if (addClass.class_name !== "") {
  //     nextStepSchedule();
  //     dispatch(getTeacher(user_token, studio_id));
  //   }
  // }, [addClass.class_name]);

  useEffect(() => {
    if (!isCreating) {
      setActivityType(class_detail?.type ?? "");
      setClassName(class_detail?.class_name ?? "");
      setIsOnline(class_detail?.is_online ?? "");
      setLocation(class_detail?.location ?? "");
      setRoom(class_detail?.room ?? "");
      setSlots(class_detail?.slots ?? "");
      setNotes(class_detail?.notes ?? "");
      setPrice(class_detail?.class_price ?? "");
      setTax(class_detail?.class_tax ?? "");
      setCredits(class_detail?.credits ?? "");
      if (class_detail.is_online === 1) {
        setCassLink(class_detail?.class_link ?? "");
        setAdditionalInformation(class_detail?.additional_information ?? "");
      }
    }
  }, [!isCreating]);

  useEffect(() => {
    if (!isCreating) {
      handleSubmit();
    }
  }, [
    className,
    isOnline,
    location,
    room,
    slots,
    classlink,
    additionalInformation,
    notes,
    price,
    tax,
    credits,
  ]);

  return (
    <Col lg={4}>
      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div className="custom-card1" style={{ paddingTop: 20 }}>
          <Form.Group>
            <Form.Check
              id="online-class"
              type="checkbox"
              label="Online"
              defaultChecked={isOnline === 1}
              onChange={(event) => setIsOnline(event.target.checked ? 1 : 0)}
            />
          </Form.Group>
          {/* <Form.Group style={{ marginBottom: 10 }}>
            <SelectInput
              required
              label="Activity Type"
              data={[
                {
                  label: "- Select Activity Type -",
                  value: "",
                  selected: isCreating && true,
                },
                {
                  label: "Event",
                  value: "event",
                  selected: activityType === "event" ? true : false,
                },
                {
                  label: "Class",
                  value: "class",
                  selected: activityType === "class" ? true : false,
                },
                // {
                //   label: "Registration",
                //   value: "registration",
                //   selected: false,
                // },
              ]}
              onChange={(e) => setActivityType(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              label="Class Name"
              placeholder="Healty and young yoga"
              value={className}
              onChange={(event) => {
                setClassName(event.target.value);
              }}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  type="number"
                  label="Price"
                  placeholder="Input Price..."
                  value={price}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  type="number"
                  label="Tax (%)"
                  placeholder="Input Tax..."
                  value={tax}
                  onChange={(event) => {
                    setTax(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              addTooltip
              labelClassName="d-flex"
              required
              type="number"
              label="Credits"
              placeholder="Input credits..."
              value={credits}
              onChange={(event) => {
                setCredits(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required={isOnline === 0}
              label="Location"
              placeholder="Jakarta"
              value={location}
              onChange={(event) => {
                setLocation(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <Row>
              <Col>
                <TextInput
                  required={isOnline === 0}
                  label="Room"
                  placeholder="2A"
                  value={room}
                  onChange={(event) => {
                    setRoom(event.target.value);
                  }}
                />
              </Col>
              <Col>
                <TextInput
                  type="number"
                  required
                  label="Available Slots"
                  value={slots}
                  placeholder="100"
                  onChange={(event) => {
                    setSlots(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Form.Group>

          {isOnline ? (
            <>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  label="Class Link"
                  placeholder="https://zoom.us/yogayoung"
                  value={classlink}
                  onChange={(event) => {
                    setCassLink(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  label="Additional Information"
                  placeholder="Meeting ID : 1233434 Password : yogayoung123"
                  value={additionalInformation}
                  onChange={(event) => {
                    setAdditionalInformation(event.target.value);
                  }}
                />
              </Form.Group>
            </>
          ) : null}

          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              label="Notes"
              placeholder="Input Notes..."
              value={notes}
              onChange={(event) => {
                setNotes(event.target.value);
              }}
            />
          </Form.Group>
        </div>
        {isCreating && (
          <div className=" p-3 d-flex justify-content-between">
            <div>
              <Button
                type={"outline-secondary"}
                title={isOnboarding ? "Skip for Now" : "Cancel"}
                onClick={() => {
                  if (isOnboarding) {
                    onboarding_status?.plan === false
                      ? history.push({
                          pathname: "/add-plan",
                          state: { isOnboarding: true },
                        })
                      : onboarding_status?.term_and_condition === false
                      ? history.push({
                          pathname: "/setting-terms-and-conditions",
                          state: { isOnboarding: true, step: 2 },
                        })
                      : onboarding_status?.cancellation_setting === false
                      ? history.push({
                          pathname: "/setting-cancellation",
                          state: { isOnboarding: true, step: 2 },
                        })
                      : onboarding_status?.payment_information === false
                      ? history.push({
                          pathname: "/setting-payment",
                          state: { isOnboarding: true, step: 2 },
                        })
                      : history.goBack();
                  } else {
                    history.goBack();
                  }
                }}
              />
            </div>
            <div>
              <Button buttonType="submit" type="primary" title="Next" />
            </div>
          </div>
        )}
      </Form>
    </Col>
  );
};

export default ClassNameSetup;
