import UserActionTypes from "../user/user.types";
import StudioActionTypes from "./studio.types";

const INITIAL_STATE = {
  studio: {},
  isLoading: false,
  isRegisterStudent: false,
  status: "default",
  subdomain: "",
  user_studio: {},
  general_terms_public: [],
  general_terms: [],
  client_key: [],
  cancellation_setting: {},
  isLoadingCancellation: "default",
};

const StudioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StudioActionTypes.REGISTER_STUDIO:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.REGISTER_STUDIO_SUCCESS:
      return {
        ...state,
        studio: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudioActionTypes.REGISTER_STUDIO_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.REGISTER_STUDIO_WITH_SOCMED_SUCCESS:
      return {
        ...state,
        studio: action.payload,
      };
    case StudioActionTypes.REGISTER_STUDENT:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.REGISTER_STUDENT_SUCCESS:
      return {
        ...state,
        user_studio: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudioActionTypes.REGISTER_STUDENT_FAILED:
      return {
        ...state,
        user_studio: {},
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.EDIT_STUDIO:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.EDIT_STUDIO_SUCCESS:
      return {
        ...state,
        subdomain: action.subdomain,
        user_studio: action.payload,
        status: "success",
        isLoading: false,
      };
    case StudioActionTypes.EDIT_STUDIO_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.CHOOSE_STUDIO:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.CHOOSE_STUDIO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subdomain: action.subdomain,
        user_studio: action.payload,
        status: "success",
      };
    case StudioActionTypes.CHOOSE_STUDIO_FAILED:
      return {
        ...state,
        isLoading: false,
        user_studio: {},
        message: action.message,
        status: "failed",
      };
    case StudioActionTypes.GET_CLIENT_KEY:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.GET_CLIENT_KEY_SUCCESS:
      return {
        ...state,
        client_key: action.payload,
        isLoading: false,
      };
    case StudioActionTypes.GET_CLIENT_KEY_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.GET_CANCELLATION_SETTING:
      return {
        ...state,
        isLoadingCancellation: true,
      };
    case StudioActionTypes.GET_CANCELLATION_SETTING_SUCCESS:
      return {
        ...state,
        cancellation_setting: action.payload,
        isLoadingCancellation: false,
      };
    case StudioActionTypes.GET_CANCELLATION_SETTING_FAILED:
      return {
        ...state,
        isLoadingCancellation: false,
        message: action.message,
      };
    case StudioActionTypes.GET_GENERAL_TERMS_PUBLIC:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.GET_GENERAL_TERMS_PUBLIC_SUCCESS:
      return {
        ...state,
        general_terms_public: action.payload,
        isLoading: false,
      };
    case StudioActionTypes.GET_GENERAL_TERMS_PUBLIC_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.GET_GENERAL_TERMS:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.GET_GENERAL_TERMS_SUCCESS:
      return {
        ...state,
        general_terms: action.payload,
        isLoading: false,
      };
    case StudioActionTypes.GET_GENERAL_TERMS_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.UPDATE_GENERAL_TERMS:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.UPDATE_GENERAL_TERMS_SUCCESS:
      return {
        ...state,
        status: "success",
        isLoading: false,
      };
    case StudioActionTypes.UPDATE_GENERAL_TERMS_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.UPDATE_CANCELLATION:
      return {
        ...state,
        isLoading: true,
      };
    case StudioActionTypes.UPDATE_CANCELLATION_SUCCESS:
      return {
        ...state,
        status: "success",
        isLoading: false,
      };
    case StudioActionTypes.UPDATE_CANCELLATION_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case StudioActionTypes.SET_STATUS_DEFAULT:
      return {
        ...state,
        status: "default",
      };
    case StudioActionTypes.SET_IS_REGISTER_STUDENT:
      return {
        ...state,
        isRegisterStudent: true,
      };
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        user_studio: {},
        subdomain: "",
      };
    default:
      return state;
  }
};

export default StudioReducer;
