import React, { useEffect, useRef, useState } from "react";

import { useCookies } from "react-cookie";
import { HiMail, HiPhone } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col, Modal, Spinner, Image } from "react-bootstrap";

import Footer from "./../../../../components/Footer/footer.component";
import HeaderOwner from "./../../../../components/HeaderOwner/header-owner.component";
import Button from "../../../../components/Button/button.component";
import Pagination from "../../../../components/pagination";
import DropdownCard from "../../../../components/Button/dropdown.component";
import AtomLoading from "../../../../components/loding-indicator";

import "./students.styles.scss";
import { getStudents } from "../../../../redux/owner/owner.action";
import EmptyData from "../../../../components/no-data";
import HeaderTeacher from "../../../../components/HeaderTeacher";
import axiosInstance from "../../../../axiosInstance";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { useOutsideOnclick } from "../../../../services/clickOutside";
import OrganismLayout from "../../../../components/Layout/organism-layout";

export const Students = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user", "subdomain"]);
  const [active, setActive] = useState(1);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState({
    id: "",
    visible: false,
    name: "",
    email: "",
  });

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, students } = useSelector((state) => state.owner);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  useOutsideOnclick(wrapperRef, setShow);

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/students/${show1?.id}`, config);

      let params = {
        token_jwt: user_token,
        studio_id: studio_id,
      };

      dispatch(getStudents(params, active));
      setShow1({ visible: false });
      toast.success("Student account is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useEffect(() => {
    let params = {
      token_jwt: user_token,
      studio_id: studio_id,
    };
    dispatch(getStudents(params, active));
  }, [active]);

  return (
    <>
      <OrganismLayout title="Students" maxWidth="44rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Col>
            <Button
              title="Add Student"
              type="primary"
              onClick={() => {
                history.push("/add-student");
              }}
            />
            <div ref={wrapperRef} className="students my-3">
              {students.data?.length > 0 ? (
                students.data?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="single-student d-flex justify-content-between"
                    >
                      <div className="d-flex gap-3">
                        <div lg={1} className="position-relative">
                          <button
                            onClick={() => {
                              setIndex(i);
                              setShow((show) => !show);
                            }}
                            className="option-button "
                          >
                            ...
                          </button>
                          {show && index === i && (
                            <DropdownCard
                              className="dropdown-text"
                              positionTop
                              positionLeft={45}
                              data={[
                                {
                                  name: "Edit",
                                  onClick: () =>
                                    history.push({
                                      pathname: `/student/${item?.id}/edit`,
                                      state: { id: item?.id },
                                    }),
                                },
                                {
                                  name: "Delete",
                                  onClick: () => {
                                    setShow(false);
                                    setShow1({
                                      id: item?.id,
                                      visible: true,
                                      name: item?.first_name,
                                      last_name: item?.last_name,
                                      email: item?.email,
                                    });
                                  },
                                },
                              ]}
                            />
                          )}
                        </div>
                        <div lg={3}>
                          <Link
                            to={{
                              pathname: `/student/${item?.id}/detail`,
                              // state: { id: item?.id },
                            }}
                            className="text-black fw-bold"
                          >
                            {item?.first_name} {item?.last_name}
                          </Link>
                          <p>
                            <HiMail
                              color="#CE7499"
                              size={14}
                              style={{ marginRight: 5 }}
                            />
                            {item.email}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p>
                          <HiPhone
                            color="#CE7499"
                            size={14}
                            style={{ marginRight: 5 }}
                          />
                          {item.phone_number !== null ? (
                            item?.phone_number
                          ) : (
                            <em className="custom-form-label fs-12">
                              "Not available"
                            </em>
                          )}
                        </p>
                      </div>
                      <div>
                        <p style={{ fontWeight: 500 }}>
                          Upcoming: {item?.upcoming}
                        </p>
                      </div>
                      <div>
                        <p style={{ fontWeight: 500 }}>
                          Attended: {item?.attended}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </div>
            {students.data?.length > 0 && (
              <Pagination
                active={active}
                setActive={setActive}
                current_page={students.meta.pagination?.current_page}
                total_pages={students.meta.pagination?.total_pages}
              />
            )}
          </Col>
        )}
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show1?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Delete Student</h2>
          <p>Delete this Student?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">
                {show1?.name} {show1?.last_name}
              </b>
              <br />
              <span className="heading3">{show1?.email}</span>
            </p>
          </div>
          <div className="text-start p-3 bg-pink b-gray rounded mb-3">
            <b>Please do these before deleting the student account :</b>
            <ol className="heading3 m-0">
              <li>
                Cancel all the payment status (payment verification, pending,
                refund request, and settlement) related to in-progress and
                upcoming order by the student.
              </li>
              <li>
                Accept all the cancellation requests related to the student.
              </li>
            </ol>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow1({ visible: false })}
            />
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDelete()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
