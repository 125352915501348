import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import moment from "moment";
import Footer from "../../../../components/Footer/footer.component";
import Header from "../../../../components/HeaderOwner/header-owner.component";
import AtomLoading from "../../../../components/loding-indicator";
import DropdownCard from "../../../../components/Button/dropdown.component";
import Pagination from "../../../../components/pagination";
import sampleImg from "../../../../assets/images/avatar.png";

import { useCookies } from "react-cookie";
import { getTeacherDetail } from "./../../../../redux/owner/owner.action";
import "react-calendar/dist/Calendar.css";
import axiosInstance from "../../../../axiosInstance";
import ModalDelete from "../../../../components/Modal/delete";
import EmptyData from "../../../../components/no-data";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const TeacherDetail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState({ visible: false, name: "", email: "" });

  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [classHeld, setClassHeld] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);

  const {
    teacher_detail,
    addTeacherStatus,
    updateTeacherStatus,
    isLoadingTeacher,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const teacher_name = `${teacher_detail?.first_name} ${
    teacher_detail?.last_name !== null ? teacher_detail?.last_name : ""
  }`;
  const id = props.location.state?.id;

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/teachers/${teacher_detail?.id}`, config);

      setShow1({ visible: false });
      history.push("/organizers");
      toast.success("Teacher account is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getTeacherDashboard = async (action_type, id) => {
    try {
      const res = await axiosInstance.get(
        `/dashboard-teachers?date=${moment(selectedDate).format(
          "YYYY-MM"
        )}&actiontype=${action_type}&page=${active}&teacher_id=${id}`,
        config
      );

      action_type === "class-held"
        ? setClassHeld(res?.data)
        : setUpcomingClasses(res?.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    dispatch(getTeacherDetail(user_token, studio_id, id));
  }, []);

  useEffect(() => {
    getTeacherDashboard("class-held", teacher_detail?.id);
    getTeacherDashboard("upcoming-classes", teacher_detail?.id);
  }, [teacher_detail?.id, selectedDate, active, active1]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <Header title="Detail Teacher" />
        {isLoadingTeacher ? (
          <AtomLoading />
        ) : (
          <Row
            style={{ maxWidth: "32rem", transform: "translateY(-77px)" }}
            className="d-flex flex-column mx-auto"
          >
            <Col className="shadow-sm p-3 mb-3 bg-body rounded-lg">
              <div className="d-flex justify-content-between">
                <div className="d-flex w-100">
                  <div className="position-relative">
                    <img
                      style={{ width: "120px", top: -50 }}
                      className="rounded-circle me-3 position-absolute"
                      src={sampleImg}
                      alt="Avatar"
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <div className="ps-5">
                      <span>{teacher_name}</span>
                      <br />
                      <div className="heading3">
                        <span>{teacher_detail?.email}</span> <br />
                        <span>{teacher_detail?.phone_number}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-relative">
                  <button
                    onClick={() => setShow((show) => !show)}
                    className="option-button"
                  >
                    ...
                  </button>
                  {show && (
                    <DropdownCard
                      width="4rem"
                      className="dropdown-text"
                      positionTop
                      positionLeft={40}
                      data={[
                        {
                          name: "Edit",
                          onClick: () =>
                            history.push({
                              pathname: `/teacher/${id}/edit`,
                              state: { id: id },
                            }),
                        },
                        {
                          name: "Delete",
                          onClick: () =>
                            setShow1({
                              visible: true,
                              name: teacher_name,
                              email: teacher_detail?.email,
                            }),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </Col>

            <div className="shadow-sm p-1 mb-2 rounded-lg bg-white">
              <div
                style={{ width: "250px" }}
                className="mx-auto d-flex justify-content-between align-items-center"
              >
                <IoIosArrowBack
                  onClick={() =>
                    setSelectedDate(moment(selectedDate).subtract(1, "M"))
                  }
                  className="greyblue fs-4 pointer custom-hover"
                />
                <span className="lightaquamarine">
                  {moment(selectedDate).format("MMMM, YYYY")}
                </span>
                <IoIosArrowForward
                  onClick={() =>
                    setSelectedDate(moment(selectedDate).add(1, "M"))
                  }
                  className="greyblue fs-4 pointer custom-hover"
                />
              </div>
            </div>

            <b>Class Held</b>
            <Col className="mx-auto shadow-sm p-3 my-3 bg-body rounded-lg fs-12">
              {classHeld.data?.length > 0 ? (
                classHeld.data?.map((el, idx) => (
                  <div key={idx}>
                    <span>{el?.class_date}</span> <br />
                    <h6>{el?.class_name}</h6>
                    <Row>
                      <Col>{el?.participants} Registrants</Col>
                      <Col>{el?.attended} Attendants</Col>
                      <Col xs={4} md={5} lg={5}>
                        {el?.turnover
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                        IDR Est. Turnover
                      </Col>
                    </Row>
                    <hr />
                  </div>
                ))
              ) : (
                <EmptyData />
              )}
              {classHeld.data?.length > 0 && (
                <Pagination
                  active={active}
                  setActive={setActive}
                  current_page={classHeld.meta.pagination?.current_page}
                  total_pages={classHeld.meta.pagination?.total_pages}
                />
              )}
            </Col>

            <b>Upcomping Class</b>
            <Col className="mx-auto shadow-sm p-3 my-3 bg-body rounded-lg fs-12">
              {upcomingClasses.data?.length > 0 ? (
                upcomingClasses.data.map((el, idx) => (
                  <div key={idx}>
                    <span>{el?.date_time_class}</span>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6>{el?.class_name}</h6>
                      </div>
                      <div>{el?.participant}</div>
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <EmptyData />
              )}
              {classHeld.data?.length > 0 && (
                <Pagination active={active1} setActive={setActive1} />
              )}
            </Col>
          </Row>
        )}
      </div>

      <ModalDelete
        show={show1}
        setShow={setShow1}
        subject={"Teacher"}
        messages={
          "Please delete all class assignments related to the teacher before deleteing the teacher account."
        }
        handleDelete={handleDelete}
      />

      <Footer />
    </Container>
  );
};

export default TeacherDetail;
