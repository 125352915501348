import axios from "../../axiosInstance";
import UserActionTypes from "./user.types";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";

const requestOtpUrl = "/otp";
const verifyOtpUrl = "/verification";
const verifyOtpStudioUrl = "/studios/auth";
const changeRoleUrl = "/profiles/roles";
const requestOtpActivateUrl = "/studio/request-activation";
const activateAccountUrl = "/studio/account-activation";

export const resendOtp = (email, subdomain) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UserActionTypes.RESEND_OTP,
      });

      const config =
        subdomain === null
          ? {
              headers: {
                "content-type": "application/json",
              },
            }
          : {
              headers: {
                "content-type": "application/json",
                subdomain: subdomain,
              },
            };

      const res = await axios.post(
        requestOtpUrl,
        {
          email: email,
        },
        config
      );

      if (res.status === 200) {
        dispatch({
          type: UserActionTypes.RESEND_OTP_SUCCESS,
          payload: email,
        });
      }
      toast.success("OTP Sent Successfully!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      if (error.response) {
        let message = error.response.data.message
          ? error.response.data.message
          : "Something went wrong";
        dispatch({
          type: UserActionTypes.RESEND_OTP_FAILED,
          message: message,
        });
      } else {
        dispatch({
          type: UserActionTypes.RESEND_OTP_FAILED,
          message: "Something went wrong.",
        });
      }
    }
  };
};

export const requestOtp = (email, subdomain) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UserActionTypes.REQUEST_OTP,
      });

      const config = subdomain
        ? {
            headers: {
              "content-type": "application/json",
              subdomain: subdomain,
            },
          }
        : {
            headers: {
              "content-type": "application/json",
            },
          };

      const res = await axios.post(
        requestOtpUrl,
        {
          email: email,
        },
        config
      );

      dispatch({
        type: UserActionTypes.REQUEST_OTP_RESPONSE,
        payload: res.data,
      });

      dispatch({
        type: UserActionTypes.REQUEST_OTP_SUCCESS,
        payload: email,
      });
      if (res.data.is_account_active === 1) toast.info(res.data.message);
      else toast.info(res.data.message);
      return res;
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      if (error.response) {
        let message = error.response.data.message
          ? error.response.data.message
          : "Something went wrong";
        dispatch({
          type: UserActionTypes.REQUEST_OTP_FAILED,
          message: message,
        });
      } else {
        dispatch({
          type: UserActionTypes.REQUEST_OTP_FAILED,
          message: "Something went wrong.",
        });
      }
    }
  };
};

export const requestOtpActivate = (params, subdomain) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          subdomain: subdomain,
        },
      };

      dispatch({
        type: UserActionTypes.REQUEST_OTP_ACTIVATE,
      });

      const res = await axios.post(requestOtpActivateUrl, params, config);

      if (res.status === 200) {
        dispatch({
          type: UserActionTypes.REQUEST_OTP_ACTIVATE_SUCCESS,
          payload: res.data.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const activateAccountUser = (token, studio_id, params) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.post("/user/account-activation", params, config);
      toast.success("Activation Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const activateAccount = (params, subdomain) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          subdomain: subdomain,
        },
      };

      dispatch({
        type: UserActionTypes.ACTIVATE_ACCOUNT,
      });

      const res = await axios.post(activateAccountUrl, params, config);

      if (res.status === 200) {
        dispatch({
          type: UserActionTypes.ACTIVATE_ACCOUNT_SUCCESS,
          payload: res.data.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: UserActionTypes.ACTIVATE_ACCOUNT_FAILED,
      });
    }
  };
};

export const verifyOtp = (params, subdomain) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          subdomain: subdomain ?? null,
        },
      };

      dispatch({
        type: UserActionTypes.VERIFY_OTP,
      });

      const res = await axios.post(
        subdomain === null ? verifyOtpUrl : verifyOtpStudioUrl,
        params,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: UserActionTypes.VERIFY_OTP_SUCCESS,
          payload: res.data.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: UserActionTypes.VERIFY_OTP_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

// export const changeRole = (token, studio_id, params) => {
//   return async (dispatch) => {
//     try {
//       const config = {
//         headers: {
//           "content-type": "application/json",
//           Authorization: `Bearer ${token}`,
//           "Studio-Id": studio_id,
//         },
//       };

//       const res = await axios.post(changeRoleUrl, params, config);
//       console.log(res, "response");
//       if (res.status === 200) {
//         dispatch({
//           type: UserActionTypes.SET_CHANGE_ROLE,
//           payload: res.data.data,
//         });
//       }
//     } catch (error) {
//       toast.error(<GenerateErrorMessage error={error} />);
//     }
//   };
// };

export const setNewUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.SET_NEW_USER,
      payload: payload,
    });
  };
};

export const setStatusDefault = () => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.SET_STATUS_DEFAULT,
    });
  };
};

export const setStatusVerifyDefault = () => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.VERIFY_OTP_DEFAULT,
    });
  };
};

export const setLoginFromStudio = () => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.LOGIN_FROM_STUDIO,
    });
  };
};

export const logout = (token) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await axios.delete("/logout", config);

    dispatch({
      type: UserActionTypes.LOGOUT,
    });
  };
};
