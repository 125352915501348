import { Row, Col, Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useCookies } from "react-cookie";

import logo from "./../../assets/images/bgheader.png";
import logoStudio from "./../../assets/images/swift_logo.png";

import "./header-owner.styles.scss";
import { logout, setStatusVerifyDefault } from "../../redux/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setStatusDefault } from "../../redux/studio/studio.action";

const HeaderOwner = ({ title }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies, setCookies, removeCookies] = useCookies([
    "user",
    "subdomain",
  ]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;

  const menu = [
    { name: "Dashboard", href: "/" },
    { name: "Student", href: "/students" },
    { name: "Classes", href: "/classes" },
    { name: "Teachers", href: "/teachers" },
    { name: "Profile", href: "/profile" },
    { name: "Settings", href: "/settings" },
  ];

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }

    dispatch(logout(user_token));

    loginFromStudio
      ? history.push("/studio-login")
      : (window.location.href =
          process.env.REACT_APP_PROTOCOL +
          process.env.REACT_APP_HOST +
          "/login");
  };

  return (
    <Row>
      <Col lg={12} className="p-0">
        <Navbar className="header-owner" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/dashboard">
              <img
                alt="studio-logo"
                src={user?.studio_logo ?? cookies.user?.studio_logo}
                width="60"
                height="60"
                className="rounded-circle position-absolute"
                style={{ zIndex: 10, top: 25 }}
              />
              <div style={{ transform: "translateX(60px)" }}>
                <b className="text-uppercase ps-3" style={{ fontSize: "14px" }}>
                  {user?.studio_name ?? cookies.user?.studio_name}
                </b>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="text-center" id="basic-navbar-nav">
              <Nav className="mx-auto">
                {menu.map((el, idx) => (
                  <Nav.Link key={idx} as={Link} to={el.href}>
                    {el.name}
                  </Nav.Link>
                ))}
              </Nav>
              <Nav.Link
                className="nav"
                onClick={() => {
                  handleLogout();
                }}
              >
                Log Out
              </Nav.Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="subheader d-flex justify-content-center p-4 position-relative">
          <h2 className="heading1 m-0" style={{ color: "#fff" }}>
            {title}
          </h2>
          <img
            src={logo}
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default HeaderOwner;
