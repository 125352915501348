import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import HeaderOwner from "./../../../../components/HeaderOwner/header-owner.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import AtomLoading from "../../../../components/loding-indicator";
import {
  getCancellationSetting,
  setStatusDefault,
  updateCancellation,
} from "../../../../redux/studio/studio.action";
import OnboardingStatus from "../../../../components/onboarding-status";

export const Cancellation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rolesOwner, setRolesOwner] = useState(true);
  const [rolesTeacher, setRolesTeacher] = useState(false);
  const [cancellationBeforeHours, setCancellationBeforeHours] = useState(1);
  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { onboarding_status } = useSelector((state) => state.owner);
  const { cancellation_setting, status, isLoading, isLoadingCancellation } =
    useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const isOnboarding = props.location.state?.isOnboarding;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      cancellation_before_hours: cancellationBeforeHours,
      cancellation_policy_title: title,
      cancellation_policy_description: description,
      roles:
        rolesOwner && rolesTeacher
          ? "owner,teacher"
          : rolesOwner
          ? "owner"
          : rolesTeacher && "teacher",
    };

    dispatch(updateCancellation(params, user_token, studio_id));
  };

  useEffect(() => {
    dispatch(getCancellationSetting(user_token, studio_id));
  }, []);

  useEffect(() => {
    if (onboarding_status?.cancellation_setting || cancellation_setting) {
      if (cancellation_setting.roles?.length > 1) {
        const roleteacher = cancellation_setting.roles?.[1] === "teacher";
        setRolesTeacher(roleteacher);
      }

      setCancellationBeforeHours(
        cancellation_setting?.cancellation_before_hours
      );
      setTitle(cancellation_setting?.cancellation_policy_title);
      setDescription(cancellation_setting?.cancellation_policy_description);
    }
  }, [cancellation_setting]);

  useEffect(() => {
    if (status === "success") {
      dispatch(getCancellationSetting(user_token, studio_id));
      history.goBack();
    } else if (status === "success" && isOnboarding) {
      onboarding_status?.payment_information === false
        ? history.push({
            pathname: "/setting-payment",
            state: { isOnboarding: true },
          })
        : history.goBack();
    }
    dispatch(setStatusDefault());
  }, [status, isOnboarding]);

  return (
    <Container fluid style={{ height: "100%" }} className="bg-light">
      <div style={{ minHeight: "100vh" }}>
        <HeaderOwner title="Cancellation" />
        {isLoadingCancellation ? (
          <AtomLoading />
        ) : (
          <>
            {isOnboarding && <OnboardingStatus selectedStep={2} />}
            <Row
              style={{ transform: "translateY(-77px)" }}
              className="justify-content-md-center"
            >
              <Col lg={4}>
                <div className="p-3 bg-white rounded-lg add-student-form">
                  <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <p className="fw-bold">Configure Cancellation Period</p>
                    <Row>
                      <Col>
                        <Form.Group style={{ marginBottom: 10 }}>
                          <TextInput
                            required
                            autoFocus
                            label="Hours"
                            placeholder="Input Hours..."
                            value={cancellationBeforeHours}
                            onChange={(event) => {
                              setCancellationBeforeHours(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    <p className="heading3">
                      The hour(s) in which a student can cancel for a class
                      before the class starts
                    </p>
                    <p>
                      <b>Cancellation Policy</b>
                    </p>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <TextInput
                        required
                        label="Title"
                        placeholder="Input Title..."
                        value={title || ""}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <label htmlFor="desc" className="custom-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        required
                        className="text-input"
                        name="desc"
                        id="desc"
                        cols="30"
                        rows="5"
                        placeholder="Input Description..."
                        value={description || ""}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <p className="fw-bold">Choose Roles That Can Access Cancellation</p>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <div className="d-flex gap-4">
                        <Form.Check
                          disabled
                          type="checkbox"
                          label="Owner"
                          id="roleowner"
                          defaultChecked={rolesOwner}
                          // onChange={(e) => setRolesOwner(e.target.checked)}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Teacher"
                          id="roleteacher"
                          defaultChecked={rolesTeacher}
                          onChange={(e) => setRolesTeacher(e.target.checked)}
                        />
                      </div>
                    </Form.Group>
                    <div className="my-5 d-flex justify-content-between">
                      <Button
                        type={"outline-secondary"}
                        title={isOnboarding ? "Skip for Now" : "Cancel"}
                        onClick={() => {
                          if (isOnboarding) {
                            onboarding_status?.payment_information === false
                              ? history.push({
                                  pathname: "/setting-payment",
                                  state: { isOnboarding: true },
                                })
                              : history.goBack();
                          } else {
                            history.goBack();
                          }
                        }}
                      />
                      <Button
                        buttonType="submit"
                        type="primary"
                        title="SAVE"
                        isLoading={isLoading}
                      />
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Footer />
    </Container>
  );
};
