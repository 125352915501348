import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Modal, Spinner, Image } from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import Button from "./../../../../components/Button/button.component";
import { getDetailPlanTransaction } from "../../../../redux/student/student.action";
import { useCookies } from "react-cookie";
import AtomNumberFormat from "../../../../components/number-format";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import moment from "moment";

const DetailTransaction = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { detail_plan } = useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const id = props.location.state?.id;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  useEffect(() => {
    dispatch(getDetailPlanTransaction(user_token, studio_id, id));
  }, []);

  return (
    <OrganismLayout maxWidth="36rem">
      <Col>
        <div className="text-center">
          <h2 className="heading1 m-0 mb-3">Detail Plan</h2>
          <p className="fw-bold pb-3">{detail_plan?.name}</p>
        </div>
        <div className="b-gray rounded-lg">
          <div className="p-3">
            <Row>
              <Col className="lightaquamarine">Status</Col>
              <Col className="text-capitalize">
                {detail_plan.payment?.payment_status}
              </Col>
            </Row>
            <Row>
              <Col className="lightaquamarine">Transaction ID</Col>
              <Col>{detail_plan?.id}</Col>
            </Row>
            <Row>
              <Col className="lightaquamarine">Buy Date</Col>
              <Col>{detail_plan?.order_date}</Col>
            </Row>
            <Row>
              <Col className="lightaquamarine">Expire Date</Col>
              <Col>{detail_plan.payment?.expired_date}</Col>
            </Row>
            <Row>
              <Col className="lightaquamarine">Payment Method</Col>
              <Col className="text-capitalize">
                {detail_plan.payment?.payment_type?.replace("_", " ")}
              </Col>
            </Row>
            <Row>
              <Col className="lightaquamarine">Plan Start Date</Col>
              <Col>{moment(detail_plan.plan_start_date).format("DD MMMM YYYY")}</Col>
            </Row>
            <Row>
              <Col className="lightaquamarine">Plan End Date</Col>
              <Col>{moment(detail_plan.plan_end_date).format("DD MMMM YYYY")}</Col>
            </Row>
          </div>
        </div>
        <Row className="p-1 mt-3">
          <Col>Price</Col>
          <Col className="d-flex justify-content-end">
            {" "}
            <AtomNumberFormat
              prefix="IDR "
              value={detail_plan.payment?.price}
            />{" "}
          </Col>
        </Row>
        <Row className="p-1">
          <Col>Tax</Col>
          <Col className="d-flex justify-content-end">
            <AtomNumberFormat
              prefix="IDR "
              value={detail_plan.payment?.tax_amount}
            />
          </Col>
        </Row>
        <Row className="fw-bold p-1 mb-3">
          <Col>TOTAL PAYMENT</Col>
          <Col className="d-flex justify-content-end">
            <AtomNumberFormat
              prefix="IDR "
              value={detail_plan.payment?.total_amount}
            />
          </Col>
        </Row>
        <hr />
        <div className="d-flex justify-content-center py-3">
          <Button
            title="Back"
            type="primary"
            onClick={() => history.goBack()}
          />
        </div>
      </Col>
    </OrganismLayout>
  );
};

export default DetailTransaction;
